import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";
import { ReportsPermissions, SettingsPermissions, UserPermissionAllData, userPermissionType } from "./UserPermission";
import "./sidebar.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  HomeRegular, LocationRegular, GiftCardMultipleRegular, MoneySettingsRegular, SettingsRegular,
  WindowRegular, GridDotsFilled, NotebookRegular, PeopleTeamRegular, ChatBubblesQuestionRegular,
  DocumentDataRegular, ReceiptRegular, MailCopyRegular, BoxRegular ,CalendarMonthRegular
} from '@fluentui/react-icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import api from "../../CommonApi/axios";
import headers from "../../CommonApi/headers";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 15,
  },
}));

export default function GroupAdminSidebar({ drawerPos, handleDrawer, data }) {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  // Helper function to check if data has changed
  const hasDataChanged = (newData, existingData) => {
    return newData.status !== existingData.status || 
      JSON.stringify(newData.features) !== JSON.stringify(existingData.features)
       || newData.branches !== existingData.branches
       || newData.number_of_branches !== existingData.number_of_branches 
  };

  // Function to handle API call and data setting in localStorage
  const fetchAndUpdateData = () => {
    api
      .get(`/groupadmin/subscription`, { headers })
      .then((res) => {
        const newTableData = res.data.data;

        // Save to localStorage
        localStorage.setItem('DataSub', JSON.stringify(newTableData));
      })
      .catch((err) => {
        console.error(err);
        localStorage.remove('DataSub');
      });
  };

  useEffect(() => {
    const localDataSub = localStorage.getItem('DataSub');

    if (localDataSub) {
      const parsedLocalData = JSON.parse(localDataSub);
      // Check if there are any changes in the new API data compared to local storage
      api
        .get(`/groupadmin/subscription`, { headers })
        .then((res) => {
          const newTableData = res.data.data;

          // If data has changed, update localStorage
          if (hasDataChanged(newTableData, parsedLocalData)) {
            fetchAndUpdateData();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If there's no local data, fetch fresh data
      fetchAndUpdateData();
    }
  }, [UserPermissionAllData?.customToken]);

  const UserPermissionAllData_ = UserPermissionAllData && UserPermissionAllData?.role_details
  // console.log("UserPermissionAllData_", UserPermissionAllData_)
  // console.log("data" ,data)

  useEffect(() => {
    handleClickScroll();
  }, [url]);

  const handleClickScroll = () => {
    const element = document.getElementById(
      url === "settings"
        ? "section-12"
        : "");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  const menuItemsArray = [
    {
      section: "dashboard",
      label: "Dashboard",
      icon: <HomeRegular />,
      compare: "dashboard",
      subcription: "dashboard"
    },
    {
      section: "branch_management",
      label: "Branches",
      icon: <LocationRegular />,
      compare: "branch",
      subcription: "branch"
    },
    {
      section: "table_management",
      label: "Tables",
      icon: <GridDotsFilled />,
      compare: "tables",
      subcription: "tables"
    },
    {
      section: "order_management",
      label: "Orders",
      icon: <ReceiptRegular />,
      compare: "orders",
      subcription: "orders"
    },
    {
      section: "menu_management",
      label: "Menus",
      icon: <NotebookRegular />,
      compare: "menus",
      subcription: "menus"
    },
    {
      section: "table_reservations",
      label: "Table Reservations",
      icon: <CalendarMonthRegular />,
      compare: "table_reservations",
      subcription: "table_reservations"
    },
    {
      section: "stock_management",
      label: "Stock Management",
      icon: <BoxRegular />,
      compare: "stock",
      subcription: "branch"
    },
    {
      section: "coupon_voucher_management",
      label: "Coupons & Vouchers",
      icon: <GiftCardMultipleRegular />,
      compare: ["coupons", "vouchers"],
      subcription: "coupon_voucher_management"
    },
    {
      section: "tour",
      label: "Tour Groups",
      icon: <PeopleTeamRegular />,
      compare: "tour_groups",
      subcription: "tour_group_setup"
    },
    {
      section: "email_marketing",
      label: "Email Marketing",
      icon: <MailCopyRegular />,
      compare: "email_marketing",
      subcription: "email_marketing"
    },
    {
      section: "subscription_management",
      label: "Subscriptions",
      icon: <MoneySettingsRegular />,
      compare: "subscriptions",
      subcription: "subscriptions"
    },
    {
      section: "enquiry",
      label: "Enquiries",
      icon: <ChatBubblesQuestionRegular />,
      compare: "enquiries",
      subcription: "enquiry_management"
    },
    {
      section: "report",
      label: "Reports",
      icon: <DocumentDataRegular />,
      compare: "report",
      subcription: "report"
    },
    {
      section: "kpgpt",
      label: "KPGPT",
      icon: <WindowRegular />,
      compare: "KPGPT",
      subcription: "KPGPT"
    },
    {
      section: "settings",
      label: "Settings",
      icon: <SettingsRegular />,
      compare: "settings",
      subcription: "settings"
    },
  ];


  function formatKey(key) {
    return key.toLowerCase().replace(/\s+/g, '_'); // Example implementation
  }

  function filterMenuItems(permissions, menuItemsArray) {
    // Create a set of sections that have any access permissions
    const permissionsMap = new Set(
      permissions
        ?.filter(item => item?.access?.length > 0)
        ?.map(item => formatKey(item.key))
    );


    // Filter menuItemsArray based on the permissionsMap
    const filteredMenuItems = menuItemsArray?.filter(menuItem => {
      if (menuItem.section === "coupon_voucher_management") {
        // Special case for "coupon_voucher_management"
        return permissionsMap?.has("coupons") || permissionsMap?.has("vouchers");
      }
      if (menuItem.section === "report") {
        // Special case for "coupon_voucher_management"
        return ReportsPermissions?.length > 0;
      }
      if (menuItem.section === "settings") {
        // Special case for "coupon_voucher_management"
        return SettingsPermissions?.length > 0;
      }

      // General case for all other sections
      return permissionsMap?.has(formatKey(menuItem.compare));
    });

    return filteredMenuItems;
  }

  const filteredMenuItems__ = filterMenuItems(UserPermissionAllData_?.permissions, menuItemsArray);

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;

  let filteredMenuItemsData = userPermissionType === "groupadmin" ? menuItemsArray : filteredMenuItems__

  // let shouldShowCouponVoucher;
  const shouldShowCouponVoucher = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features
  // console.log("shouldShowCouponVoucher" ,shouldShowCouponVoucher)
  const MenuItemsRGA = shouldShowCouponVoucher ? filteredMenuItemsData.filter(item => {
    // let shouldShowCouponVoucher;
    if (item.section === "coupon_voucher_management") {
      return (shouldShowCouponVoucher?.includes("vouchers") ||
        shouldShowCouponVoucher?.includes("coupons")
      );
    }
    if (item.section === "tour") {
      return shouldShowCouponVoucher?.includes("tour_group_setup") // Only show if 'enquiries' is in features
    }

    if (item.section === "enquiry") {
      return shouldShowCouponVoucher?.includes("enquiry_management")// Only show if 'vouchers' is in features
    }

    return filteredMenuItemsData;
  }) : menuItemsArray.filter(item =>
    !["enquiry_management", "tour_group_setup", "coupon_voucher_management"].includes(item.subcription)
  );

  // console.log("MenuItemsRGA", MenuItemsRGA)

  let drawerClass = [];
  if (drawerPos === 1) {
    drawerClass.push("drawerMin");
  } else if (drawerPos === 2) {
    drawerClass.push("drawerOpen");
  } else {
    drawerClass = [];
  }

  return (
    <React.Fragment>
      <aside>
        <div
          id="kt_aside"
          className={`${drawerClass.join(" ")} ${"aside pb-5 pt-5 pt-lg-0"}`}
        >
          <div style={{ borderRight: "1px solid #C7D0D7" }} className="aside-menu flex-column-fluid" id="kt_aside_menu">
            <div
              className=" my-0 my-lg-5 pe-lg-n1"
              id="kt_aside_menu_wrapper"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
              data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
              data-kt-scroll-offset="5px"
            >

              <div
                style={{ height: "80vh", position: "relative", overflowY: "auto" }}
                className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
                id="#kt_aside_menu"
                data-kt-menu="true"

              >
                {MenuItemsRGA?.length > 0 && drawerPos === 1 ? MenuItemsRGA?.map((menuItem, index) => (
                  <div className="menu-item my-0" key={index} id={`section-${index + 1}`}>
                    <LightTooltip

                      title={menuItem.label} placement="right" arrow>
                      <Link
                        className={url === menuItem.section ? "menu-link active_ menu-center" : "menu-link padding_ menu-center"}
                        to={`/group_admin/${menuItem.section}`}
                        data-bs-trigger="hover"
                        data-bs-dismiss="click"
                        data-bs-placement="right"
                      >
                        <span className={url === menuItem.section ? "icon_active" : "icon_s"}>
                          {menuItem.icon}
                        </span>
                      </Link>
                    </LightTooltip>
                  </div>
                )) :

                  MenuItemsRGA?.map((menuItem, index) => (
                    <div className="menu-item my-0" key={index} id={`section-${index + 1}`}>
                      <Link
                        className={url === menuItem.section ? "menu-link active menu-center" : "menu-link menu-center"}
                        to={`/group_admin/${menuItem.section}`}
                        data-bs-trigger="hover"
                        data-bs-dismiss="click"
                        data-bs-placement="right"
                      >
                        <span className={url === menuItem.section ? "icon_active" : "icon_s"}>
                          {menuItem.icon} </span> <span className="mx-3">{menuItem.label} </span>
                      </Link>

                    </div>
                  ))}
              </div>

            </div>
            <div >
              {drawerPos === 1 ? (
                <div onClick={handleDrawer} className="text-center my-2 arrowDown_">
                  <ArrowForwardIosIcon style={{ fontSize: 16, cursor: "pointer", color: "#5A5F72" }} />
                </div>
              ) : (
                <div className="row my-2 arrowDown_Hide">
                  <div className="text-start col-md-10 hide">Hide</div>
                  <div onClick={handleDrawer} className="text-end col-md-2">
                    <ArrowBackIosIcon style={{ fontSize: 16, cursor: "pointer", color: "#5A5F72" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}
