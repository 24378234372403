import { combineReducers } from "@reduxjs/toolkit";
import { branchReducer } from "./branch";
import branchListReducer from "./branch/branch-list";
import voucherTemListReducer from "./voucher/voucherTem-list";
import voucherPurchasedListReducer from "./voucher/voucherPurchased-list";
import UserManagmentListReducer from "./usermanagment/usermanagment-list";
import couponListReducer from "./coupon/coupon-list";
import { languagesAllListReducer, languagesResListReducer } from "./languages/languages-list";
import { printerCatagoryListReducer, printerListReducer } from "./printer/printer-list";
import { colorDetailsReducer, footerListReducer, headerListReducer } from "./cms/cms";
import menuListReducer from "./menu/menu";
import orderDetailsReducer from "./order/order";
import emailMarketingListReducer from "./emailmarketing/emailmarketinglist";
import dashboardCountReducer from "./dashboard/dashboradcount";
import { addonListReducer } from "./add-on-properties/add-on-properties";
import { inventoryCategoryListReducer, inventoryProductListReducer } from "./inventory/inventory";
import { stockListReducer } from "./stock/stock";
import emailTemplateListReducer from "./emailtemplate/emailtemplatelist";

const rootReducer = combineReducers({
  branch: branchReducer,
  branchList : branchListReducer,

  voucherTemList : voucherTemListReducer,
  voucherPurchasedList : voucherPurchasedListReducer,

  UserManagmentList : UserManagmentListReducer,

  couponList : couponListReducer,

  languagesAllList : languagesAllListReducer,
  languagesResList : languagesResListReducer,

  printerList : printerListReducer, 
  printerCatagoryList : printerCatagoryListReducer,

  headerList : headerListReducer,
  footerList :footerListReducer ,
  colorDetails :colorDetailsReducer,

  menuList : menuListReducer,
  orderDetails : orderDetailsReducer,

  emailMarketingList : emailMarketingListReducer,
  dashboardCount : dashboardCountReducer,

  addonList : addonListReducer,
  inventoryCatagotyList : inventoryCategoryListReducer,
  inventoryproductList : inventoryProductListReducer,

  stockList  : stockListReducer,
  emailTemplateList : emailTemplateListReducer
});

export { rootReducer };
