import React, { useEffect, useRef, useState } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import useDrawer from "../../../../../../context/DrawerContext";
import api from "../../../../../../CommonApi/axios";
import headers from "../../../../../../CommonApi/headers";
import "react-toastify/dist/ReactToastify.css";
import NoteState from "../../CommanBranch/NoteState";
import Swal from "sweetalert2";
import { UserPermissionAllData, userPermissionType } from "../../../../../Comman/UserPermission";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Box from '@mui/material/Box';
import { Form, Button, Container } from 'react-bootstrap';
import { EyeRegular } from '@fluentui/react-icons';
import ButtonMUI from '@mui/material/Button';
// import "./setting.css"
import { ErrorMessage } from "../../../../../Comman/Error/ErrorMessage";
import { showSuccessNotification } from "../../../../../Comman/Swal/Swal";
import EmailTemplateCommon from "../EmailTemplateCommon/EmailTemplateCommon";




function EmailTemplateSetup(props) {
      const textareaRef = useRef(null);

      const { branchID } = props
      const dashboardURl = sessionStorage.getItem("dashboardURl");

      const handleCopy = () => {
            if (textareaRef.current) {
                  navigator.clipboard.writeText(textareaRef.current.value);
                  // console.log(textareaRef?.current?.value);
            }
      };
      let history = useHistory();

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


      const userPermissionType_ = userPermissionType === "groupadmin"
      const [error, setError] = useState();
      const [Data, setData] = useState("");
      const [loader, setloader] = useState(false);
      const [isReservationsEnabled, setIsReservationsEnabled] = useState(true);
      const [list_branch, setlist_branch] = useState(false)
      // const [bookingIframeCode, setBookingIframeCode] = useState();
      const [title, settitle] = useState("");
      const [text, settext] = useState("");
      const [theme, setTheme] = useState("");
      const [image ,setimage] = useState([])

      const bookingIframeCode = `https://${dashboardURl}/table_reservation`

      function handleSubmit(event) {
            event.preventDefault();
            setError(null);

            if (theme === "") {
                  setError("Please select theme");
                  return
            }
            // else if (bookingIframeCode === "") {
            //       setError("Please enter booking iframe code");

            //       return
            // } 
            else {
                  setloader(true);
                  // const formData = {
                  //       branch_id: branchID,
                  //       reservations_allowed: isReservationsEnabled,
                  //       list_branch: list_branch,
                  //       iframe_code: bookingIframeCode,
                  //       button_color: title,
                  //       text_color: text,
                  //       theme: theme?.value
                  // }

                  let item = {
                        reservation_theme: theme?.value
                  }

                  // console.log("formData" ,formData);
                  // return

                  // const apiCall = Data?._id ?
                  //       api.patch(`/groupadmin/table_reservation_settings/${Data?._id}`, formData, { headers }) :
                  //       api.post(`/groupadmin/table_reservation_settings`, formData, { headers });

                  const apiCall = api.patch(`/groupadmin/settings/table_reservation_time/theme/`, item, { headers });


                  apiCall
                        .then((res) => {
                              setloader(false);
                              showSuccessNotification("Your work has been saved successfully.")
                              groupAdminDataGate();
                        })
                        .catch((err) => {
                              setloader(false);
                              if (err.response) {
                                    setError(err.response?.data?.error?.message);
                              }
                        });
            }

      }

      useEffect(() => {
            groupAdminDataGate();
      }, []);

      function groupAdminDataGate() {
            api
                  .get(`/groupadmin/profile`, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        setTheme(tableData?.reservation_theme ? { label: tableData?.reservation_theme, value: tableData?.reservation_theme } : "");
                  })
                  .catch((err) => {
                        if (err.response) {
                              // console.log(err?.response?.data?.error?.message);
                        }
                  });
      }

      // console.log("theme", theme)


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div

                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ marginTop: "-30px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-2"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row mb-5">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Table Reservation
                                                                        </h3>
                                                                  </div>


                                                                  <div
                                                                        className="col-md-3 d-inline justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >


                                                                        {/* <NoteState /> */}

                                                                  </div>
                                                            </div>

                                                            <EmailTemplateCommon />
                                                            <div className="mt-5">
                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div
                                                                              className="tab-pane fade show active"
                                                                              id="kt_tab_pane_1"
                                                                              role="tabpanel"
                                                                        >
                                                                              <div className="card card-docs mb-2">
                                                                                    <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                                          <div className="rounded border p-10">
                                                                                                <form onSubmit={handleSubmit}>
                                                                                                      {/* <div className="row col-md-12">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title">Enable/Disable Reservations</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-8">
                                                                                                                  <div className="mb-5">
                                                                                                                        <div className="switch-container">
                                                                                                                              <label className="switchSmall m5">
                                                                                                                                    <input checked={isReservationsEnabled}
                                                                                                                                          onChange={() => setIsReservationsEnabled(!isReservationsEnabled)} type="checkbox" />
                                                                                                                                          <small></small>
                                                                                                                              </label>
                                                                                                                              <span className="switch-label me-4">{isReservationsEnabled ? "Enabled" : "Disabled"}</span>
                                                                                                                              {!isReservationsEnabled && <span className="switch-label">
                                                                                                                                    <input
                                                                                                                                          className="form-check-input"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={list_branch}
                                                                                                                                          onChange={() => setlist_branch(!list_branch)}
                                                                                                                                    />
                                                                                                                                    <label className="form-check-label switch-label mx-3">Show Branch Name (Non-Selectable) in selection</label>

                                                                                                                              </span>}

                                                                                                                        </div>

                                                                                                                  </div>
                                                                                                            </div>

                                                                                                            <hr />
                                                                                                      </div> */}
                                                                                                      <div className="row col-md-12">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title">Booking iframe code</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-7">
                                                                                                                  <div className="position-relative mb-5">
                                                                                                                        <ButtonMUI
                                                                                                                              onClick={handleCopy}
                                                                                                                              variant="outlined"
                                                                                                                              size="small"
                                                                                                                              className="position-absolute top-0 end-0 m-5 copybutton"
                                                                                                                        >
                                                                                                                              Copy
                                                                                                                        </ButtonMUI>
                                                                                                                        <div
                                                                                                                              style={{
                                                                                                                                    backgroundColor: 'black',
                                                                                                                                    color: 'white',
                                                                                                                                    width: '100%',
                                                                                                                                    height: '150px',

                                                                                                                                    padding: '10px',
                                                                                                                                    borderRadius: '15px',

                                                                                                                              }}
                                                                                                                        >
                                                                                                                              <Form.Group controlId="styledTextarea" className="mb-3">
                                                                                                                                    <Form.Control
                                                                                                                                          as="textarea"
                                                                                                                                          rows={5}
                                                                                                                                          // placeholder="Enter your text here..."
                                                                                                                                          ref={textareaRef}
                                                                                                                                          value={`<iframe title="Table Reservation"  src="${bookingIframeCode}"  width="750" height="650" style="border:0;" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}
                                                                                                                                          readOnly={true}
                                                                                                                                          // onChange={(e) => setBookingIframeCode(e.target.value)}
                                                                                                                                          style={{
                                                                                                                                                backgroundColor: 'black',
                                                                                                                                                color: 'white',
                                                                                                                                                border: '0px solid #555',
                                                                                                                                                height: '100%',
                                                                                                                                                resize: 'none',
                                                                                                                                                width: "90%",
                                                                                                                                                overflowX: 'hidden',
                                                                                                                                          }}
                                                                                                                                    />
                                                                                                                              </Form.Group>
                                                                                                                        </div>
                                                                                                                  </div>

                                                                                                                  <ButtonMUI className="mb-4 Preview_button" variant="outlined" startIcon={<EyeRegular style={{ fontSize: "24px" }} />}>
                                                                                                                        Preview
                                                                                                                  </ButtonMUI>

                                                                                                            </div>
                                                                                                            <div className="col-md-1">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />



                                                                                                      <div className="row col-md-12 mt-10">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title mt-3">Theme</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="mb-5">
                                                                                                                        <Select
                                                                                                                              isSearchable={false}
                                                                                                                              placeholder="Select Theme"
                                                                                                                              options={[
                                                                                                                                    { label: "Normal", value: "Normal" },
                                                                                                                                    { label: "Indian Themed", value: "Indian Themed" },

                                                                                                                              ]}
                                                                                                                              onChange={(opt) => setTheme(opt)}
                                                                                                                              // onChange={handleBranchChange}
                                                                                                                              value={theme}
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                            </div>

                                                                                                      </div>
                                                                                                      <hr />

                                                                                                      <div className="row col-md-12 mt-10">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title mt-3">Feature Section Title</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="mb-5">
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              placeholder="Enter Feature Section Title"
                                                                                                                              className="form-control"
                                                                                                                              // style={{ height: "50px" }}
                                                                                                                              value={title}
                                                                                                                              onChange={(e) => settitle(e.target.value)}

                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />

                                                                                                      <div className="row col-md-12 mt-10">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title mt-3">Feature Section Text</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="mb-5">
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              placeholder="Enter Feature Section Title"
                                                                                                                              className="form-control "
                                                                                                                              // style={{ height: "50px" }}
                                                                                                                              value={text}
                                                                                                                              onChange={(e) => settext(e.target.value)}
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />
                                                                                                      <div className="row col-md-12 mt-10">
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="label-title mt-3">Feature Section Image</div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  <div className="mb-5">
                                                                                                                        <input
                                                                                                                              type="file"
                                                                                                                              className="form-control "
                                                                                                                              // style={{ height: "50px" }}
                                                                                                                              value={image}
                                                                                                                              onChange={(e) => setimage(e.target.value)}
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />

                                                                                                      <ErrorMessage error={error} />
                                                                                                      {loader ?
                                                                                                            <div className="col-md-12 text-center">
                                                                                                                  <CircularProgress />
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="text-center">

                                                                                                                  {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.some(permission =>
                                                                                                                        permission?.key === "table_reservation" &&
                                                                                                                        (permission?.access?.includes("edit") || permission?.access?.includes("create"))
                                                                                                                  )) && (
                                                                                                                              <button
                                                                                                                                    type="submit"
                                                                                                                                    id="kt_modal_new_address_submit"
                                                                                                                                    className="btn btn-primary"
                                                                                                                              >
                                                                                                                                    Save
                                                                                                                              </button>)}
                                                                                                                  <button onClick={() => history.push("/group_admin/settings")} type="button" className="btn btn-danger mx-2">
                                                                                                                        Cancel
                                                                                                                  </button>
                                                                                                            </div>}
                                                                                                </form>

                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>

                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>


            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
      return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateSetup);

