
export const BRANCH = {
  GETBRANCH: "GETBRANCH",
  GETBRANCHTIME: "GETBRANCHTIME",
  LANGUAGE: "LANGUAGE",
  TYPE: "TYPE",
  CLEARBRANCHLIST: "CLEARBRANCHLIST",
  BRANCHLIST: "BRANCHLIST",
  BRANCHID: "BRANCHID",
  BRANCHNAME: "BRANCHNAME",
  BRANCHTIMEDATA: "BRANCHTIMEDATA",
  BRANCHTIMEDATACLEAR: "BRANCHTIMEDATACLEAR",
  BRANCHDETAILS: "BRANCHDETAILS",
  BRANCHPRIMARYLANGU: "BRANCHPRIMARYLANGU",
  BRANCHLANGUAGES: "BRANCHLANGUAGES",
  DELIVERYSETTINGSDATA: "DELIVERYSETTINGSDATA",
  DINEINTIMEDATA: "DINEINTIMEDATA",
  WELCOMEMESSAGEDATA: "WELCOMEMESSAGEDATA",
  SETBRANCH: "SETBRANCH",
  SETBRANCHDETAIL: "SETBRANCHDETAIL",
  GETPROFILE: "GETPROFILE",
};


export const BRANCH1 = {
  GET_BRANCH_LIST : "GET_BRANCHL_IST",
  SET_BRANCH_LIST : "SET_BRANCH_LIST",
  CLEAR_BRANCH_LIST : "CLEAR_BRANCH_LIST"
}

export const BRANCHID = {
  GET_BRANCHDETAILS_BY_ID : "GET_BRANCHDETAILSLBY_ID",
  SET_BRANCHDETAILS_BY_ID : "SET_BRANCHDETAILS_BY_ID",
  CLEAR_BRANCHDETAILS_BY_ID : "CLEAR_BRANCHDETAILS_BY_ID"
}


export const VOUCHERTEM = {
  GET_VOUCHERTEM_LIST : "GET_VOUCHERTEML_LIST",
  SET_VOUCHERTEM_LIST : "SET_VOUCHERTEM_LIST",
  CLEAR_VOUCHERTEM_LIST : "CLEAR_VOUCHERTEM_LIST"
}


export const VOUCHERPURCHASED = {
  GET_VOUCHERPURCHASED_LIST : "GET_VOUCHERPURCHASED_LIST",
  SET_VOUCHERPURCHASED_LIST : "SET_VOUCHERPURCHASED_LIST",
  CLEAR_VOUCHERPURCHASED_LIST : "CLEAR_VOUCHERPURCHASED_LIST"
}


export const USERMANAGMENT = {
  GET_USERMANAGMENT_LIST : "GET_USERMANAGMENT_LIST",
  SET_USERMANAGMENT_LIST : "SET_USERMANAGMENT_LIST",
  CLEAR_USERMANAGMENT_LIST : "CLEAR_USERMANAGMENT_LIST"
}

export const COUPON = {
  GET_COUPON_LIST : "GET_COUPONL_LIST",
  SET_COUPON_LIST : "SET_COUPON_LIST",
  CLEAR_COUPON_LIST : "CLEAR_COUPON_LIST"
}


export const LANGUAGEALL = {
  GET_LANGUAGEALL_LIST : "GET_LANGUAGEALLL_LIST",
  SET_LANGUAGEALL_LIST : "SET_LANGUAGEALL_LIST",
  CLEAR_LANGUAGEALL_LIST : "CLEAR_LANGUAGEALL_LIST"
}

export const LANGUAGERES = {
  GET_LANGUAGERES_LIST : "GET_LANGUAGERESL_LIST",
  SET_LANGUAGERES_LIST : "SET_LANGUAGERES_LIST",
  CLEAR_LANGUAGERES_LIST : "CLEAR_LANGUAGERES_LIST"
}



export const PRINTER = {
  GET_PRINTER_LIST : "GET_PRINTERL_LIST",
  SET_PRINTER_LIST : "SET_PRINTER_LIST",
  CLEAR_PRINTER_LIST : "CLEAR_PRINTER_LIST",

  GET_PRINTERCATEGORY_LIST : "GET_PRINTERCATEGORYL_LIST",
  SET_PRINTERCATEGORY_LIST : "SET_PRINTERCATEGORY_LIST",
  CLEAR_PRINTERCATEGORY_LIST : "CLEAR_PRINTERCATEGORY_LIST",
}


export const CMS = {
  GET_HEADER_LIST : "GET_HEADERL_LIST",
  SET_HEADER_LIST : "SET_HEADER_LIST",
  CLEAR_HEADER_LIST : "CLEAR_HEADER_LIST",

  GET_FOOTER_LIST : "GET_FOOTERL_LIST",
  SET_FOOTER_LIST : "SET_FOOTER_LIST",
  CLEAR_FOOTER_LIST : "CLEAR_FOOTER_LIST",

  GET_COLOR : "GET_COLORL",
  SET_COLOR : "SET_COLOR",
  CLEAR_COLOR : "CLEAR_COLOR",
}

export const MENU = {
  GET_MENU_LIST : "GET_MENUL_LIST",
  SET_MENU_LIST : "SET_MENU_LIST",
  CLEAR_MENU_LIST : "CLEAR_MENU_LIST",
}

export const ORDER = {
  GET_ORDER_DETAILS : "GET_ORDERL_DETAILS",
  SET_ORDER_DETAILS : "SET_ORDER_DETAILS",
  CLEAR_ORDER_DETAILS : "CLEAR_ORDER_DETAILS",
}


export const EMAILMARKETING = {
  GET_EMAILMARKETING_LIST : "GET_EMAILMARKETINGL_LIST",
  SET_EMAILMARKETING_LIST : "SET_EMAILMARKETING_LIST",
  CLEAR_EMAILMARKETING_LIST : "CLEAR_EMAILMARKETING_LIST",
}

export const DASHBOARD = {
  GET_DASHBOARD_COUNT : "GET_DASHBOARDL_COUNT",
  SET_DASHBOARD_COUNT : "SET_DASHBOARD_COUNT",
  CLEAR_DASHBOARD_COUNT : "CLEAR_DASHBOARD_COUNT",
  SET_LOADING: 'SET_LOADING', // New action
}


export const ADDONPROPERTIES = {
  GET_ADD_ON : "GET_ADD_ON" ,
  SET_ADD_ON : "SET_ADD_ON" ,
  CLEAR_ADD_ON : "CLEAR_ADD_ON"
 }

 export const INVENTORY = {
  GET_INVENTORY_CATEGORY: "GET_INVENTORY_CATEGORY",
  SET_INVENTORY_CATEGORY: "SET_INVENTORY_CATEGORY",
  CLEAR_INVENTORY_CATEGORY: "CLEAR_INVENTORY_CATEGORY",

  GET_INVENTORY_PRODUCT: "GET_INVENTORY_PRODUCT",
  SET_INVENTORY_PRODUCT: "SET_INVENTORY_PRODUCT",
  CLEAR_INVENTORY_PRODUCT: "CLEAR_INVENTORY_PRODUCT",
};

export const STOCK = {
  GET_STOCK_LIST : "GET_STOCK_LIST",
  SET_STOCK_LIST : "SET_STOCK_LIST",
  CLEAR_STOCK_LIST : "CLEAR_STOCK_LIST"
 };

 export const EMAILTEMPLATE = {
  GET_EMAILTEMPLATE_LIST : "GET_EMAILTEMPLATE_LIST",
  SET_EMAILTEMPLATE_LIST : "SET_EMAILTEMPLATE_LIST",
  CLEAR_EMAILTEMPLATE_LIST : "CLEAR_EMAILTEMPLATE_LIST"
 };






