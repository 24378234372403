import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import api from "../../../../../../CommonApi/axios";
import Swal from "sweetalert2";
import Select from "react-select";
import $ from "jquery";
import headers from "../../../../../../CommonApi/headers";
import useDrawer from "../../../../../../context/DrawerContext";
import CommonCatagory from "./CommonCatagory";
import { MenuPermissions, userPermissionType } from "../../../../../Comman/UserPermission";
import { groupAdminBranchDetilasByID, printerList } from "../../../../../Comman/CommonFunction/CommonFunction";
window.jquery = window.$ = $;


export default function GroupAdminMenuCategoryEditInformation() {
  const location = useLocation();
  const { id, menuId, branchID } = useParams();

  const [languange, setlanguange] = useState([]);
  const [PrinterList_, setPrinterList_] = useState([]);
  const [printer, setprinter] = useState("");


  useEffect(() => {
    groupAdminBranchDetilasByID(branchID, setlanguange);
    printerList(branchID, setPrinterList_);
  }, [branchID]);

  let history = useHistory();

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [name_EN, setname_EN] = useState("");
  const [name_FR, setname_FR] = useState("");
  const [name_IT, setname_IT] = useState("");
  const [name_DE, setname_DE] = useState("");
  const [name_AR, setname_AR] = useState("");
  const [description_EN, setdescription_EN] = useState("");
  const [description_FR, setdescription_FR] = useState("");
  const [description_IT, setdescription_IT] = useState("");
  const [description_DE, setdescription_DE] = useState("");
  const [description_AR, setdescription_AR] = useState("");
  const [disclaimer_EN, setdisclaimer_EN] = useState("");
  const [disclaimer_FR, setdisclaimer_FR] = useState("");
  const [disclaimer_IT, setdisclaimer_IT] = useState("");
  const [disclaimer_DE, setdisclaimer_DE] = useState("");
  const [disclaimer_AR, setdisclaimer_AR] = useState("");
  const [is_active, setis_active] = useState("");
  const [image, setimage] = useState("");
  const [seq_no, setseq_no] = useState("");
  const [is_featured, setis_featured] = useState(false);
  const [color, setcolor] = useState("");
  const [product_ids, setproduct_ids] = useState([]);
  const [productList, setProductList] = useState([]);
  const [apiproductList, setapiproductList] = useState([]);


  useEffect(() => {
    groupAdminProductList();
  }, [is_featured]);

  function groupAdminProductList() {
    setProductList([]);

    let item = {
      menu_id: menuId,
    };
    api
      .post(
        `/groupadmin/category/productList/${branchID}`,
        item,
        {
          headers,
        }
      )
      .then((res) => {
        const options = res.data.data.map((item) => ({
          label: item.name_EN,
          value: item._id,
        }));
        setProductList(options);
        // console.log("options", options);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.data?.error?.message === "No Data found.") {
            setProductList([]);
          }
        }
      });
  }

  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setImgData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setimage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    CatagoryDetailsGet();
  }, [id]);

  function CatagoryDetailsGet() {
    api
      .get(`/groupadmin/category/${id}`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        console.log(tableData);
        setname_EN(tableData.name_EN);
        setseq_no(tableData.seq_no);
        setname_FR(tableData.name_FR === "undefined" ? "" : tableData.name_FR);
        setname_IT(tableData.name_IT === "undefined" ? "" : tableData.name_IT);
        setname_DE(tableData.name_DE === "undefined" ? "" : tableData.name_DE);
        setname_AR(tableData.name_AR === "undefined" ? "" : tableData.name_AR);
        setprinter(tableData?.printer_category_name ? {
          label: tableData?.printer_category_name,
          value: tableData?.printer_key
        } : [])
        setdescription_EN(tableData.description_EN);
        setdescription_FR(
          tableData.description_FR === "undefined"
            ? ""
            : tableData.description_FR
        );
        setdescription_IT(
          tableData.description_IT === "undefined"
            ? ""
            : tableData.description_IT
        );
        setdescription_DE(
          tableData.description_DE === "undefined"
            ? ""
            : tableData.description_DE
        );
        setdescription_AR(
          tableData.description_AR === "undefined"
            ? ""
            : tableData.description_AR
        );
        setis_active(tableData.is_active);
        setdisclaimer_EN(
          tableData.disclaimer_EN === "undefined" ? "" : tableData.disclaimer_EN
        );
        setdisclaimer_DE(
          tableData.disclaimer_DE === "undefined" ? "" : tableData.disclaimer_DE
        );
        setdisclaimer_FR(
          tableData.disclaimer_FR === "undefined" ? "" : tableData.disclaimer_FR
        );
        setdisclaimer_IT(
          tableData.disclaimer_IT === "undefined" ? "" : tableData.disclaimer_IT
        );
        setdisclaimer_AR(
          tableData.disclaimer_AR === "undefined" ? "" : tableData.disclaimer_AR
        );
        setimage(tableData.image ? tableData.image : "");
        setcolor(tableData.color === "undefined" ? "" : tableData.color);
        setis_featured(
          tableData.is_featured === "undefined" ? false : tableData.is_featured
        );
        setapiproductList(
          tableData?.featured_products?.length > 0
            ? tableData?.featured_products.map((item) => ({
              label: item.name_EN,
              value: item._id,
            }))
            : []
        );
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }


  console.log("printer", printer)


  // function handleSubmit(event) {
  //   event.preventDefault();
  //   setError(null);

  //   if (name_EN.toString().trim().length < 3) {
  //     setError(`Category Name Must Be at least 3 character Long`);
  //   }
  //   if (is_featured === false) {
  //     if (seq_no === 0) {
  //       setError(
  //         `Enter valid sequence number. `
  //       )
  //     }
  //     else {
  //       $(".loader").show();
  //       const formData = new FormData();
  //       formData.append("menu_id", menuId);
  //       formData.append("branch_id", branchID);
  //       formData.append("is_active", is_active);
  //       // formData.append("image", imgData);




  //       if (imgData && (imgData.type !== "image/png" && imgData.type !== "image/jpeg" && imgData.type !== "image/jpg")) {
  //         setError("Please select a PNG, JPEG, or JPG image");
  //         $(".loader").hide();
  //         return
  //       } else {
  //         formData.append("image", imgData);
  //       }
  //       // const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
  //       // if (!allowedFormats.includes(imgData.type)) {
  //       //   $(".loader").hide();
  //       //   setError("Invalid image format. Please upload a PNG, JPEG, or JPG image.");
  //       //   return; // Stop form submission if there's an error
  //       // }else{
  //       //   formData.append("image", imgData);
  //       // }
  //       formData.append("seq_no", is_featured ? "0" : Number(seq_no));

  //       if (is_featured) {
  //         formData.append("is_featured", true);
  //       } else {
  //         formData.append("is_featured", false);
  //       }

       

  //       formData.append("name_EN", name_EN ? name_EN : "");

  //       formData.append("name_FR", name_FR ? name_FR : "");

  //       formData.append("name_IT", name_IT ? name_IT : "");

  //       formData.append("name_DE", name_DE ? name_DE : "");
  //       formData.append("name_AR", name_AR ? name_AR : "");

  //       formData.append("description_EN", description_EN ? description_EN : "");

  //       formData.append("description_FR", description_FR ? description_FR : "");
  //       formData.append("description_IT", description_IT ? description_IT : "");

  //       formData.append("description_DE", description_DE ? description_DE : "");
  //       formData.append("description_AR", description_AR ? description_AR : "");


  //       formData.append("disclaimer_EN", disclaimer_EN ? disclaimer_EN : "");

  //       formData.append("disclaimer_DE", disclaimer_DE ? disclaimer_DE : "");

  //       formData.append("disclaimer_FR", disclaimer_FR ? disclaimer_FR : "");

  //       formData.append("disclaimer_IT", disclaimer_IT ? disclaimer_IT : "");

  //       formData.append("disclaimer_AR", disclaimer_AR ? disclaimer_AR : "");

  //       formData.append("color", color ? color : "");

        
  //       if (product_ids?.length > 0) {
  //         formData.append(
  //           "product_list",
  //           JSON.stringify(
  //             product_ids.length > 0 ? product_ids.map((main) => main.value) : []
  //           )
  //         );
  //       }

  //       // console.log(Object.fromEntries(formData.entries()));
  //       // return

  //       api
  //         .patch(`/groupadmin/category/${id}`, formData, { headers })
  //         .then((res) => {
  //           $(".loader").hide();
  //           setTimeout(() => {
  //             Swal.fire({
  //               position: "center-center",
  //               icon: "success",
  //               title: "Catagory details has been edited successfully.",
  //               showConfirmButton: false,
  //               timer: 1500,
  //             });
  //             history.push("/group_admin/menu_management/menu_category", {
  //               menuId: menuId,
  //               branchID: branchID,
  //             });
  //           });
  //         })
  //         .catch((err) => {
  //           $(".loader").hide();
  //           if (err.response) {
  //             setError(err.response?.data?.error?.message);
  //           }
  //         });
  //     }
  //   }
  //   else {
  //     $(".loader").show();

  //     const formData = new FormData();
  //     formData.append("menu_id", menuId);
  //     formData.append("branch_id", branchID);
  //     formData.append("is_active", is_active);
  //     formData.append("image", imgData);
  //     formData.append("seq_no", is_featured ? "0" : Number(seq_no));

  //     if (is_featured) {
  //       formData.append("is_featured", true);
  //     } else {
  //       formData.append("is_featured", false);
  //     }


  //     formData.append("name_EN", name_EN ? name_EN : "");

  //     formData.append("name_FR", name_FR ? name_FR : "");

  //     formData.append("name_IT", name_IT ? name_IT : "");

  //     formData.append("name_DE", name_DE ? name_DE : "");
  //     formData.append("name_AR", name_AR ? name_AR : "");

  //     formData.append("description_EN", description_EN ? description_EN : "");

  //     formData.append("description_FR", description_FR ? description_FR : "");
  //     formData.append("description_IT", description_IT ? description_IT : "");

  //     formData.append("description_DE", description_DE ? description_DE : "");
  //     formData.append("description_AR", description_AR ? description_AR : "");


  //     formData.append("disclaimer_EN", disclaimer_EN ? disclaimer_EN : "");

  //     formData.append("disclaimer_DE", disclaimer_DE ? disclaimer_DE : "");

  //     formData.append("disclaimer_FR", disclaimer_FR ? disclaimer_FR : "");

  //     formData.append("disclaimer_IT", disclaimer_IT ? disclaimer_IT : "");

  //     formData.append("disclaimer_AR", disclaimer_AR ? disclaimer_AR : "");

  //     formData.append("color", color ? color : "");
  //     if (product_ids?.length > 0) {
  //       formData.append(
  //         "product_list",
  //         JSON.stringify(
  //           product_ids.length > 0 ? product_ids.map((main) => main.value) : []
  //         )
  //       );
  //     }

  //     // console.log(Object.fromEntries(formData.entries()));

  //     api
  //       .patch(`/groupadmin/category/${id}`, formData, { headers })
  //       .then((res) => {
  //         $(".loader").hide();
  //         setTimeout(() => {
  //           Swal.fire({
  //             position: "center-center",
  //             icon: "success",
  //             title: "Catagory details has been edited successfully.",
  //             showConfirmButton: false,
  //             timer: 1500,
  //           });
  //           history.push("/group_admin/menu_management/menu_category", {
  //             menuId: menuId,
  //             branchID: branchID,
  //           });
  //         });
  //       })
  //       .catch((err) => {
  //         $(".loader").hide();
  //         if (err.response) {
  //           setError(err.response?.data?.error?.message);
  //         } else if (err.request) {
  //           setError(err.request);
  //         } else {
  //           setError(err.message);
  //         }
  //       });
  //   }
  // }


  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    // Basic validation
    if (name_EN.toString().trim().length < 3) {
      setError("Category Name must be at least 3 characters long");
      return;
    }
    if (!is_featured && seq_no === 0) {
      setError("Enter a valid sequence number.");
      return;
    }

    $(".loader").show();
    const formData = new FormData();
    formData.append("menu_id", menuId);
    formData.append("branch_id", branchID);
    formData.append("is_active", is_active);
    formData.append("seq_no", is_featured ? "0" : Number(seq_no));

    if (printer?.value && printer.value !== "No select") {
      formData.append("printer_text", printer.value);
      // formData.append("printer_category_name", printer.label);
    }
    // Image validation
    if (imgData) {
      const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validImageTypes.includes(imgData.type)) {
        setError("Please select a PNG, JPEG, or JPG image");
        $(".loader").hide();
        return;
      }
      formData.append("image", imgData);
    }

    if (is_featured) {
      formData.append("is_featured", true);
    } else {
      formData.append("is_featured", false);
    }

    formData.append("name_EN", name_EN ? name_EN : "");

    formData.append("name_FR", name_FR ? name_FR : "");

    formData.append("name_IT", name_IT ? name_IT : "");

    formData.append("name_DE", name_DE ? name_DE : "");
    formData.append("name_AR", name_AR ? name_AR : "");

    formData.append("description_EN", description_EN ? description_EN : "");

    formData.append("description_FR", description_FR ? description_FR : "");
    formData.append("description_IT", description_IT ? description_IT : "");

    formData.append("description_DE", description_DE ? description_DE : "");
    formData.append("description_AR", description_AR ? description_AR : "");


    formData.append("disclaimer_EN", disclaimer_EN ? disclaimer_EN : "");

    formData.append("disclaimer_DE", disclaimer_DE ? disclaimer_DE : "");

    formData.append("disclaimer_FR", disclaimer_FR ? disclaimer_FR : "");

    formData.append("disclaimer_IT", disclaimer_IT ? disclaimer_IT : "");

    formData.append("disclaimer_AR", disclaimer_AR ? disclaimer_AR : "");

    formData.append("color", color ? color : "");

    if (product_ids?.length > 0) {
      formData.append(
        "product_list",
        JSON.stringify(
          product_ids.length > 0 ? product_ids.map((main) => main.value) : []
        )
      );
    }

    // API call
    api
      .patch(`/groupadmin/category/${id}`, formData, { headers })
      .then((res) => {
        $(".loader").hide();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Category details have been edited successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/group_admin/menu_management/menu_category", {
          menuId,
          branchID,
        });
      })
      .catch((err) => {
        $(".loader").hide();
        const errorMsg =
          err.response?.data?.error?.message ||
          err.request ||
          err.message ||
          "An unknown error occurred";
        setError(errorMsg);
      });
  }


  function EdithandleSubmitImage(event) {
    event.preventDefault();

    setimage("");
    const formData = new FormData();
    formData.append("image", "");

    api
      .patch(`/groupadmin/category/removeImage/${id}`, formData, {
        headers,
      })
      .then((res) => {
        // Swal.fire({
        //   position: "center-center",
        //   icon: "success",
        //   title: "Product image has been edited successfully.",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        //  window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Menu Management
                    </h1>
                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Category - Information Category
                        </h3>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() =>
                              history.push(
                                "/group_admin/menu_management/menu_category",
                                {
                                  menuId: menuId,
                                  branchID: branchID,
                                }
                              )
                            }
                            className="btn btn-primary ps-7"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="rounded border p-5">

                      <CommonCatagory is_featured={is_featured} />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="card card-docs mb-2">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Category Name</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label className="required form-label">
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          value={name_EN}
                                          required
                                          onChange={(e) =>
                                            setname_EN(e.target.value)
                                          }
                                          style={
                                            languange.includes("English")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("English")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          AR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Arabic"
                                          value={name_AR}
                                          onChange={(e) =>
                                            setname_AR(e.target.value)
                                          }
                                          style={
                                            languange.includes("arabic")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("arabic")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={name_DE}
                                          onChange={(e) =>
                                            setname_DE(e.target.value)
                                          }
                                          style={
                                            languange.includes("Deutsch")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Deutsch")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          FR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={name_FR}
                                          onChange={(e) =>
                                            setname_FR(e.target.value)
                                          }
                                          style={
                                            languange.includes("français")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("français")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={name_IT}
                                          onChange={(e) =>
                                            setname_IT(e.target.value)
                                          }
                                          style={
                                            languange.includes("Italiano")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Italiano")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                    <br />

                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Disclaimer</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          value={disclaimer_EN}
                                          // required
                                          onChange={(e) =>
                                            setdisclaimer_EN(e.target.value)
                                          }
                                          style={
                                            languange.includes("English")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("English")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>

                                      <div className="mb-10">
                                        <label className=" form-label">
                                          AR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Arabic"
                                          value={disclaimer_AR}
                                          onChange={(e) =>
                                            setdisclaimer_AR(e.target.value)
                                          }
                                          style={
                                            languange.includes("arabic")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("arabic")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={disclaimer_DE}
                                          onChange={(e) =>
                                            setdisclaimer_DE(e.target.value)
                                          }
                                          style={
                                            languange.includes("Deutsch")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Deutsch")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          FR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={disclaimer_FR}
                                          onChange={(e) =>
                                            setdisclaimer_FR(e.target.value)
                                          }
                                          style={
                                            languange.includes("français")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("français")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={disclaimer_IT}
                                          onChange={(e) =>
                                            setdisclaimer_IT(e.target.value)
                                          }
                                          style={
                                            languange.includes("Italiano")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Italiano")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="rounded border p-5">
                                      <div className="mb-1">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={() =>
                                            setis_active(!is_active)
                                          }
                                          checked={is_active}
                                        //
                                        />
                                        <label className="form-check-label mx-3">
                                          <h6>Active</h6>
                                        </label>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="rounded border p-10">
                                      <div className="mb-7">
                                        <div className="rounded border p-5">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={() =>
                                              setis_featured(!is_featured)
                                            }
                                            checked={is_featured}
                                          />
                                          <label className="form-check-label mx-3">
                                            <h6>Featured Catagory</h6>
                                          </label>
                                        </div>
                                      </div>
                                      {console.log("is_featured", is_featured)}
                                      {is_featured ? "" :
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Catagory sequel number
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            placeholder="Enter catagory sequel number"
                                            value={seq_no}
                                            required
                                            onChange={(e) =>
                                              setseq_no(e.target.value)
                                            }
                                          />
                                        </div>}
                                      {is_featured ? (
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Product
                                          </label>
                                          <Select
                                            className="search-line"
                                            placeholder="Search..."
                                            isMulti={true}
                                            value={apiproductList}
                                            options={[
                                              // {
                                              //   label: "All",
                                              //   value: productList,
                                              // },
                                              ...productList,
                                            ]}
                                            onChange={(opt) => {
                                              setapiproductList(opt);
                                              setproduct_ids(opt);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="row col-md-12">
                                        <div className="col-md-4">
                                          {" "}
                                          <h6 className="mb-3">Image</h6>
                                          <div
                                            className="image-input image-input-outline mb-7"
                                            data-kt-image-input="true"
                                          >
                                            <img
                                              className="image-input-wrapper"
                                              src={
                                                image
                                                  ? image
                                                  : "/assets/media/svg/3377061_food_hor_mok_thai_icon.png"
                                              }
                                              alt="image"
                                              height="125px"
                                              width="125px"
                                              style={{ objectFit: "cover" }}
                                            ></img>
                                            <label
                                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                              data-kt-image-input-action="change"
                                              data-bs-toggle="tooltip"
                                              data-bs-dismiss="click"
                                            // title="Change avatar"
                                            >
                                              <i className="bi bi-pencil-fill fs-7" />
                                              <input
                                                type="file"
                                                name="avatar"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={onChangePicture}
                                              />
                                            </label>
                                            {image ? (
                                              <React.Fragment>
                                                <span
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                  data-kt-image-input-action="cancel"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-dismiss="click"
                                                >
                                                  <i
                                                    className="bi bi-x fs-2"
                                                    onClick={
                                                      EdithandleSubmitImage
                                                    }
                                                  />
                                                </span>
                                                <span
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                  data-kt-image-input-action="remove"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-dismiss="click"
                                                  s
                                                  title="Remove"
                                                >
                                                  <i
                                                    onClick={
                                                      EdithandleSubmitImage
                                                    }
                                                    className="bi bi-x fs-2"
                                                  />
                                                </span>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-8">
                                          <div className="mb-7">
                                            <label className=" form-label">
                                              Category Color
                                            </label>
                                            <input
                                              type="color"
                                              className="form-control form-control-solid"
                                              value={color}
                                              onChange={(e) =>
                                                setcolor(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>


                                        <div className="mb-6">
                                          <label className="form-label">
                                            Printer
                                          </label>

                                          <Select
                                            id="select2"
                                            required
                                            className="search-line"
                                            placeholder="Select Printer ..."
                                            options={PrinterList_}
                                            value={printer}
                                            onChange={(opt) => {
                                              setprinter(opt);
                                            }}
                                          />

                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6>Description</h6>
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">
                                          EN
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="English"
                                          value={description_EN}
                                          // required
                                          onChange={(e) =>
                                            setdescription_EN(e.target.value)
                                          }
                                          style={
                                            languange.includes("English")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("English")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>

                                      <div className="mb-10">
                                        <label className=" form-label">
                                          AR
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Arabic"
                                          value={description_AR}
                                          onChange={(e) =>
                                            setdescription_AR(e.target.value)
                                          }
                                          style={
                                            languange.includes("arabic")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("arabic")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          DE
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="German"
                                          value={description_DE}
                                          onChange={(e) =>
                                            setdescription_DE(e.target.value)
                                          }
                                          style={
                                            languange.includes("Deutsch")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Deutsch")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className="form-label">FR</label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="French"
                                          value={description_FR}
                                          onChange={(e) =>
                                            setdescription_FR(e.target.value)
                                          }
                                          style={
                                            languange.includes("français")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("français")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                      <div className="mb-10">
                                        <label className=" form-label">
                                          IT
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control form-control-solid"
                                          placeholder="Italian"
                                          value={description_IT}
                                          onChange={(e) =>
                                            setdescription_IT(e.target.value)
                                          }
                                          style={
                                            languange.includes("Italiano")
                                              ? { backgroundColor: "#f5f8fa" }
                                              : { backgroundColor: "#E0E0E0" }
                                          }
                                          readOnly={
                                            languange.includes("Italiano")
                                              ? false
                                              : true
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <br />
                                    <div
                                      className="loader"
                                      style={{ display: "none" }}
                                    >
                                      <img src="/assets/loader.gif" />
                                      {/* <img src="" alt="logo" /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-12"> {errorDiv} </div>

                                  <div className="modal-footer flex-center">
                                    {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) && (
                                      <button
                                        type="submit"
                                        id="kt_modal_new_address_submit"
                                        className="btn btn-primary"
                                      >
                                        <span className="indicator-label">
                                          Save
                                        </span>

                                      </button>)}
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
