import React, { useEffect, useState, useRef } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import api from "../../../CommonApi/axios";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import headers from "../../../CommonApi/headers";
import { QRCode } from "react-qrcode-logo";
import Select from "react-select";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Button from '@mui/material/Button';
import "./BranchDetails.css"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import moment from "moment";
import { getBranchDetaisById } from "../../../Store";
import { connect } from "react-redux";
import BranchCommon from "./BranchCommon";
import useDrawer from "../../../context/DrawerContext";
import useError from "../../Comman/Error/useError";
import { getCurrencyLabel } from "../../Comman/currencyUtils/currencyUtils";
import { getCurrencyLabelDropDown } from "../../Comman/CommonFunction/CommonFunction";
import { isSameDateError } from "@mui/x-date-pickers/internals/hooks/validation/useDateValidation";
import { BranchesPermissions, userPermissionType } from "../../Comman/UserPermission";

function GroupAdminBranchDetailView({ getBranchDetaisById, branchDetaislByID }) {

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  const userPermissionType_ = userPermissionType === "groupadmin"

  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ bihavior: "smooth" });
  }, []);


  const [isCheck, setIsCheck] = useState("")
  const [Time, setTime] = useState("")
  const [user, setuser] = useState([]);
  const [unRestrictedMenuId, setunRestrictedMenuId] = useState("");
  const [MenuDay, setMenuDay] = useState("");
  const [restrictedMenuId, setrestrictedMenuId] = useState("");

  const { error, showError, clearError } = useError();

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    groupAdminMenuList();
    if (id) {
      getBranchDetaisById(id);
    }
  }, []);

  useEffect(() => {
    groupAdminMenuListSetting();
  }, [id])

  const downloadFile = (value) => {
    window.location.href = `${value}`;
  };

  function groupAdminMenuList() {
    api
      .get(`/groupAdmin/menu/branchWise/${id}`, { headers })
      .then((res) => {
        // console.log("sda" ,res.data.data)
        const options = res.data.data.map((item) => ({
          label: item.menu_name,
          value: item._id,
          is_active: item.is_active
        }));
        setuser(options);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  function groupAdminMenuListSetting() {
    api
      .get(`/groupAdmin/branch/getMenuSetting/${id}`, { headers })
      .then((res) => {
        // console.log("getMenuSetting" ,res.data.data)
        setunRestrictedMenuId({
          label: (<div className="row">
            <div className="col-md-6" >{res.data.data?.unrestrictedMenuName}</div>
            <div className="col-md-6 text-end">
              {res.data.data?.unRestrictedMenuStatus == true ?
                <Button style={{ backgroundColor: '#ECFDF3', color: "#027A48", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 13 }} />}>
                  ACTIVE
                </Button> :
                res.data.data?.unRestrictedMenuStatus == false ?
                  <Button style={{ backgroundColor: "#F2F4F7", color: "#344054", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 13 }} />}>
                    INACTIVE
                  </Button>
                  : ""
              }
            </div>
          </div>),
          value: res.data.data?.unRestrictedMenuId,
          //  is_active : item.is_active
        })
        //  console.log(res.data.data?.is_restricted == true ? true : false)
        setIsCheck(res.data.data?.is_restricted == true ? true : "");
        setTime(res.data.data?.menuActiveTime)
        setrestrictedMenuId({

          label: (<div className="row">
            <div className="col-md-6" >{res.data.data?.restrictedMenuName}</div>
            <div className="col-md-6 text-end">
              {res.data.data?.restrictedMenuStatus === true ?
                <Button style={{ backgroundColor: '#ECFDF3', color: "#027A48", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 13 }} />}>
                  ACTIVE
                </Button> :
                res.data.data?.restrictedMenuStatus === false ?
                  <Button style={{ backgroundColor: "#F2F4F7", color: "#344054", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 13 }} />}>
                    INACTIVE
                  </Button> : ""}
            </div>
          </div>),
          value: res.data.data?.restrictedMenuId,
        })
        setMenuDay(
          {
            label: res.data.data?.menuActiveDays,
            value: res.data.data?.menuActiveDays,
          }
        )
        // const options = res.data.data.map((item) => ({
        //   label: item.menu_name ,
        //   value: item._id,
        //   is_active : item.is_active
        // }));
        // setuser(options);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err?.response?.data);
        }
      });
  }

  const downloadCodeForBranch = (branch_name_EN) => {
    const canvas = document.getElementById("BRANCH_QR");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${branch_name_EN}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isCheck ? "#fff" : '#eff2f5',
      borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
      '&:hover': {
        borderColor: "hsl(0, 0%, 80%)"
      },
      boxShadow: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
      '&:hover': {
        borderColor: "hsl(0, 0%, 80%)"
      }
    }),
  };

  const selectStyles1 = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isCheck ? "#fff" : '#eff2f5',
      borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
      '&:hover': {
        borderColor: "hsl(0, 0%, 80%)"
      },
      boxShadow: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
      '&:hover': {
        borderColor: "hsl(0, 0%, 80%)"
      }
    }),
  };

  console.log("Time", Time)


  function handalSubmit(event) {
    event.preventDefault();
    clearError(null)

    if (unRestrictedMenuId === "") {
      showError("Please select menu");
      return
    }
    else {
      if (isCheck) {
        if (MenuDay === "") {
          showError("Please fill regular menu available until the days");
          return
        }
        else if (Time === undefined || Time === "") {
          showError("Please fill regular menu available until the time");
          return
        }
        else if (restrictedMenuId === "") {
          showError("Please select a menu available after");
          return
        } else {

          const item = new FormData();
          item.append("is_restricted", isCheck ? isCheck : false,);
          item.append("menuActiveDays", MenuDay?.value ? MenuDay?.value : "00");
          if (Time) {
            item.append("menuActiveTime", Time);
          }
          if (restrictedMenuId?.value) {
            item.append("restrictedMenuId", restrictedMenuId?.value,);
          }
          if (unRestrictedMenuId?.value) {
            item.append("unRestrictedMenuId", unRestrictedMenuId?.value);
          }
          // console.log(Object.fromEntries(item.entries()));
          // return

          api
            .post(`/groupadmin/branch/addRegularMenu/${id}`, item, { headers })
            .then((res) => {
              clearError()
              Swal.fire({
                position: "top-center",
                icon: "success",
                title: "Saved Successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              groupAdminMenuListSetting();
              // history.goBack();
            })
            .catch((err) => {
              if (err.response) {
                showError(err.response?.data?.error?.message);
              }
            });
        }
      } else {


        const item = new FormData();
        item.append("is_restricted", isCheck ? isCheck : false,);
        item.append("menuActiveDays", MenuDay?.value ? MenuDay?.value : "00");
        if (Time) {
          item.append("menuActiveTime", Time);
        }
        if (restrictedMenuId?.value) {
          item.append("restrictedMenuId", restrictedMenuId?.value,);
        }
        if (unRestrictedMenuId?.value) {
          item.append("unRestrictedMenuId", unRestrictedMenuId?.value);
        }
        // console.log("item" ,item)
        api
          .post(`/groupadmin/branch/addRegularMenu/${id}`, item, { headers })
          .then((res) => {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Saved Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            clearError()
            groupAdminMenuListSetting();
            // history.goBack();
          })
          .catch((err) => {
            if (err.response) {
              showError(err.response?.data?.error?.message);
            }
          });
      }
    }
  }

  const currencyLabel = getCurrencyLabel(branchDetaislByID?.resData?.currency);


  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const data = [
    {
      className: "col-xl-6",
      bgColor: "bg-primary",
      title: "This Month Revenue",
      value: Number(branchDetaislByID.thisMonthRevenue).toFixed(2) ?? Number(0).toFixed(2)
    },
    {
      className: "col-xl-6",
      bgColor: "bg-info",
      title: "Last Month Revenue",
      value: Number(branchDetaislByID.previousMonthRevenue).toFixed(2) ?? Number(0).toFixed(2)
    },
    {
      className: "col-xl-6",
      bgColor: "bg-success",
      title: "Total Revenue",
      value: Number(branchDetaislByID.totalRevenue).toFixed(2) ?? Number(0).toFixed(2)
    },
    {
      className: "col-xl-6",
      bgColor: "bg-danger",
      title: "Total Orders",
      value: branchDetaislByID.orderCount ? branchDetaislByID.orderCount : 0
    }
  ];


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div ref={scrollRef} classbeginName="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={maingetViewDetails.join(" ")}>
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Branches
                        </h1>
                      </div>
                    </div>

                    <hr style={{ opacity: 0.07, marginTop:"5px" }} />
                    <div className="d-flex flex-stack mb-1">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        {branchDetaislByID?.resData?.branch_name_EN ?
                          <h3
                            className="anchor fw-bolder mb-5"
                            id="custom-form-control"
                          >
                            Branch - {branchDetaislByID?.resData?.branch_name_EN}
                          </h3> : ""}
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() =>
                              history.push(
                                "/group_admin/branch_management"
                              )
                            }
                            className="btn btn-primary"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={"rounded border p-5"}>
                      <BranchCommon />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        ><div className="card card-docs mb-2">
                            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                              <h1
                                className="anchor fw-bolder mb-5"
                                id="vertical-horizontal-scroll"
                                style={{ float: "left" }}
                              >
                                Branch Details
                              </h1>

                              <br />
                              <br />
                              <hr />
                              <br />

                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    {branchDetaislByID?.resData ?
                                      <ul>
                                        <li>
                                          <h6>
                                            Branch Name :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {" "}
                                              {branchDetaislByID?.resData?.branch_name_EN}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Branch Location :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {branchDetaislByID?.resData?.address}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Branch Email :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {branchDetaislByID?.resData?.email}
                                            </strong>
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Phone Number :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {branchDetaislByID?.resData?.phone_number}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Currency :{" "}
                                            <strong style={{ color: "#606361" }}>  {getCurrencyLabelDropDown(currencyLabel)}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            VAT Number :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {branchDetaislByID?.resData?.VAT_number}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Sequence Number :{" "}
                                            <strong style={{ color: "#606361" }}>
                                              {branchDetaislByID?.resData?.seq_no}
                                            </strong>{" "}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6>
                                            Terms and Conditions :{" "}
                                            <span className="mx-2">
                                              {branchDetaislByID?.resData?.doc_url !== undefined ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-success"
                                                  onClick={() => downloadFile(branchDetaislByID?.resData?.doc_url)}
                                                >
                                                  Download
                                                </button>
                                              ) : (
                                                <button type="button" className="btn btn-secondary">
                                                  No Document
                                                </button>
                                              )}
                                            </span>
                                          </h6>
                                        </li>
                                      </ul> : ""}
                                    <br />
                                    <div>
                                      <div>
                                        <label
                                          className="form-label"
                                        >
                                          <h3> Branch Menu QR Code</h3>
                                        </label>
                                      </div>
                                      <QRCode
                                        value={`${branchDetaislByID?.url}/${encodeURIComponent(branchDetaislByID?.resData?.branch_name_EN)}/menu`}
                                        size={256}
                                        style={{
                                          height: "auto",
                                          maxWidth: "40%",
                                          width: "40%",
                                        }}
                                        level={"H"}
                                        id="BRANCH_QR"
                                        includeMargin={true}
                                      />
                                      <br />
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="text-center">
                                            <a className="settingss btn btn-success btn-sm" style={{ fontSize: "inherit" }} onClick={() => downloadCodeForBranch(branchDetaislByID?.resData?.branch_name_EN)}>
                                              {" "}
                                              Download QR{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="row">
                                      {data?.map((item, index) => (
                                        <div key={index} className={item.className}>
                                          <Link to="#" className={`card ${item.bgColor} hoverable card-xl-stretch mb-xl-8`}>
                                            <div className="card-body">
                                              <center>
                                                <div className="text-white fw-bolder fs-2 mb-2 mt-5">{item.title}</div>
                                                <div className="text-white fw-bolder fs-2 mb-2 mt-5">{item.title === "Total Orders" ? "" : currencyLabel} {item.value}</div>
                                              </center>
                                            </div>
                                          </Link>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <br />
                              <div className="pt-10">
                                <form onSubmit={handalSubmit}>
                                  <div className="">

                                    <div className="d-flex flex-stack mb-5">
                                      <div
                                        className="d-flex justify-content-end"
                                        data-kt-docs-table-toolbar="base"
                                      >
                                        <h1
                                          className="anchor fw-bolder"
                                          id="custom-form-control"
                                          style={{ fontSize: 22 }}
                                        >
                                          Menu Setting
                                        </h1>
                                      </div>

                                    </div>
                                    <h4
                                      className="anchor fw-bolder"
                                      id="custom-form-control"
                                      style={{ fontSize: 16 }}
                                    >
                                      Regular Menu
                                    </h4>
                                    <div>
                                      Adding the Time Interval for the Regular Menu.
                                    </div>

                                    <hr />
                                    <div className="row mt-10">
                                      <div className="col-md-6 col-sm-12 my-2">

                                        <h6 className="Branch_selection ">Select A Menu</h6>
                                      </div>
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <Select
                                          placeholder="Select Menu ..."
                                          options={user?.length > 0 ? user?.map((main) => (
                                            {
                                              label: (<div className="row">
                                                <div className="col-md-6" >{main?.label}</div>
                                                <div className="col-md-6 text-end">
                                                  {main?.is_active == true ?
                                                    <Button style={{ backgroundColor: '#ECFDF3', color: "#027A48", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 13 }} />}>
                                                      ACTIVE
                                                    </Button> : <Button style={{ backgroundColor: "#F2F4F7", color: "#344054", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 13 }} />}>
                                                      INACTIVE
                                                    </Button>}
                                                </div>
                                              </div>),
                                              value: main?.value,

                                            }
                                          )) : ""}
                                          value={unRestrictedMenuId}
                                          onChange={(opt) => {
                                            setunRestrictedMenuId(opt)
                                          }
                                          }
                                          className="search-line"
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-10">
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <h6 className="Branch_selection ">Enable Regular Menu Available Until
                                          <span className="mx-2">
                                            <LightTooltip title="By enabling this you can manage the timings of your regular menu." placement="top">
                                              <HelpOutlineOutlinedIcon style={{ fontSize: 20, marginBottom: 3, color: "#98A2B3" }} />
                                            </LightTooltip>
                                          </span>
                                        </h6>
                                      </div>
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <label className="switch">
                                          <input
                                            checked={isCheck}
                                            onChange={() => {
                                              clearError(null)
                                              setIsCheck((state) => !state);
                                            }} type="checkbox" />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="row mt-8">
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <h6 className="Branch_selection " style={{ marginTop: "22px", color: isCheck ? "#344054" : "#98A2B3" }}>Regular Menu Available Until</h6>
                                      </div>
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className=" form-label" style={{ color: isCheck ? "#344054" : "#98A2B3" }}>Days</label>
                                            <Select
                                              placeholder="Select..."
                                              className="custom-select-container"
                                              value={MenuDay}
                                              isDisabled={isCheck ? false : true}
                                              options={isCheck ? [
                                                { label: "00", value: "00" },
                                                { label: "01", value: "01" },
                                                { label: "02", value: "02" },
                                                { label: "03", value: "03" },
                                                { label: "04", value: "04" },
                                                { label: "05", value: "05" },
                                                { label: "06", value: "06" },
                                                { label: "07", value: "07" },
                                                { label: "08", value: "08" },
                                                { label: "09", value: "09" },
                                                { label: "10", value: "10" }
                                              ] : ''}
                                              styles={selectStyles}
                                              onChange={(opt) => setMenuDay(opt)}
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label className="required form-label" style={{ color: isCheck ? "#344054" : "#98A2B3" }}>Time</label>
                                            <input style={{ height: 38 }} disabled={isCheck ? false : true} type="time" className="form-control" value={Time}
                                              onChange={(e) => setTime(e.target.value)} />
                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                    <div className="row mt-10">
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <h6 className="Branch_selection " style={{ color: isCheck ? "#344054" : "#98A2B3" }}>Select A Menu Available After</h6>
                                      </div>
                                      <div className="col-md-6 col-sm-12 my-2">
                                        <Select
                                          placeholder="Select Menu ..."
                                          // isSearchable={false}
                                          isDisabled={isCheck ? false : true}
                                          options={isCheck ?
                                            user?.length > 0 ? user?.map((main) => (
                                              {
                                                label: (<div className="row">
                                                  <div className="col-md-6" >{main?.label}</div>
                                                  <div className="col-md-6 text-end">
                                                    {main?.is_active == true ?
                                                      <Button style={{ backgroundColor: '#ECFDF3', color: "#027A48", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 13 }} />}>
                                                        ACTIVE
                                                      </Button> : <Button style={{ backgroundColor: "#F2F4F7", color: "#344054", borderRadius: "12px", height: 23, marginBottom: "1px" }} size="small" variant="contained" startIcon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 13 }} />}>
                                                        INACTIVE
                                                      </Button>}
                                                  </div>
                                                </div>),
                                                value: main?.value
                                              }
                                            )) : '' : ""
                                          }
                                          value={restrictedMenuId}
                                          menuPosition="fixed"
                                          styles={selectStyles1}
                                          onChange={(opt) => setrestrictedMenuId(opt)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {error && <div className="col-md-12 mt-5">
                                    <div className="text-center alert alert-danger">{error}</div> </div>}
                                  <div
                                    className="d-flex justify-content-center mx-2 mt-12 mb-7"
                                  >

                                    {(userPermissionType_ || (BranchesPermissions?.access?.includes("create") || BranchesPermissions?.access?.includes("edit"))) && (
                                      <button
                                        style={{ width: "110px" }}
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Save
                                      </button>
                                    )}
                                    <button
                                      onClick={(e) => {
                                        window.location.reload(true);

                                      }}
                                      style={{ width: "110px" }}
                                      className="btn btn-danger mx-2"
                                      type="button"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}



const mapStateToProps = (state) => ({
  branchDetaislByID: state?.branch?.branchDetaislByID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetaisById: (id) => dispatch(getBranchDetaisById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminBranchDetailView);
