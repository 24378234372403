import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useDrawer from "../../../../context/DrawerContext";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../Comman/UserPermission";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import { tableOptionBranchList } from "../../../NewTableCommon/tableOption";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
window.jquery = window.$ = $;

export default function GroupAdminReasonsManagement() {
  const userPermissionType_ = userPermissionType === "groupadmin"

  let history = useHistory();

  useEffect(() => {
    groupAdminReasonList();
  }, []);

  const [voucher, serVoucher] = useState([]);
  const [Id__, setId] = useState("");

  // console.log("id" ,Id__)
  function groupAdminReasonList() {
    api
      .get(`/groupadmin/discount`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        serVoucher(tableData);
        // console.log(tableData)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [reason, setreason] = useState("")
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (reason === "") {
      setError("Please enter reason.");
      return;
    }
    else {
      $(".loader").show();

      const item = new FormData();
      item.append("reason", reason);


      api
        .post(`/groupadmin/discount`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          groupAdminReasonList();
          showSuccessNotification("Discount reason has been added succesfully.")
          handleClose();
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }

  }

  function handleSubmitEdit(event) {
    event.preventDefault();
    setError(null);
    if (reason === "") {
      setError("Please enter reason.");
      return;
    }
    else {
      $(".loader").show();
      const item = new FormData();
      item.append("reason", reason);
      api
        .patch(`/groupadmin/discount/${Id__}`, item, { headers })
        .then((res) => {
          $(".loader").hide();
          groupAdminReasonList();
          setreason("");
          showSuccessNotification("Discount reason has been edited succesfully.")
          handleClose();
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }

  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setError(null);
    setId("");
    setShow(false)
  }

  const handleShow = () => setShow(true);
  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[4];

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null

  const handleEditDiscountReasons = (Data) => {
    setreason(Data?.discount_reason);
    setId(Data._id);
    handleShow();
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  // console.log("UserManagmentList", UserManagmentList)

  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 50,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'discount_reason',
        header: 'Reason',
        size: 1000,
        Cell: ({ row }) => (row?.original?.discount_reason || "---"),
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const EDIT = UserPermissionAllData?.role_details?.settings?.some(permission =>
    permission?.key === "discount" &&
    permission?.access?.includes("edit")
  );
  const DELETE = UserPermissionAllData?.role_details?.settings?.some(permission =>
    permission?.key === "discount" &&
    permission?.access?.includes("delete")
  );
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: voucher,
    ...tableOptionBranchList({
      groupAdminReasonList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history,
      handleEditDiscountReasons
    }),
  });

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="content d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>

                    <div className="d-flex flex-stack mb-7">
                      <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <h1 className="anchor fw-bolder" id="custom-form-control">
                          Discount Management
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                            permission?.key === "discount" &&
                            permission?.access?.includes("create")
                          )) && (
                              <button
                                className="btn btn-primary mx-2"
                                onClick={() => {
                                  handleShow();
                                  setreason("");
                                  setId('');
                                }
                                }
                              >
                                Add Reason
                              </button>)}

                          <button onClick={() => history.push("/group_admin/settings/discount_and_tips_management")} className="btn btn-primary">Go Back</button>
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: "5px" }} />
                    <div className="row col-md-12 mb-5">
                      <div
                        className="col-md-12"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Discount Reasons
                        </h3>
                      </div>

                   
                    </div>

                    <div className={"rounded border p-5"}>
                      {/* <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                      <li className="nav-item">
                        <Link
                          className={
                            url === "discout_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "/group_admin/discount_and_tips_management/discout_management",
                          }}
                        >
                          Discount Management
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            url === "reasons_management"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname:
                              "//group_admin/discount_and_tips_management/reasons_management",
                          }}
                        >
                          Reasons
                        </Link>
                      </li>
                    </ul> */}

                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        />

                      </div>
                    </div>

                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>


      <ColumPopoverCommon
        anchorEl_={anchorEl_}
        handleClickColum_={handleClickColum_}
        onColumnDataChange={onColumnDataChange}
        handleCloseColum_={handleCloseColum_}
        url23={url}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h1>{Id__ ? "Edit Reason" : "Add Reason"} </h1>
          <Form onSubmit={Id__ ? handleSubmitEdit : handleSubmit}>
            <Form.Group className="mb-3 mt-3" >
              <Form.Label>Please enter an appropriate reason. <span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reason}
                onChange={(e) => setreason(e.target.value.replace(/^\s/, ''))}
              />
            </Form.Group>


            <div className="col-md-12 text-center">
              <br />
              <div className="loader" style={{ display: "none" }}>
                <img src="/assets/loader.gif" />
              </div>
            </div>
            <ErrorMessage error={error} />
            <Row className="text-center justify-content-center mx-1">
              <Col lg={6} md={6} xs={6}>
                <Button style={{ width: "100%" }} variant="primary" type="submit">
                  {Id__ ? "Edit" : "Save"}
                </Button>
              </Col>
              <Col lg={6} md={6} xs={6}>
                <Button style={{ width: "100%" }} variant="secondary" type="button" className="btn btn-danger"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>



    </React.Fragment>
  );
}


