import React, { useState, useEffect } from "react";
import api from "../../CommonApi/axios";
import headers from "../../CommonApi/headers";
import $ from "jquery";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ClearIcon from "@mui/icons-material/Clear";
import "./Header.css";
import Badge from '@mui/material/Badge';
import { Link, useHistory } from "react-router-dom";
import * as mqtt from "mqtt/dist/mqtt";
import mqttdata from "../../api/mqtt";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import packageJson from "../../../package.json"
import { EnquiryPermissions, KPGPTPermissions, ReportsPermissions, SettingsPermissions, UserPermissionAllData, userPermissionType } from "./UserPermission";
window.jquery = window.$ = $;

export default function GroupAdminHeader(props) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [Enquiry, setEnquiry] = useState("");

  useEffect(() => {
    api
      .get(`/groupAdmin/inquiry/count`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setEnquiry(tableData);
      })
      .catch((err) => { });
  }, [UserPermissionAllData?.customToken]);

  // console.log("Enquiry", Enquiry)
  $(document).ready(function (e) {
    $(".cursor-pointer").click(function () {
      $("#togall").show();
    });
    setTimeout(function () {
      $("#togall").fadeOut("fast");
    }, 5000);
  });

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  var url12 = pathnamesplits[1];

  // console.log("url" ,url)

  document.addEventListener("mouseup", function (e) {
    var container = document.getElementById("togall");
    if (!container.contains(e.target)) {
      container.style.display = "none";
    }
  });

  let history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();
    localStorage.removeItem("customToken");
    localStorage.removeItem("RGAWebVersion");
    localStorage.removeItem("RGALOGO");
    localStorage.removeItem("GroupAdminID");
    localStorage.removeItem("RGAICON");
    localStorage.removeItem('GroupAdminRes_Name');
    localStorage.removeItem("RESGroup");
    localStorage.removeItem("RedirectUrl");
    localStorage.removeItem("DataSub");
    sessionStorage.removeItem("dashboardURl");
    sessionStorage.clear()
    history.push("/");
    window.location.reload(true);
  }


  const currentVersion = packageJson.version;  // Get the current version from .env
  const savedVersion = localStorage.getItem('RGAWebVersion'); // Get saved version from localStorage

    


  useEffect(() => {
    if (savedVersion !== currentVersion) {
      // New build detected, clear localStorage
      localStorage.removeItem("customToken");
      localStorage.removeItem("RGALOGO");
      localStorage.removeItem("GroupAdminID");
      localStorage.removeItem("RGAICON");
      localStorage.removeItem('GroupAdminRes_Name');
      localStorage.removeItem("RESGroup");
      localStorage.removeItem("RedirectUrl");
      localStorage.removeItem("DataSub");
      sessionStorage.removeItem("dashboardURl");
      localStorage.removeItem("RGAWebVersion");
      sessionStorage.clear()
      localStorage.setItem('RGAWebVersion', currentVersion);
      history.push("/");
      window.location.reload(true);
    }
  }, [savedVersion !== currentVersion]);

  // let GroupAdminRes_Name = localStorage.getItem("GroupAdminRes_Name")


  const [user, setuser] = useState("");

  useEffect(() => {
    api
      .get(`/groupadmin/profile`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setuser(tableData);
      })
      .catch((err) => {
        if (err.response) {
          const errorData = err.response.data;
          if (errorData.status === 401) {
            localStorage.removeItem("customToken");
            localStorage.removeItem("RGALOGO");
            localStorage.removeItem("GroupAdminID");
            localStorage.removeItem("RGAICON");
            sessionStorage.removeItem("dashboardURl");
            localStorage.removeItem("RedirectUrl");
            localStorage.removeItem("DataSub");
            sessionStorage.clear()
            // localStorage.removeItem('GroupAdminRes_Name');
            history.push("/");
            window.location.reload(true);
          } else if (errorData.error && errorData.error.message === "Authentication Failed. Please login again.") {
            localStorage.removeItem("customToken");
            localStorage.removeItem("RGALOGO");
            localStorage.removeItem("GroupAdminID");
            localStorage.removeItem("RGAICON");
            sessionStorage.removeItem("dashboardURl");
            localStorage.removeItem("RedirectUrl");
            localStorage.removeItem("DataSub");
            sessionStorage.clear()
            // localStorage.removeItem('GroupAdminRes_Name');
            history.push("/");
            window.location.reload(true);
          }
        }
      });
  }, [UserPermissionAllData?.customToken]);


  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const UserPermissionAllData_ = UserPermissionAllData && UserPermissionAllData?.role_details



  const menuItems = [
    {
      url: "/group_admin/dashboard",
      label: "Dashboard",
      icon: "bi bi-house",
      backgroundColor: "dashboard",
      compare: "dashboard",
      subcription: "dashboard"
    },
    {
      url: "/group_admin/branch_management",
      label: "Branches",
      icon: "bi bi-geo-alt",
      backgroundColor: [
        "branch_management",
        "branch_detail_view",
        "add_manager",
        "add_staff",
        "add_table",
        "edit_branch_details",
        "add_branch",
        "edit_manager_details",
        "edit_staff_details",
      ],
      compare: "branch",
      subcription: "branch"
    },
    {
      url: "/group_admin/table_management",
      label: "Tables",
      icon: "bi bi-calendar3",
      backgroundColor: "table_management",
      compare: "tables",
      subcription: "tables"
    },
    {
      url: "/group_admin/order_management",
      label: "Orders",
      icon: "bi bi-journals",
      backgroundColor: "order_management",
      compare: "orders",
      subcription: "orders"
    },
    {
      url: "/group_admin/menu_management",
      label: "Menus",
      icon: "bi bi-menu-button-wide",
      backgroundColor: [
        "menu_management",
        "add_new_menu",
        "menu_information",
        "menu_category_detail_information",
        "menu_category_detail_products",
        "product_details_information",
        "product_details_discriptaion",
        "product_details_declaration",
        "product_details_time_filter",
        "add_new_product",
        "menu_category",
        "edit_menu_category",
      ],
      compare: "menus",
      subcription: "menus"
    },
    {
      url: "/group_admin/table_reservations",
      label: "Table Reservations",
      icon: "bi bi-table",
      backgroundColor: [
        "table_reservations",
        "table_reservations_details"],
      compare: "table_reservations"
    },
    {
      url: "/group_admin/stock_management",
      label: "Stock Management",
      icon: "bi bi-box-seam",
      backgroundColor: [
        "stock_management",
        "view_stock", "add_stock"],
      compare: "stock"
    },
    {
      url: "/group_admin/coupon_voucher_management",
      label: "Coupons & Vouchers",
      icon: "bi bi-cash-coin",
      backgroundColor: [
        "coupon_voucher_management",
        "coupon_management",
        "voucher_management",
      ],
      compare: ["coupons", "vouchers"],
      subcription: "coupon_voucher_management"
    },

    {
      url: "/group_admin/tour",
      label: "Tour Groups",
      icon: "fa fa-users",
      backgroundColor: "tour",
      compare: "tour_groups",
      subcription: "tour_group_setup"
    },
    {
      url: "/group_admin/email_marketing",
      label: "Email Marketing",
      icon: "fa fa-envelope",
      backgroundColor: "email_marketing",
      compare: "email_marketing",
      subcription: "email_marketing"
    },
    {
      url: "/group_admin/subscription_management",
      label: "Subscriptions",
      icon: "bi bi-journal-text",
      backgroundColor: "subscription_management",
      compare: "subscriptions",
      subcription: "subscriptions"
    },
    {
      url: "/group_admin/enquiry",
      label: "Enquiries",
      icon: "bi bi-person-lines-fill",
      backgroundColor: "enquiry",
      compare: "enquiries",
      subcription: "enquiry_management"
    },
    {
      url: "/group_admin/report",
      label: "Reports",
      icon: "far fa-file",
      backgroundColor: "report",
      compare: "report",
      subcription: "report"
    },
    {
      url: "/group_admin/kpgpt",
      label: "KPGPT",
      icon: "far fa-robot",
      backgroundColor: "kpgpt",
      compare: "KPGPT",
      subcription: "KPGPT"
    },
    {
      url: "/group_admin/settings",
      label: "Settings",
      icon: "bi bi-gear",
      backgroundColor: "settings",
      compare: "settings",
      subcription: "settings"
    },
  ];


  // let filteredMenuItems = [...menuItems];
  // if (userPermissionType === "TGA") {
  //   filteredMenuItems = filteredMenuItems.filter(item => item.label.includes("Tour Groups") ||
  //             item.label.includes("Settings") ||
  //             item.label.includes("Reports")
  // );
  // }

  function formatKey(key) {
    return key?.toLowerCase()?.replace(/\s+/g, '_'); // Example implementation
  }

  function filterMenuItems(permissions, menuItems) {
    // Create a set of sections that have any access permissions
    const permissionsMap = new Set(
      permissions
        ?.filter(item => item.access.length > 0)
        ?.map(item => formatKey(item.key))
    );




    // Filter menuItemsArray based on the permissionsMap
    const filteredMenuItems = menuItems?.filter(menuItem => {
      if (menuItem.label === "Coupons & Vouchers") {
        // Special case for "coupon_voucher_management"
        return permissionsMap?.has("coupons") || permissionsMap?.has("vouchers");
      }
      if (menuItem.compare === "Reports") {
        return ReportsPermissions?.length > 0;
      }
      if (menuItem.compare === "Settings") {
        return SettingsPermissions?.length > 0;
      }

      // General case for all other sections
      return permissionsMap?.has(formatKey(menuItem.compare));
    });

    return filteredMenuItems;
  }

  // Example usage
  const filteredMenuItems__ = filterMenuItems(UserPermissionAllData_?.permissions, menuItems);

  let filteredMenuItems = userPermissionType === "groupadmin" ? menuItems : filteredMenuItems__

  // console.log("filteredMenuItems" ,filteredMenuItems)

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;

  // let shouldShowCouponVoucher;
  const shouldShowCouponVoucher = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features
  const MenuItemsRGA = shouldShowCouponVoucher ? filteredMenuItems.filter(item => {
    if (item.label === "Coupons & Vouchers") {
      return (shouldShowCouponVoucher?.includes("vouchers") ||
        shouldShowCouponVoucher?.includes("coupons")
      );
    }

    if (item.label === "Tour Groups") {
      return shouldShowCouponVoucher?.includes("tour_group_setup") // Only show if 'enquiries' is in features
    }

    if (item.label === "Enquiries") {
      return shouldShowCouponVoucher?.includes("enquiry_management")// Only show if 'vouchers' is in features
    }

    return filteredMenuItems;
  }) : filteredMenuItems.filter(item =>
    !["enquiry_management", "tour_group_setup", "coupon_voucher_management"].includes(item.subcription)
  );


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 220 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ overflowX: "hidden" }}
    >
      <div className="my-2">
        <div className="row mx-2">
          <div className="text-end">
            <ClearIcon style={{ fontSize: "26px" }} />
          </div>
        </div>
      </div>
      <br />
      {MenuItemsRGA?.map((item, index) => (
        <div key={index} style={{
          textAlign: "start",
          backgroundColor: determineBackgroundColor(item.backgroundColor)
        }}>
          <Link className="mx-2" style={{ border: 0 }} to={item.url}>
            <i
              style={{
                color: determineIconColor(item.backgroundColor),
                backgroundColor: determineBackgroundColor(item.backgroundColor),
              }}
              className={`${item.icon} mx-2 icon_`}
            />
            <span
              style={{ color: determineIconColor(item.backgroundColor) }}
              className="menu_title"
            >
              {item.label}
            </span>
          </Link>
          <hr />
        </div>
      ))}
    </Box>
  );

  const determineBackgroundColor = (criteria) => {
    if (Array.isArray(criteria)) {
      return criteria.includes(url) ? "#F25042" : "white";
    } else {
      return url === criteria ? "#F25042" : "white";
    }
  };

  // Function to determine icon color based on the provided criteria
  const determineIconColor = (criteria) => {
    if (Array.isArray(criteria)) {
      return criteria.includes(url) ? "white" : "";
    } else {
      return url === criteria ? "white" : "";
    }
  };

  const [Payload, setPayload] = useState("");
  const [PayloadPayment, setPayloadPayment] = useState("");



  const GroupAdminID = localStorage.getItem("GroupAdminID");

  // useEffect(() => {


  //   let connectUrl =
  //     `${process.env.REACT_APP_BASE_URL ===
  //       "https://groupapi.kitchenparameter.com"
  //       ? "wss"
  //       : process.env.REACT_APP_BASE_URL ===
  //         "https://groupapidemo.kitchenparameter.com"
  //         ? "wss" : process.env.REACT_APP_BASE_URL ===
  //           "https://goldenindia-restaurantgroupapi.creatoro.in" ?
  //           "wss"
  //           : "ws"
  //     }:` +
  //     mqttdata.mhost +
  //     ":" +
  //     mqttdata.mport;

  //   var OPTIONS = {
  //     clientId: "mqttjs_" + Math.random().toString(16).substring(2, 8),
  //   };
  //   var client = mqtt.connect(
  //     process.env.REACT_APP_BASE_URL === "https://groupapi.kitchenparameter.com"
  //       ? "wss://socket.kitchenparameter.com"
  //       : process.env.REACT_APP_BASE_URL ===
  //         "https://goldenindia-restaurantgroupapi.creatoro.in" ?
  //         "wss://socket.creatoro.in" : process.env.REACT_APP_BASE_URL ===
  //           "https://groupapidemo.kitchenparameter.com" ?
  //           "wss://socket.creatoro.in" : "ws://3.12.248.21:9001",
  //     OPTIONS
  //   );

  //   const topic = `orderalertDineIn-${GroupAdminID}`;

  //   client.on("connect", () => {
  //     console.log("Connected");
  //   });
  //   client.on("error", (err) => {
  //     console.error("Connection error: ", err);
  //     client.end();
  //   });
  //   client.on("reconnect", () => {
  //     console.log("Reconnecting");
  //   });
  //   client.subscribe(topic, { qos: 0 }, (error) => {
  //     if (error) {
  //       console.log("Subscribe to topics error", error);
  //       return;
  //     }
  //     console.log("sub done");
  //   });
  //   client.on("message", (topic, message) => {
  //     const payload = { topic, message: message?.toString() };
  //     setPayload(JSON.parse(payload?.message));

  //     const soundUrl = `https://ia800203.us.archive.org/14/items/slack_sfx/been_tree.mp3`;
  //     const notificationSound = new Audio(soundUrl);
  //     notificationSound.play();
  //   });
  // }, []);


  // useEffect(() => {


  //   var OPTIONS = {
  //     clientId: "mqttjs_" + Math.random().toString(16).substring(2, 8),
  //   };
  //   var client = mqtt.connect(
  //     process.env.REACT_APP_BASE_URL === "https://groupapi.kitchenparameter.com"
  //       ? "wss://socket.kitchenparameter.com"
  //       : process.env.REACT_APP_BASE_URL ===
  //         "https://goldenindia-restaurantgroupapi.creatoro.in" ?
  //         "wss://socket.creatoro.in" : process.env.REACT_APP_BASE_URL ===
  //           "https://groupapidemo.kitchenparameter.com" ?
  //           "wss://socket.creatoro.in" : "ws://3.12.248.21:9001",
  //     OPTIONS
  //   );

  //   const topic = `orderalertDineInOnlinePayment-${GroupAdminID}`;

  //   client.on("connect", () => {
  //     console.log("Connected1");
  //   });
  //   client.on("error", (err) => {
  //     console.error("Connection error1: ", err);
  //     client.end();
  //   });
  //   client.on("reconnect", () => {
  //     console.log("Reconnecting1");
  //   });
  //   client.subscribe(topic, { qos: 0 }, (error) => {
  //     if (error) {
  //       console.log("Subscribe to topics error1", error);
  //       return;
  //     }
  //     console.log("sub done1");
  //   });
  //   client.on("message", (topic, message) => {
  //     const payload = { topic, message: message?.toString() };
  //     setPayloadPayment(JSON.parse(payload?.message));

  //     const soundUrl = `https://ia800203.us.archive.org/14/items/slack_sfx/been_tree.mp3`;
  //     const notificationSound = new Audio(soundUrl);
  //     notificationSound.play();
  //   });
  // }, []);


  // useEffect(() => {
  //   if (Payload) {
  //     Swal.fire({
  //       position: "center-center",
  //       iconHtml: '<i class="fa-regular fa-bell fa-beat fa-2xs"></i>',
  //       title: `<span style="font-size: 30px;">Table No : ${Payload?.table_no}</span>`,
  //       html: `<span style="font-size: 20px;">Branch Name : ${Payload?.branch_name}</span> <br/><br/> <span style="font-size: 20px;">New Order Alert</span>  `,
  //       showConfirmButton: true,
  //       icon: "info",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setPayload("");
  //       }
  //     });
  //   }
  // }, [Payload]);


  // useEffect(() => {
  //   if (PayloadPayment) {
  //     Swal.fire({
  //       position: "center-center",
  //       iconHtml: '<i class="fa-regular fa-bell fa-beat fa-2xs"></i>',
  //       title: `<span style="font-size: 30px;">Table No : ${PayloadPayment?.table_no}</span>`,
  //       html: `<span style="font-size: 20px;">Branch Name : ${PayloadPayment?.branch_name}</span> <br/><br/> <span style="font-size: 20px;">Table Closed.</span>  `,
  //       showConfirmButton: true,
  //       icon: "info",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setPayloadPayment("");
  //       }
  //     });
  //   }
  // }, [PayloadPayment]);

  let RGAICON = localStorage.getItem("RGAICON");
  useEffect(() => {
    if (RGAICON != null) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = `${RGAICON}`;
    }
  }, [RGAICON != null]);

  // console.log("GroupAdminRes_Name" ,GroupAdminRes_Name)

  const Enquiries_ = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features


  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {UserPermissionAllData?.restaurant_name ? UserPermissionAllData?.restaurant_name : "Kitchen Parameter"}
        </title>
      </Helmet>
      <div id="kt_header" className="header align-items-stretch">
        <ToastContainer />
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n1 me-2"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
            // id="kt_aside_mobile_toggle"
            >
              <React.Fragment>
                <MenuIcon
                  style={{
                    fontSize: 30,
                    color: "grey",
                    display: "inline-block",
                  }}
                  onClick={toggleDrawer("left", true)}
                />
                <SwipeableDrawer
                  anchor={"left"}
                  open={state["left"]}
                  onClose={toggleDrawer("left", false)}
                  onOpen={toggleDrawer("left", true)}
                >
                  {list("left")}
                </SwipeableDrawer>
              </React.Fragment>
            </div>
          </div>
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <div onClick={() => userPermissionType === "groupadmin" && history.push("/group_admin/dashboard")}
            >
              <img
                alt="Logo"
                src={UserPermissionAllData?.logo_url || "/assets/media/logos/Logo_Dark.png"}
                style={{
                  height: 40,
                  objectFit: "scale-down",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-stretch flex-shrink-0">

            {(userPermissionType === "groupadmin" || KPGPTPermissions?.access?.length > 0) && (
              <div className="d-flex align-items-center ms-1 ms-lg-3 kpgpt-icon">
                  <i onClick={()=>history.push("/group_admin/kpgpt")}
                    className="far fa-robot icon2_"
                    style={{ cursor: "pointer" }}
                  />
              </div>
            )}

            {(userPermissionType === "groupadmin" || EnquiryPermissions?.access?.length > 0) &&
                Enquiries_?.includes("enquiry_management") &&
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <div
                  className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mx-3"
                  id="kt_activities_toggle"
                >
                  <div onClick={() => {
                    sessionStorage.setItem("EnquiryTypeSession", "pending_enquiry");
                    history.push("/group_admin/enquiry")
                  }}>
                    <Badge badgeContent={Enquiry?.totalpenidngInquiryCount} color="primary">
                      <i className="bi bi-bell" style={{ fontSize: 29 }} />
                    </Badge>
                  </div>
                </div>
              </div>
            }
            <div
              className="d-flex align-items-center ms-1 ms-lg-3"
              id="kt_header_user_menu_toggle"
            >
              <div
                className="cursor-pointer symbol symbol-30px symbol-md-40px show menu-dropdown"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >

                <img
                  src={
                    user?.profile_pic || "/assets/media/avatars/blank.png"}
                  height="110"
                  width="130"
                  alt="profile_img"
                />
              </div>
              <div
                id="togall"
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px show"
                data-kt-menu="true"
                style={{
                  display: "none",
                  zIndex: 105,
                  position: "fixed",
                  inset: "0px 0px auto auto",
                  margin: 0,
                  transform: "translate(-30px, 65px)",
                }}
                data-popper-placement="bottom-end"
              >
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                      <img
                        alt="Logo"
                        src={
                          user?.profile_pic || "/assets/media/avatars/blank.png"}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="fw-bolder d-flex align-items-center fs-5">
                        {userPermissionType === "groupadmin" ? user?.owner_name : user?.name}
                      </div>
                      <div className="fw-bold text-muted text-hover-primary fs-7">
                        {user?.email}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator my-2" />
                {userPermissionType === "groupadmin" &&
                  <div>


                    <div className="menu-item px-5">
                      <Link
                        to="/group_admin/profile"
                        className={
                          url === "profile"
                            ? "menu-link active px-5"
                            : "menu-link px-5"
                        }
                      >
                        My Profile
                      </Link>
                    </div>
                    <div className="separator my-2" />
                  </div>}


                <div className="menu-item px-5">
                  <div onClick={handleSubmit} className="menu-link px-5">
                    Logout
                  </div>
                </div>
                <div className="separator my-2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
    </React.Fragment>
  );
}
