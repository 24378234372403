import { EMAILTEMPLATE } from "../../actions";



const emailTemplateListReducer = (state = [], action) => {
    switch (action.type) {
        case EMAILTEMPLATE.CLEAR_EMAILTEMPLATE_LIST:
            return [];
        case EMAILTEMPLATE.SET_EMAILTEMPLATE_LIST:
            return [...action?.emailTemplatelist];
        default:
            return state;
    }
}

export default emailTemplateListReducer