import React, { useState, useEffect, useMemo } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { useHistory, useLocation, Link } from "react-router-dom";
import useDrawer from "../../../../context/DrawerContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonCatagory from "./GoldenIndiaLausanne/Catagory/CommonCatagory";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from "moment";
import { HeaderStyle } from "../../Component/OrderManagement/TableCommonModule";
import { tableOption, tableOptionMenuProducts } from "../../../NewTableCommon/tableOption";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import renderStatusChip from "../../../NewTableCommon/renderStatusChip";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../NewTableCommon/tableFilter";

export default function GroupAdminCategoryByProduct() {
  let history = useHistory();
  let location = useLocation();
  var pathname = window.location.pathname;

  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  const { id, menuId, branchID } = useParams();



  function addNewProduct() {
    history.push("/group_admin/menu_management/product/add_new_product", {
      menuId: menuId,
      branchID: branchID,
      category_id: id,
    });
  }
  const [error, setError] = useState(null);
  //// ///////////////////// Prducts List /////////////////////////
  const [user, setuser] = useState([]);
  const windowHeight = useWindowHeight();

  useEffect(() => {
    groupAdminProductListById();
    // console.log("m", menuId);
    // console.log("b", branchID);
    // console.log("c", id);
  }, [location]);

  function groupAdminProductListById() {
    let data = JSON.stringify({
      menu_id: menuId,
      category_id: id,
    });

    api
      .post(`/groupadmin/product/${branchID}`, data, {
        headers: headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setuser(tableData);
        console.log("tableData", tableData);
      })
      .catch((err) => {
        if (err.response) {
          setError("No Product Found.");
        }
      });
  }

  //// ///////////////////// Prducts List /////////////////////////

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));


  // console.log("url" ,url)
  // console.log("columnsDataString" ,`${url}_columnData`)
  const [FilterData, SetFilterData] = useState("");

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };


  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'name_EN',
        header: 'Product Name',
        size: 300,
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: `/group_admin/menu_management/menu_category/menu_products/product_details_information/${row?.original?._id}/${id}/${menuId}/${branchID}`,
            }}
          >
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.name_EN}
              {/* {console.log("name_EN" ,row?.original?.name_EN)} */}
            </span>
          </Link>
        )
      },
      {
        accessorKey: 'base_price',
        header: 'Price',
        size: 270,
        Cell: ({ row }) => Number(row?.original?.base_price || 0).toFixed(2),
      },
      {
        accessorKey: 'printer_category_name',
        header: 'Printer',
        size: 270,
        Cell: ({ row }) => (row?.original?.printer_category_name || "---"),
      },
      {
        accessorKey: 'seq_no',
        header: 'Sequence No',
        size: 270,
        Cell: ({ row }) => row?.original?.seq_no,
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 270,
        Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
      }
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);


  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value;
    if (status === "Active") {
      return user.filter(menu => menu.is_active === true);
    } else if (status === "Inactive") {
      return user.filter(menu => menu.is_active === false);
    }
    return user;
  }, [user, FilterData?.Status?.value]);



  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionMenuProducts({
      groupAdminProductListById,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol
    }),
  });




  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Menu Management
                    </h1>

                    <hr />
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Category - Products
                        </h3>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) && (
                            <button
                              className="btn btn-primary ps-7"
                              onClick={addNewProduct}
                            >
                              New Product
                            </button>)}
                        </div>
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            className="btn btn-primary ps-7"
                            onClick={() =>
                              history.push(
                                "/group_admin/menu_management/menu_category",
                                {
                                  menuId: menuId,
                                  branchID: branchID,
                                }
                              )
                            }
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <CommonCatagory is_featured={false} />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          {/* {user != "" ? (
                            user.map((main, index) => (
                              <div
                                className="py-5"
                                style={{ clear: "both" }}
                                key={index}
                              >
                                <div className="card shadow-sm">
                                  <div className="card-body">
                                    <Link
                                      to={{
                                        pathname:
                                          `/group_admin/menu_management/menu_category/menu_products/product_details_information/${main._id}/${id}/${menuId}/${branchID}`,
                                        // state: {
                                        //   productID: main._id,
                                        //   menuId: menuId,
                                        //   branchID: branchID,
                                        //   category_id: id,
                                        // },
                                      }}
                                    >
                                      <div className="row col-md-12">
                                        <div className="col-md-2">
                                          {" "}
                                          <b style={{ color: "#000000" }}>
                                            {main.name_EN}
                                          </b>
                                        </div>
                                        <div className="col-md-2">
                                          <b
                                            style={{
                                              color: "#000000",
                                            }}
                                          >
                                            Price: {main.base_price}
                                          </b>
                                        </div>
                                        <div className="col-md-2">
                                          {" "}
                                          <b
                                            style={{
                                              color: "#000000",
                                            }}
                                          >
                                            Printer: {main.printer_category_name || ''}
                                          </b>
                                        </div>
                                        <div className="col-md-3">
                                          {" "}
                                          <b
                                            style={{
                                              color: "#000000",
                                            }}
                                          >
                                            Sequence no: {main?.seq_no}
                                          </b>
                                        </div>

                                        <div className="col-md-3">
                                          {main.is_active == true ? (
                                            <Link
                                              type="button"
                                              to={{
                                                pathname:
                                                  `/group_admin/menu_management/menu_category/menu_products/product_details_information/${main._id}/${id}/${menuId}/${branchID}`,
                                                // state: {
                                                //   productID: main._id,
                                                //   menuId: menuId,
                                                //   branchID: branchID,
                                                //   category_id: id,
                                                // },
                                              }}
                                              className="btn btn-success"
                                              style={{ float: "right" }}
                                            >
                                              Active
                                            </Link>
                                          ) : (
                                            <Link
                                              to={{
                                                pathname:
                                                  "/group_admin/menu_management/menu_category/menu_products/product_details_information",
                                                state: {
                                                  productID: main._id,
                                                  menuId: menuId,
                                                  branchID:
                                                    branchID,
                                                  category_id:
                                                    id,
                                                },
                                              }}
                                              type="button"
                                              className="btn btn-Inactive"
                                              style={{ float: "right" }}
                                            >
                                              Inactive
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                                <br />
                              </div>
                            ))
                          ) : (
                            <div className="card shadow-sm">
                              <div
                                className="card-body"
                                style={{ textAlign: "center" }}
                              >
                                <div className="col-md-12">
                                  <a>
                                    <b
                                      style={{
                                        color: "#000000",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {" "}
                                      {error}
                                    </b>
                                    <span></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )} */}
                          <div className="mt-5">
                            <MaterialReactTable
                              table={table}
                            /></div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>


            <ColumPopoverCommon
              anchorEl_={anchorEl_}
              handleClickColum_={handleClickColum_}
              onColumnDataChange={onColumnDataChange}
              handleCloseColum_={handleCloseColum_}
              url23={url}
            />

            <TableFilter
              open={isFilterPopoverOpen}
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilter={handleApplyFilters}
              url23={url}
            />

            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
