import React, { useEffect, useState, useMemo } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import useDrawer from "../../../../../../context/DrawerContext";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { useHistory, Link } from "react-router-dom";
import { UserPermissionAllData, userPermissionType } from "../../../../../Comman/UserPermission";
// import "./TableRes.css"
import EmailTemplateCommon from "../EmailTemplateCommon/EmailTemplateCommon";
import TableFilter from "../../../../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../../../../NewTableCommon/ColumPopoverCommon";
import useWindowHeight from "../../../../../NewTableCommon/useWindowsPosition";
import { branchfilterAndMapData } from "../../../../../Comman/BranchList";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { HeaderStyle } from "../../../OrderManagement/TableCommonModule";
import { tableOptionEmailTemplate, tableOptionPrinterList } from "../../../../../NewTableCommon/tableOption";
import { getBranchList, getEmailTemplateList } from "../../../../../../Store";
import Addnewdetails from "../../../../../Comman/AddNewDetails/Addnewdetails";

window.jquery = window.$ = $;



function EmailTemplateList({ getEmailTemplateList, emailTemplateList, getBranchList, BranchList_ }) {

      useEffect(() => {
            getEmailTemplateList()
            getBranchList();
      }, []);
      const userPermissionType_ = userPermissionType === "groupadmin"
      const filteredOptions = branchfilterAndMapData(BranchList_);
      const windowHeight = useWindowHeight();

      let history = useHistory();
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url = pathnamesplits[4];
      const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);
      const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const [BranchId, setBranchID] = useState(branchName || "");


      // useEffect(() => {
      //       emailTemplateList();
      // }, [location]);

      // function emailTemplateList() {
      //       api
      //             .get(`/groupadmin/table_reservation_settings/email_template`, {
      //                   headers,
      //             })
      //             .then((res) => {
      //                   // const tableData = res?.data?.data?.charges?._id.includes(id);
      //                   const tableData = res?.data?.data
      //                   console.log("tableData" .tableData)
      //             });
      // }

      const [FilterData, SetFilterData] = useState("");
      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };

      const VIEW = UserPermissionAllData?.role_details?.settings?.find(permission =>
            permission?.key === "email_template")?.access?.includes("view")

      const DELETE = UserPermissionAllData?.role_details?.settings?.find(permission =>
            permission?.key === "email_template")?.access?.includes("delete")

      const EDIT = UserPermissionAllData?.role_details?.settings?.find(permission =>
            permission?.key === "prinemail_templateter")?.access?.includes("edit")

      const filteredMenuList = useMemo(() => {
            const status = FilterData?.Status?.value;
            if (BranchId && BranchId.value !== "All" && status) {
                  return emailTemplateList.filter(menu => menu.branch_id === BranchId.value &&
                        menu.is_active === (status === "Active"));
            } else if (BranchId && BranchId.value !== "All") {
                  return emailTemplateList.filter(menu => menu.branch_id === BranchId.value);
            } else if (status) {
                  return emailTemplateList.filter(menu => menu.is_active === (status === "Active"));
            }
            return emailTemplateList;
      }, [emailTemplateList, BranchId, FilterData?.Status?.value]);



      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: '_id',
                        header: 'Sr.No',
                        size: 50,
                        Cell: ({ row }) => Number(row.index + 1),
                  },
                  {
                        accessorKey: 'template_name',
                        header: 'Template Name',
                        size: 700,
                        Cell: ({ row }) => (row?.original?.template_name),
                        // Cell: ({ row }) => {
                              // const hasViewPermission = userPermissionType_ || VIEW;
                              // return hasViewPermission ? (
                              //       <Link
                              //       // to={{
                              //       //       pathname: `/group_admin/settings/printer/printerDetails/${row?.original?._id}`,
                              //       // }}
                              //       >
                              //             <span style={HeaderStyle?.uniqueCodeHeader}>
                              //                   {row?.original?.template_name}
                              //             </span>
                              //       </Link>
                              // ) : (
                              //       <span style={HeaderStyle?.uniqueCodeHeader}>
                              //             {row?.original?.template_name}
                              //       </span>
                              // );
                        // },
                  },
                  {
                        accessorKey: 'subject_line',
                        header: 'Subject Line',
                        size: 400,
                        Cell: ({ row }) => (row?.original?.subject_line || "---"),
                  },
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const table = useMaterialReactTable({
            columns: Columns,
            data: filteredMenuList,
            ...tableOptionEmailTemplate({
                  getEmailTemplateList,
                  url,
                  handleClickColum_,
                  // handleSubmitActive,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol,
                  DELETE, VIEW, EDIT, history
            }),
      });

      // console.log("filteredMenuList" ,filteredMenuList)

      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div

                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Settings
                                                                              </h1>

                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >

                                                                              {/* <button
                                                                                    className="btn btn-primary"
                                                                                    type='button'
                                                                                    onClick={() => history.push("/group_admin/settings") }
                                                                              >
                                                                                   Go Back
                                                                              </button> */}

                                                                              {/* {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                    permission?.key === "email_template" &&
                                                                                    permission?.access?.includes("create")
                                                                              )) && (
                                                                                          <Addnewdetails url="/group_admin/settings/emailtemplate/addemailtemplate" Name="Add Template" />
                                                                                    )} */}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="row col-md-12 mb-4">
                                                                  <div
                                                                        className="col-md-9"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Email Template
                                                                        </h3>
                                                                  </div>

                                                                  <div
                                                                        className="col-md-3 text-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >

                                                                  </div>
                                                            </div>

                                                            <div className="rounded border p-5">
                                                                  <EmailTemplateCommon />
                                                                  <div className="mt-5">
                                                                        <MaterialReactTable
                                                                              table={table}
                                                                        /></div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url}
                  />

                  {/* <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url}
                  /> */}

            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      emailTemplateList: state?.emailTemplateList,
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getEmailTemplateList: () => dispatch(getEmailTemplateList()),
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateList);

