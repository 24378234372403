import { all, fork } from "redux-saga/effects";
import { watchBranchList } from "./branch/branchlist-saga";
import { watchBranchDetailsById } from "./branch/branchdetailsbyid-saga";
import { watchVoucherTemList } from "./voucher/voucherTemlist-saga";
import { watchVoucherPurchasedList } from "./voucher/voucherPurchasedList-saga";
import { watchserManagmentList } from "./usermanagment/usermanagment-saga";
import { watchCouponList } from "./coupon/couponlist-saga";
import { watchLanguageAllList, watchLanguageResList } from "./languages/languagesList-saga";
import { watchPrinterCatagoryList, watchPrinterList } from "./printer/printer-saga";
import { watchColorDetails, watchFooterList, watchHeaderList } from "./cms/cms-saga";
import { watchMenuList } from "./menu/menu-saga";
import { watchOrderDetails } from "./order/order-saga";
import { watchEmailMarketingList } from "./emailmarketing/emailmarketing-saga";
import { watchDashboardCount } from "./dashboard/dashboard-saga";
import { watchAddonList } from "./add-on-properties/add-on-properties-saga";
import { watchInventoryCategoryList, watchInventoryProductList } from "./inventory/inventorysaga";
import { watchStockList } from "./stock/stock-saga";
import { watchEmailTemplateList } from "./emailtemplate/emailtemplate-saga";


export function* rootSaga() {
  yield all([
    fork(watchBranchList),
    fork(watchBranchDetailsById),

    fork(watchVoucherTemList),
    fork(watchVoucherPurchasedList),

    fork(watchserManagmentList),
    fork(watchCouponList),

    fork(watchLanguageAllList),
    fork(watchLanguageResList),

    fork(watchPrinterList),
    fork(watchPrinterCatagoryList),

    fork(watchHeaderList),
    fork(watchFooterList),
    fork(watchColorDetails),

    fork(watchMenuList),

    fork(watchOrderDetails),

    fork(watchEmailMarketingList),
    fork(watchDashboardCount),
    fork(watchAddonList),
    fork(watchInventoryCategoryList),
    fork(watchInventoryProductList),

    fork(watchStockList),
    fork(watchEmailTemplateList)
  ]);
}
