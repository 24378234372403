import moment from "moment";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";

export const getUrlWithPrefix = (url) => {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const formattedURL = `https://${url}`;

      if (baseURL === "https://groupapi.kitchenparameter.com" ||
            baseURL === "https://groupapidemo.kitchenparameter.com" ||
            baseURL === "https://goldenindia-restaurantgroupapi.creatoro.in") {
            return formattedURL;
      }

      return `http://${url}`;
};


export const selectStyles = {
      control: (provided) => ({
            ...provided,
            borderRadius: "8px",
            height: "30px",
            marginTop: "5px",
            width: "250px",
            maxWidth: "500px"
      }),
      menu: (provided) => ({
            ...provided,
            zIndex: 9999
      })
};

export const selectStylesReport = {
      menuList: (provided) => ({
            ...provided,
            maxHeight: "300px", // Set the max height for the dropdown menu
      }),
};


const formatDate = (date) => moment(date).format('DD-MM-YYYY');
const formatDay = (date) => moment(date).format('dddd');


export const getDateRange = (Type) => {

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var urlDaynamic = pathnamesplits[2];
      const storedNavDate = sessionStorage.getItem(`${urlDaynamic}_Nav_Date`);
      const Date11 = storedNavDate ? JSON.parse(storedNavDate) : null

      const today = new Date();
      const startOfWeek = new Date(today);
      const endOfWeek = new Date(today);
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today.getFullYear(), 11, 31);

      switch (Type) {
            case "Today":
                  return `${formatDay(today)}, ${formatDate(today)}`
            case "Yesterday":
                  const yesterday = new Date(today);
                  yesterday.setDate(today.getDate() - 1);
                  return `${formatDay(yesterday)}, ${formatDate(yesterday)}`;
            case "Week":
                  const dayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
                  startOfWeek.setDate(today.getDate() - dayOfWeek);
                  endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));
                  return `${formatDate(startOfWeek)} - ${formatDate(today)}`;
            case "Month":
                  return `${formatDate(startOfMonth)} - ${formatDate(today)}`;
            case "Year":
                  return `${moment(today).format('YYYY')}`;
            case "LastMonth":
                  const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                  return `${formatDate(startOfLastMonth)} - ${formatDate(endOfLastMonth)}`;
            default:
                  return Date11 ? `${formatDate(Date11?.startDate)} to ${formatDate(Date11?.endDate)}` : null;
      }
};


// currencyOptions.js

export const currencyOptions = [
      { value: "CHF", label: "CHF - Swiss Franc" },
      { value: "INR", label: "INR - Indian Rupee" },
      { value: "EUR", label: "EUR - Euro" },
      { value: "USD", label: "USD - US Dollar" },
      { value: "AUD", label: "AUD - Australian Dollar" },
      { value: "NZD", label: "NZD - New Zealand Dollar" },
      { value: "GBP", label: "GBP - British Pound" },
      { value: "JPY", label: "JPY - Japanese Yen" },
      { value: "CAD", label: "CAD - Canadian Dollar" },
      { value: "CNY", label: "CNY - Chinese Yuan" },
      { value: "IDR", label: "IDR - Indonesian Rupiah" },
      { value: "IQD", label: "IQD - Iraqi Dinar" },
      { value: "KWD", label: "KWD - Kuwaiti Dinar" },
      { value: "OMR", label: "OMR - Omani Rial" },
      { value: "QAR", label: "QAR - Qatari Riyal" },
      { value: "SGD", label: "SGD - Singapore Dollar" },
      { value: "THB", label: "THB - Thai Baht" },
      { value: "AED", label: "AED - UAE Dirham" }
];

export function getCurrencyLabelDropDown(value) {
      const currency = currencyOptions.find(option => option.value === value);
      return currency ? currency.label : null;
}

export const groupAdminSetup = async (setGoogleMapKey, setIsScriptLoaded) => {
      try {
            const response = await api.get(`/groupadmin/settings/setup/getDetails`, { headers });
            const customDoc = response?.data?.data?.customDoc;
            const mapKey = customDoc?.map_key || "";

            // console.log("customDoc", customDoc);
            setGoogleMapKey(mapKey);

            // Check if the script is already present in the document
            if (!document.querySelector('script.googlemaps')) {
                  const script = document.createElement("script");
                  script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${mapKey}&libraries=places`;
                  script.async = true;
                  script.className = "googlemaps";
                  script.onload = () => setIsScriptLoaded(true);
                  document.body.appendChild(script);
            } else {
                  // If the script already exists, set the flag to true
                  setIsScriptLoaded(true);
            }
      } catch (err) {
            if (err.response) {
                  console.error("Error:", err?.response?.data?.error?.message);
            } else {
                  console.error("Error fetching Google Map details:", err);
            }
      }
};

export function printerList(branchID, setPrinterList_) {
      if (branchID) {
            api
                  .get(`/groupAdmin/branchPrinter/branch-wise-printer-category/${branchID}`, { headers })
                  .then((res) => {
                        const tableData = res?.data
                        //   GetSetProperty(arr);
                        // console.log( "branchID", tableData)
                        const options = res?.data.map((item) => ({
                              label: item.name,
                              value: item._id,
                        }));
                        if (options.length > 0) {
                              options.push(
                                    { label: 'No select', value: 'No select' });
                        }
                        setPrinterList_(options)
                  })
                  .catch((err) => {
                        if (err.response) {
                              console.log(err.response?.data?.error?.message);
                        }
                  });
      }
}

export function groupAdminBranchDetilasByID(branchID ,setlanguange) {
      api
            .get(`/groupadmin/branch/${branchID}`, { headers })
            .then((res) => {
                  const tableData = res.data.data.resData;
                  // console.log("tableData", tableData.languange);
                  setlanguange(tableData.languange);
            })
            .catch((err) => {
                  if (err.response) {
                        console.log(err?.response?.data);
                  }
            });
}