import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Select from "react-select";
import { connect } from "react-redux";
import headers from "../../../../CommonApi/headers";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import $ from "jquery";
import moment from "moment";
import useDrawer from "../../../../context/DrawerContext";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import { tableOptionEnquiryManagment } from "../../../NewTableCommon/tableOption";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import { Chip, } from '@mui/material';
import { CommentMultipleCheckmarkFilled  ,ChatBubblesQuestionFilled} from '@fluentui/react-icons';

window.jquery = window.$ = $;

function GroupAdminCompletedEnquiryListNew({ getBranchList, BranchList_ }) {
      /////////////////////////////// Branch List /////////////////////////
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
      const TypeSession = sessionStorage.getItem("EnquiryTypeSession");

      useEffect(() => {
            if (TypeSession === null) {
                  sessionStorage.setItem("EnquiryTypeSession", "completed_enquiry");
                  // sessionStorage.setItem(`${url23}_${Type}_branchName` , JSON.stringify(opt));
            }
      }, [TypeSession === null]);

      useEffect(() => {
            getBranchList();
      }, []);

      let history = useHistory();
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[2];
      const [data, setData] = useState([]);

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));
      const [Type, setType] = useState(sessionStorage.getItem("EnquiryTypeSession") || "completed_enquiry")

      // console.log("data", data)

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const [BranchId, setBranchID] = useState(branchName || "");

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      // const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;


      const dineInColumnsDataString = sessionStorage.getItem(`completed_enquiry_columnData`);
      const dineInColumnsData = dineInColumnsDataString ? JSON.parse(dineInColumnsDataString) : null;
      const hasFalseValue = dineInColumnsData && Object.values(dineInColumnsData).includes(false);

      
      const dineInColumnsDataString1 = sessionStorage.getItem(`pending_enquiry_columnData`);
      const dineInColumnsData1 = dineInColumnsDataString1 ? JSON.parse(dineInColumnsDataString1) : null;
      const hasFalseValue1 = dineInColumnsData1 && Object.values(dineInColumnsData1).includes(false);
      console.log("hasFalseValue" ,hasFalseValue)
      console.log("hasFalseValue1" ,hasFalseValue1)


      const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover
      const [columnFilters, setColumnFilters] = useState([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState([]);
      const [rowCount, setRowCount] = useState(0);
      const [pagination, setPagination] = useState("");
      const [isError, setIsError] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const prevSorting = useRef([]);

      // const storedState = sessionStorage.getItem(`${url23}_paginationStateCompletedEnquiry`);
      const storedState = sessionStorage.getItem(Type === "completed_enquiry" ? `${url23}_paginationState` : `${url23}_paginationState1`);


      const [total_completed_enquiry, setcompleted_enquiry] = useState("");
      const [total_pending_enquiry, setpending_enquiry] = useState("");

      useEffect(() => {
            if (storedState !== null) {
                  setPagination(JSON.parse(storedState));
            } else {
                  setPagination({ pageIndex: 0, pageSize: 10 });
            }
      }, [storedState]);

      const getCompletedEnquiry = async () => {
            setData([]);
            setIsLoading(true);
            setIsError(false)

            // let url = `/groupAdmin/inquiry/completed/list?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let url = `groupAdmin/inquiry/list/${Type === "completed_enquiry" ? "completed" : "pending"}/?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let formData = new FormData();
            if (BranchId?.value) {
                  url += `&branch_id=${BranchId?.value}`;
            }
            if (sorting?.length > 0 && sorting[0]?.id) {
                  switch (sorting[0]?.id) {
                        case "branchname":
                              url += `&sort_field=branch.branch_name_EN`;
                              break;
                        case "datetime":
                              url += `&sort_field=order_date`;
                              break;
                        // Add additional cases as needed
                        default:
                              url += `&sort_field=${sorting[0]?.id}`;
                              break;
                  }
            }
            if (sorting?.length > 0 && sorting[0]?.desc === true) {
                  url += `&sort_type=${'-1'}`;
            }
            if (sorting?.length > 0 && sorting[0]?.desc === false) {
                  url += `&sort_type=${'1'}`;
            }
            if (globalFilter) {
                  url += `&search=${globalFilter ?? ''}`;
            }
            // Add global filter to FormData if it exists
            try {
                  const res = await api.post(url, formData, { headers });
                  const tableData = res?.data?.data?.filteredInquiryData;
                  setData(tableData);
                  setRowCount(res?.data?.data?.totalInquiries);
                  if (Type === "completed_enquiry") {
                        setcompleted_enquiry(res?.data?.data?.totalInquiries)
                  } else {
                        setpending_enquiry(res?.data?.data?.totalInquiries)
                  }
            } catch (err) {
                  setIsError(true);
                  setData([]);
                  if (Type === "completed_enquiry") {
                        setcompleted_enquiry(0)
                  } else {
                        setpending_enquiry(0)
                  }
            } finally {
                  setIsLoading(false);
            }
      };

      const getCompletedEnquiry1 = async () => {
            setData([]);
            setIsLoading(true);
            setIsError(false)

            // let url = `/groupAdmin/inquiry/completed/list?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let url = `groupAdmin/inquiry/list/${Type === "completed_enquiry" ? "pending" : "completed"}/?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let formData = new FormData();
            if (BranchId?.value) {
                  url += `&branch_id=${BranchId?.value}`;
            }
            if (sorting?.length > 0 && sorting[0]?.id) {
                  switch (sorting[0]?.id) {
                        case "branchname":
                              url += `&sort_field=branch.branch_name_EN`;
                              break;
                        case "datetime":
                              url += `&sort_field=order_date`;
                              break;
                        // Add additional cases as needed
                        default:
                              url += `&sort_field=${sorting[0]?.id}`;
                              break;
                  }
            }
            if (sorting?.length > 0 && sorting[0]?.desc === true) {
                  url += `&sort_type=${'-1'}`;
            }
            if (sorting?.length > 0 && sorting[0]?.desc === false) {
                  url += `&sort_type=${'1'}`;
            }
            if (globalFilter) {
                  url += `&search=${globalFilter ?? ''}`;
            }
            // Add global filter to FormData if it exists
            try {
                  const res = await api.post(url, formData, { headers });
                  // const tableData = res?.data?.data?.filteredInquiryData;
                  // setData(tableData);
                  // setRowCount(res?.data?.data?.totalInquiries);
                  if (Type === "completed_enquiry") {
                        setpending_enquiry(res?.data?.data?.totalInquiries)
                  } else {
                        setcompleted_enquiry(res?.data?.data?.totalInquiries)
                  }
            } catch (err) {
                  setIsError(true);
                  // setData([]);
            } finally {
                  setIsLoading(false);
            }
      };

      function arraysAreEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                  if (arr1[i] !== arr2[i]) return false;
            }
            return true;
      }

      const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
      prevSorting.current = sorting;


      useEffect(() => {
            if (pagination.pageIndex, pagination.pageSize) {
                  getCompletedEnquiry();
                  getCompletedEnquiry1();
            }
      }, [pagination.pageIndex, pagination.pageSize, sortingChanged,
      filterData?.Status?.value, globalFilter ?? '', BranchId?.value, Type]);


      const prevPaginationRef = useRef();

      useEffect(() => {
            if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
                  sessionStorage.setItem(Type === "completed_enquiry" ? `${url23}_paginationState` : `${url23}_paginationState1`, JSON.stringify(pagination));
            }
            prevPaginationRef.current = pagination;
      }, [pagination, rowCount]);

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };

      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: '_id',
                        header: 'Sr.No',
                        size: 100,
                        Cell: ({ row }) => (row?.index + 1),
                  },
                  {
                        accessorKey: 'branch?.branch_name_EN',
                        header: 'Branch',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN),
                  },
                  {
                        accessorKey: 'name',
                        header: 'Name',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.name || '---'),
                  },
                  {
                        accessorKey: 'phone_number',
                        header: 'Phone Number',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.phone_number || '---'),
                  },
                  {
                        accessorKey: 'email',
                        header: 'Email',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.email || '---'),
                  },
                  {
                        accessorKey: 'updated_at',
                        header: 'Enquiry Date',
                        size: 200,
                        Cell: ({ row }) => moment(row?.original?.updated_at).format("DD/MM/YYYY"),
                  }
            ];
            return dineInColumnsData === null ? columns : columns.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const ColumnsPending = useMemo(() => {
            const columns = [
                  {
                        accessorKey: '_id',
                        header: 'Sr.No',
                        size: 100,
                        Cell: ({ row }) => (row?.index + 1),
                  },
                  {
                        accessorKey: 'branch?.branch_name_EN',
                        header: 'Branch',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN),
                  },
                  {
                        accessorKey: 'name',
                        header: 'Name',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.name || '---'),
                  },
                  {
                        accessorKey: 'phone_number',
                        header: 'Phone Number',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.phone_number || '---'),
                  },
                  {
                        accessorKey: 'email',
                        header: 'Email',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.email || '---'),
                  },
                  {
                        accessorKey: 'updated_at',
                        header: 'Enquiry Date',
                        size: 200,
                        Cell: ({ row }) => moment(row?.original?.updated_at).format("DD/MM/YYYY"),
                  }
            ];
            return dineInColumnsData1 === null ? columns : columns.filter(column => dineInColumnsData1[column?.header] === true);
      }, [updateColumns]);


      const handleFilterColor = () => {
            switch (Type) {
                  case "completed_enquiry":
                        return hasFalseValue ? "#e0e0e045" : "";
                  case "pending_enquiry":
                        return hasFalseValue1 ? "#e0e0e045" : "";
                  default:
                        return "";
            }
      };

      // console.log("anchorEl_", anchorEl_)
      const commonOptions = {
            columnFilters,
            globalFilter,
            sorting, isLoading,
            setColumnFilters,
            setGlobalFilter,
            setPagination,
            setSorting,
            rowCount,
            pagination,
            history,
            // handleFilterClick,
            handleClickColum_,
            windowHeight,
            // getMenuList,
            Type,
            // handleSubmitActive,
            filterData,
            DisplayCol,
            getCompletedEnquiry,
            handleFilterColor,
      };

      const table = useMaterialReactTable({
            columns: Columns,
            data: data,
            ...tableOptionEnquiryManagment(commonOptions),
      });

      const table1 = useMaterialReactTable({
            columns: ColumnsPending,
            data: data,
            ...tableOptionEnquiryManagment(commonOptions),
      });

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Enquiries
                                                                              </h1>
                                                                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    value={BranchId}
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    onChange={(opt) => {
                                                                                          if (opt.label === "All Locations") {
                                                                                                getCompletedEnquiry();
                                                                                                setBranchID("");
                                                                                                sessionStorage.removeItem(`${url23}_branchName`)
                                                                                          } else {
                                                                                                setBranchID(opt);
                                                                                                sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                                                                                          }

                                                                                    }}
                                                                                    styles={selectStyles}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              {/* <button
                                                                                    onClick={() => history.goBack()}
                                                                                    className="btn btn-primary"
                                                                                    data-kt-menu-trigger="click"
                                                                                    data-kt-menu-attach="parent"
                                                                                    data-kt-menu-placement="bottom-end"
                                                                              >
                                                                                    Go Back
                                                                              </button> */}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="mt-5">

                                                                  <ul className="nav nav-tabs nav-line-tabs mt-5 fs-6">
                                                                        {[
                                                                              {
                                                                                    label: "Completed", value: "completed_enquiry",
                                                                                    svg: <CommentMultipleCheckmarkFilled  style={{fontSize :22}}/>
                                                                                          // <svg
                                                                                          //       xmlns="http://www.w3.org/2000/svg"
                                                                                          //       width={22}
                                                                                          //       height={22}
                                                                                          //       viewBox="0 0 20 20"
                                                                                          //       fill={Type === "completed_enquiry" ? "#F25042" : "#667085"}
                                                                                          // >
                                                                                          //       <g clipPath="url(#clip0_2549_8404)">
                                                                                          //             <path
                                                                                          //                   fillRule="evenodd"
                                                                                          //                   clipRule="evenodd"
                                                                                          //                   d="M6.79427 16.4212L6.64193 12.5853L8.36719 12.5163C9.10417 12.6178 9.85026 12.9863 10.6003 13.4173L11.9401 13.3639C12.5482 13.377 12.8906 13.9785 12.3164 14.4056C11.8607 14.7689 11.2396 14.7741 10.6016 14.7415C10.1628 14.7376 10.1667 15.3275 10.6237 15.3105C10.7826 15.3171 10.9544 15.2728 11.1042 15.2663C11.8971 15.2337 12.5443 15.0566 12.9193 14.4134L13.1016 13.9368L15.056 12.8691C16.0404 12.5007 16.7031 13.429 15.9974 14.1842C14.5951 15.2923 13.237 16.3027 11.75 17.0605C10.6771 17.7728 9.57552 17.7949 8.45052 17.1921L6.79427 16.4212ZM5.8724 10.5762H17.8385C17.9271 10.5762 18 10.6504 18 10.7376V11.4421C18 11.5293 17.9271 11.6035 17.8385 11.6035H5.8724C5.78385 11.6035 5.71094 11.5306 5.71094 11.4421V10.7376C5.71094 10.6491 5.78385 10.5762 5.8724 10.5762ZM12.3203 3.83919C15.5964 4.10091 18.3555 6.85482 17.9479 10.097H5.76432C5.36198 6.83398 8.13151 4.07617 11.431 3.83529V3.30664H10.9102C10.819 3.30664 10.7435 3.23112 10.7435 3.13997V2.54362C10.7435 2.45247 10.8177 2.37695 10.9102 2.37695H12.8268C12.918 2.37695 12.9935 2.45247 12.9935 2.54362V3.13997C12.9935 3.23112 12.918 3.30664 12.8268 3.30664H12.3203V3.83919ZM2 12.4538L6.10807 12.2376L6.29037 16.8275L2.18229 17.0423L2 12.4538Z"
                                                                                          //                   fill={Type === "completed_enquiry" ? "#F25042" : "#667085"}
                                                                                          //             />
                                                                                          //       </g>
                                                                                          //       <defs>
                                                                                          //             <clipPath id="clip0_2549_8404">
                                                                                          //                   <rect width={16} height={16} fill="white" transform="translate(2 2)" />
                                                                                          //             </clipPath>
                                                                                          //       </defs>
                                                                                          // </svg>
                                                                                          ,

                                                                                    count: total_completed_enquiry

                                                                              },
                                                                              {
                                                                                    label: "Pending", value: "pending_enquiry",
                                                                                    svg: <ChatBubblesQuestionFilled style={{fontSize :22}}/>,
                                                                                    count: total_pending_enquiry
                                                                              }
                                                                        ].map((tab, index) => (
                                                                              <li className={Type === tab?.value ? "nav-link active  nav-link_table_active nav-item" : "nav-link_table  nav-item"} key={index}>
                                                                                    <div className='nav__' onClick={() => {
                                                                                          setType(tab?.value)
                                                                                          setGlobalFilter("");
                                                                                          sessionStorage.setItem("EnquiryTypeSession", tab?.value);
                                                                                          setSorting([]);
                                                                                    }} >
                                                                                          <span className='mx-2'>{tab.svg}</span> <span className='navlinkName'> {tab?.label}</span>
                                                                                          <Chip className={Type === tab?.value ? 'chips_active mx-2' : "chips mx-2"} label={tab?.count || 0} variant="outlined" />
                                                                                    </div>
                                                                              </li>
                                                                        ))}
                                                                  </ul>
                                                                  <MaterialReactTable
                                                                        table={Type === "completed_enquiry" ? table : table1}
                                                                  />
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <ColumPopoverCommon
                              anchorEl_={anchorEl_}
                              handleClickColum_={handleClickColum_}
                              onColumnDataChange={onColumnDataChange}
                              handleCloseColum_={handleCloseColum_}
                              url23={Type}
                        />

                        {/* <ColumPopover
                              anchorEl={anchorEl}
                              handleCloseColum={handleCloseColum}
                              onColumnDataChange={onColumnDataChange}
                              Type={Type}
                              BranchId={BranchId}
                              currencyLabel={currencyLabel}
                        /> */}

                        <GroupAdminUperSide />
                  </div>
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminCompletedEnquiryListNew);
