import Swal from "sweetalert2";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { showSuccessNotification } from "../Swal/Swal";
import { showToastCommon } from "../Toastify/Toastify";

export function deleteCommonFunction(url, successMessage, callback  ,item ,id) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      api
        .delete(url, { headers: headers , data :item })
        .then(() => {
            showSuccessNotification(successMessage)
            if (callback) {
                  callback();
            }
        })
        .catch((err) => {
          if (err.response) {
            showToastCommon(err?.response?.data?.error?.message);
          }
        });
    }
  });
}
