import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { EMAILTEMPLATE, setEmailTemplateList,   } from "../../actions";

function* callEmailTemplateList() { 
  try {
      const response = yield call(api.get, "/groupadmin/table_reservation_settings/email_template"  ,{ headers });
      const  tableData = response?.data?.data;
      // console.log("tableData" ,tableData);
      yield put(setEmailTemplateList(tableData?.length > 0 ? tableData : []));
    } catch (error) {
      if (error.response) {
        yield put({ type: EMAILTEMPLATE.CLEAR_EMAILTEMPLATE_LIST });
      }
    }
}


export function* watchEmailTemplateList() {
  yield takeEvery(EMAILTEMPLATE.GET_EMAILTEMPLATE_LIST, callEmailTemplateList);
}

