import React, { useEffect, useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "./UserManagmentCommon";
import { Chip } from "@mui/material";
import { getUserManagmentList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import useWindowHeight from "../../../../NewTableCommon/useWindowsPosition";
import { tableOptionBranchList } from "../../../../NewTableCommon/tableOption";
import { HeaderStyle } from "../../OrderManagement/TableCommonModule";
import ColumPopoverCommon from "../../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../../NewTableCommon/tableFilter";


function GroupAdminUserManagment({ getUserManagmentList, UserManagmentList }) {

  useEffect(() => {
    getUserManagmentList();
  }, []);

  let history = useHistory();

  function groupAdminStatusUpdate(value, ID) {
    let item = {
      is_active: value
    };
    // console.log("item" ,item);

    api
      .patch(`/groupadmin/user/${ID}`, item, { headers })
      .then((res) => {
        getUserManagmentList();
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: `User has been ${value == true ? "Active" : "Inactive"} successfully`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  const [maxBodyHeight, setMaxBodyHeight] = useState(calculateMaxBodyHeight());
  // Calculate the max body height based on the window size
  function calculateMaxBodyHeight() {
    const windowHeight = window.innerHeight;
    const tableOffset = 100; // Adjust this offset as needed
    return `${windowHeight - tableOffset}px`;
  }

  useEffect(() => {
    const handleResize = () => {
      setMaxBodyHeight(calculateMaxBodyHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value;
    if (status) {
      return UserManagmentList.filter(menu => menu.is_active === (status === "Active"));
    }
    return UserManagmentList;
  }, [UserManagmentList, FilterData?.Status?.value]);

  // console.log("UserManagmentList", UserManagmentList)

  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'name',
        header: 'User Name',
        size: 230,
        Cell: ({ row }) =>
          <Link
            to={{
              pathname: `/group_admin/settings/user_management/view_user/${row?.original?._id}`,
            }}
          >
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.name}
            </span>
          </Link>,
      },

      {
        accessorKey: 'role_name',
        header: 'Assigned Role',
        size: 230,
        Cell: ({ row }) => (row?.original?.role_name || "---"),
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        size: 200,
        Cell: ({ row }) => (row?.original?.phone_number || "---"),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 230,
        Cell: ({ row }) => (row?.original?.email)
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 200,
        // Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
        Cell: ({ row }) => {
          const { is_active, _id } = row?.original || {};
          const isActive = is_active === true;
          return (
            <div>
              <button
                onClick={() => groupAdminStatusUpdate(!isActive, _id)}
                // onClick={() => groupAdminVoucherStatusUpdate(!isActive, _id)}
                type="button"
                className={`btn ${isActive ? 'btn-success' : 'btn-Inactive'}`}
              >
                {isActive ? 'Active' : 'Inactive'}
              </button>
            </div>
          );
        },
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  // const EDIT = VouchersPermissions?.access?.includes("edit");
  // const DELETE = VouchersPermissions?.access?.includes("delete")
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionBranchList({
      getUserManagmentList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      // DELETE,
      // VIEW,
      // EDIT,
      history
    }),
  });


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div

                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          User Management - Users List  <Chip
                            className="mx-2"
                            style={{
                              color: "#DF3223", background: "#FEF3F2",
                              border: "1px solid #DF3223",
                              fontSize: 12, fontWeight: 500, letterSpacing: "0.3px"
                            }} label={`${UserManagmentList.length} Records`} />

                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push(
                              "/group_admin/settings/user_management/add_user"
                            )
                          }
                        >
                          <AddIcon className="me-2" /> Add User
                        </button>
                      </div>
                    </div>

                    <div className="rounded border p-5 mt-3">
                      <UserManagmentCommon />

                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        />

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>

            <ColumPopoverCommon
              anchorEl_={anchorEl_}
              handleClickColum_={handleClickColum_}
              onColumnDataChange={onColumnDataChange}
              handleCloseColum_={handleCloseColum_}
              url23={url}
            />

            <TableFilter
              open={isFilterPopoverOpen}
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilter={handleApplyFilters}
              url23={url}
            />
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  UserManagmentList: state?.UserManagmentList
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserManagmentList: () => dispatch(getUserManagmentList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminUserManagment);

