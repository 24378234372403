import React, { useEffect, useMemo, useState } from 'react'
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link, useHistory, useParams } from "react-router-dom";
// import QRCode from "qrcode.react";
import $ from "jquery";
import { useStyles } from '../../../Comman/TableuseStyles';
import { QRCode } from "react-qrcode-logo";
import CallMadeIcon from '@mui/icons-material/CallMade';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getBranchDetaisById } from '../../../../Store';
import { connect } from "react-redux";
import BranchCommon from '../../Page/BranchCommon';
import useDrawer from '../../../../context/DrawerContext';
import { deleteCommonFunction } from '../../../Comman/DeleteCommonFun/DeleteCommonFunction';
import { BranchesPermissions, userPermissionType } from '../../../Comman/UserPermission';
import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ColumPopoverCommon from '../../../NewTableCommon/ColumPopoverCommon';
import TableFilter from '../../../NewTableCommon/tableFilter';
import useWindowHeight from '../../../NewTableCommon/useWindowsPosition';
import { tableOptionBranchList } from '../../../NewTableCommon/tableOption';


function TableManagementInBranchView({ getBranchDetaisById, branchDetaislByID }) {



  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [TableNumber, setTableNumber] = useState("");
  const [presentNumber, setpresentNumber] = useState("");
  const [user, setuser] = useState("")
  const [Table, setTable] = useState([]);

  useEffect(() => {
    if (id) {
      getBranchDetaisById(id);
    }
  }, [id]);

  useEffect(() => {
    if (branchDetaislByID) {
      setuser(branchDetaislByID);
      setTable(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData : []);
      setpresentNumber(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData?.length : 0)
    }
  }, [branchDetaislByID])


  const downloadCode = (Table) => {
    const canvas = document.getElementById(Table);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `Table no ${Table}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };


  function phils_image_price(input) {
    const canvas = document.getElementById('roundedCanvas');
    const ctx = canvas.getContext('2d');

    // Draw a double rounded shape with a black border
    function drawDoubleRoundedShape() {
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const outerRadius = canvas.width / 3;
      const innerRadius = outerRadius - 10; // You can adjust the difference based on the desired thickness of the border

      ctx.beginPath();

      // Draw the outer arc
      ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI);
      ctx.fillStyle = '#fff'; // Change the color if desired
      ctx.fill();

      // Draw the inner arc
      ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
      ctx.fillStyle = '#fff'; // Change the color if desired
      ctx.fill();

      ctx.lineWidth = 4; // You can adjust the line width based on the desired thickness of the border
      ctx.strokeStyle = 'black';
      ctx.stroke();

      ctx.closePath();
    }

    // Call the function to draw the double rounded shape
    drawDoubleRoundedShape();

    // You can also add the drawCenteredText function if needed
    function drawCenteredText() {
      const text = input;
      ctx.font = '35px Arial';
      ctx.fillStyle = '#000'; // Change the color if desired
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    }

    // Call the function to draw the centered text if needed
    drawCenteredText();

    // Convert the canvas to a data URL if needed
    return canvas.toDataURL();
  }


  const [errorForgate, setErrorForgate] = useState(null);
  const errorDivForgate = errorForgate ? (
    <div className="text-center alert alert-danger">{errorForgate}</div>
  ) : (
    ""
  );

  const handleClose = () => {
    setTableNumber("");
    setErrorForgate(null)
  };

  function handleSubmitForgetPassword(event) {
    event.preventDefault();
    setErrorForgate(null)

    // history.push("/reset_password/12344");
    if (TableNumber == 0) {
      setErrorForgate("Table number value is accept greater than 0 ")
      return
    }
    else {
      history.push(`/group_admin/branch_management/add_table/${id}/${TableNumber}/${presentNumber}`);
      window.location.reload(true)
    }

  }

  const downloadQRCodeAll = async () => {
    const qrContainers = document.querySelectorAll('.qr-code-container');
    const zip = new JSZip();

    for (let index = 0; index < qrContainers.length; index++) {
      const container = qrContainers[index];
      const canvas = container.querySelector('canvas');
      const tableNumber = container.getAttribute('data-table-number'); // Retrieve table_number from data attribute

      if (canvas && tableNumber) {
        const imageBlob = await convertCanvasToBlob(canvas);
        zip.file(`Table_no_${tableNumber}.png`, imageBlob); // Include table_number in the file name
      }
    }

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'QR_Codes.zip');
    });
  };

  async function convertCanvasToBlob(canvas) {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png');
    });
  }
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  const userPermissionType_ = userPermissionType === "groupadmin"

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];
  // console.log("url", url)

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  // const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));
  // const [BranchId, setBranchID] = useState(branchName || "");

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;


  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  // console.log("BranchList_", BranchList_)

  const EDIT = BranchesPermissions?.access?.includes("edit");
  const DELETE = BranchesPermissions?.access?.includes("delete");
  const VIEW = BranchesPermissions?.access?.includes("view");

  const ColumnsManager = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'table_number',
        header: 'Table No',
        size: 270,
        Cell: ({ row }) => (row?.original?.table_number),
      },
      {
        accessorKey: 'phone_number',
        header: 'QR Link',
        // size: 270,
        Cell: ({ row }) => (<div className="text-start">
          <a className="settingss btn btn-primary btn-sm" style={{ fontSize: "inherit" }}
            onClick={() => window.open(`http://${row?.original?.qr_code}`, '_blank', 'noopener,noreferrer')}
          >
            {" "}
            <CallMadeIcon />
          </a>
        </div>)
      },
      {
        accessorKey: 'qr_code',
        header: 'QR Code',
        size: 150,
        Cell: ({ row }) => <div>
          <div style={{ display: "none" }}>
            <QRCode
              id={row?.original?.table_number}
              value={row?.original?.qr_code}
              size={260}
              logoImage={phils_image_price(row?.original?.table_number)}
              logoHeight={100}
              logoWidth={100}
              logoOpacity={1}
              enableCORS={true}
              qrStyle="dots"
              eyeRadius={20}
            >

            </QRCode>
          </div>
          <div className="text-start">
            <a className="settingss btn btn-success btn-sm" style={{ fontSize: "inherit" }} onClick={() => downloadCode(row?.original?.table_number)}>
              {" "}
              Download QR{" "}
            </a>
          </div>
        </div>,
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: ColumnsManager,
    data: Table,
    ...tableOptionBranchList({
      getBranchDetaisById,
      url,id,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history
    }),
  });


  return (

    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={maingetViewDetails.join(" ")}>
                
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                         <h1
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Branches  
                          {/* {user?.resData?.branch_name_EN && ` - ${user?.resData?.branch_name_EN}`} */}
                        </h1>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          {Table.length > 0 &&
                            <a
                              className="settingss btn btn-primary mx-2"
                              onClick={downloadQRCodeAll}
                              color="primary"
                              variant="contained"
                              style={{ textTransform: 'none' }}
                              size="large"
                            >

                              <DownloadIcon />  Download QR-Codes
                            </a>}

                          {(userPermissionType === "groupadmin" || BranchesPermissions?.access?.includes("create")) && (
                            <Link
                              onClick={handleClose}
                              data-bs-toggle="modal"
                              to="#kt_modal_1"
                              className="btn btn-primary"
                            >
                              Create table/s
                            </Link>
                          )}

                        </div>
                      </div>
                    </div>

                    <hr style={{ opacity: 0.07, marginTop:"-13px" }} />
                    <div className="d-flex flex-stack mb-3">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        {user?.resData?.branch_name_EN ?
                          <h3
                            className="anchor fw-bolder mb-3"
                            id="custom-form-control"
                          >
                            Branch - {user?.resData?.branch_name_EN}
                          </h3> : ""}
                      </div>
                    </div>
                    <div className={"rounded border p-5"}>
                    <BranchCommon />

                    <div className="mt-5">
                      <MaterialReactTable
                        table={table}
                      />
                    </div>
                    
                    {Table?.length > 0 &&
                      Table?.map((rowData, index) => (
                        <div key={index} data-table-number={rowData?.table_number}
                          className="qr-code-container" style={{ display: "none" }}>
                          <QRCode
                            id={rowData?.table_number}
                            value={rowData?.qr_code}
                            size={260}
                            logoImage={phils_image_price(rowData?.table_number)}
                            logoHeight={100}
                            logoWidth={100}
                            logoOpacity={1}
                            enableCORS={true}
                            qrStyle="dots"
                            eyeRadius={20}
                          >
                          </QRCode>
                        </div>
                      ))}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        />

        {/* <TableFilter
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          url23={url}
        /> */}

        <GroupAdminUperSide />
        <canvas
          id="roundedCanvas"
          width={200}
          height={200}
          style={{
            border: "1px solid black",
            borderRadius: "50%",
            display: "none"
          }}
        />
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmitForgetPassword}
              >
                <h2 className="mb-4">Create tables</h2>

                <hr />

                <div className="mx-4">
                  <div className="my-5">
                    <label className="required form-label">How many tables do you want to create ?</label>
                    <input
                      type="number"
                      className="filterme form-control form-control-lg form-control-solid border-dark"
                      placeholder="Number of tables"
                      required
                      value={TableNumber}
                      onChange={(e) => setTableNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12"> {errorDivForgate} </div>
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Create tables</span>
                  </button>
                  <button
                    type="button"
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}



const mapStateToProps = (state) => ({
  branchDetaislByID: state?.branch?.branchDetaislByID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetaisById: (id) => dispatch(getBranchDetaisById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManagementInBranchView);
