import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import headers from "../../../../../CommonApi/headers";
import api from "../../../../../CommonApi/axios";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";

const AddEditCategoryModal = ({ show, onHide, isEdit, categoryId, getPrinterCategoryList, existingCategoryName }) => {
      const [CategoryName, setCategoryName] = useState("");
      const [error, setError] = useState(null);

      // Set the old category name when the modal opens in edit mode
      useEffect(() => {
            if (isEdit && existingCategoryName) {
                  setCategoryName(existingCategoryName);
            } else {
                  setCategoryName(""); // Reset the field when adding a new category
            }
      }, [isEdit, existingCategoryName]);


      const handleSubmit = (event) => {
            event.preventDefault();
            setError(null);

            if (CategoryName.trim() === "") {
                  setError("Please enter category name");
                  return;
            }

            const item = { name: CategoryName };
            const url = isEdit
                  ? `/groupAdmin/printer-category/edit-printer`
                  : `/groupadmin/printer-category`;
            const method = isEdit ? api.patch : api.post;
            const data = isEdit ? { ...item, categoryId } : item;

            method(url, data, { headers })
                  .then((res) => {
                        showSuccessNotification(isEdit
                              ? "Printer category name edited successfully."
                              : "Printer category added successfully.")
                        setCategoryName("");
                        getPrinterCategoryList();
                        onHide(); // Close the modal
                  })
                  .catch((err) => {
                        if (err.response?.data?.error) {
                              setError(err.response?.data?.error);
                        }
                  });
      };

      return (
            <Modal show={show} onHide={() => {
                  setError(null);
                  onHide()
            }} centered>
                  <Modal.Body>
                        <h1> {isEdit ? "Edit Printer Category" : "Add New Category"}</h1>
                        <p className="my-1 mb-5" style={{ fontSize: 15 }}>Please enter appropriate printer category.</p>
                        <Form onSubmit={handleSubmit}>
                              <Form.Group className="mb-5">
                                    <Form.Label className="required">Category Name</Form.Label>
                                    <Form.Control
                                          type="text"
                                          placeholder="Enter Category Name"
                                          value={CategoryName}
                                          // onChange={(e) => setCategoryName(e.target.value)}
                                          onChange={(e) => {
                                                const inputValue = e.target?.value;
                                                const trimmedValue = inputValue.trim();
                                                if (trimmedValue === '' && inputValue.length > 0) {
                                                      return;
                                                }
                                                setCategoryName(inputValue);
                                          }}
                                    />
                              </Form.Group>
                              <ErrorMessage error={error} />

                              <div className="d-flex justify-content-center">
                                    <Button variant="primary" type="submit">
                                          Save
                                    </Button>
                                    <Button className="mx-2" variant="danger"
                                          onClick={() => {
                                                setError(null);
                                                onHide()
                                          }}>
                                          Cancel
                                    </Button>
                              </div>
                        </Form>
                  </Modal.Body>
            </Modal>
      );
};

export default AddEditCategoryModal;
