import React, { useEffect, useMemo, useRef, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { connect } from "react-redux";
// import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../Comman/BranchList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import useDrawer from "../../../../context/DrawerContext";
import { HeaderStyle } from "../OrderManagement/TableCommonModule";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../Comman/BranchList";
import { tableOptionTableReservationList } from "../../../NewTableCommon/tableOption";
import { getBranchList } from "../../../../Store";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";
import { renderStatusChipTableReservations } from "../../../NewTableCommon/rendderStatucChipDaynamic";
import FilterPopover from "../OrderManagement/FilterPopover";
import moment from "moment";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import TableCancel from "./TableCancel";
// import "./menuManagmnet.css"

function TableReservationsList({ getBranchList, BranchList_ }) {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url23 = pathnamesplits[2];

  const windowHeight = useWindowHeight();
  const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

  const userPermissionType_ = userPermissionType === "groupadmin"

  // console.log("url23", url23)

  const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);

  const filteredOptions = branchfilterAndMapData(BranchList_);
  const history = useHistory();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState("");
  const [TableReservationList, setTableReservationList] = useState([])
  const prevSorting = useRef([]);
  const [BranchId, setBranchID] = useState(branchName || "");
  const [CopyBranchId, setCopyBranchID] = useState("");
  const [menu_name, setmenu_name] = useState("");
  const [menuId, setmenuId] = useState("");
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover


  const [FilterData, SetFilterData] = useState("");

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };


  const [showTableCancel, setShowTableCancel] = useState(false);

  const handleCloseTableCancel = () => {
    setShowTableCancel(false);
  };

  const handleShowTableCancel = () => {
    setShowTableCancel(true);
  };


  const storedState = sessionStorage.getItem(`${url23}_paginationState`);

  useEffect(() => {
    if (storedState !== null) {
      setPagination(JSON.parse(storedState));
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  }, [storedState]);


  const getTableReservationList = async () => {
    setTableReservationList([]);
    setIsLoading(true);
    setIsError(false)
    let url = `/groupadmin/table_reservation/list?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
    let data = JSON.parse(sessionStorage.getItem(`${url23}_dineinfilterData`));
    if (BranchId?.value) {
      url += `&branch_id=${BranchId?.value}`;
    }
    if (globalFilter) {
      url += `&search=${globalFilter ?? ''}`;
    }

    // if (FilterData) {
    //   url += `&category_id=${FilterData?.Status?.value ?? ''}`;
    // }
    if (FilterData?.startDate) {
      url += `&start_date=${FilterData?.startDate}`;
    }
    if (FilterData?.endDate) {
      const result2 = moment(FilterData?.endDate).add(1, "days").toDate();
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }
    if (FilterData?.orderStatus?.value) {
      url += `&status=${FilterData?.orderStatus?.value}`;
    }

    if (sorting?.length > 0 && sorting[0]?.id) {
      switch (sorting[0]?.id) {
        case "branch":
          url += `&sort_field=branch.branch_name_EN`;
          break;
        case "datetime":
          url += `&sort_field=order_date`;
          break;
        // Add additional cases as needed
        default:
          url += `&sort_field=${sorting[0]?.id}`;
          break;
      }
    }

    if (sorting?.length > 0 && sorting[0]?.desc === true) {
      url += `&sort_type=${'-1'}`;
    }
    if (sorting?.length > 0 && sorting[0]?.desc === false) {
      url += `&sort_type=${'1'}`;
    }
    try {
      const res = await api.get(url, { headers });
      setRowCount(res.data.data?.totalReservations || 0);
      setTableReservationList(res.data.data?.filteredReservationsData);
      // console.log("ddd" ,res.data.data)
    } catch (err) {
      if (err) {
        setRowCount(0);
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
  prevSorting.current = sorting;

  useEffect(() => {
    if (pagination.pageIndex, pagination.pageSize) {
      getTableReservationList();
    }
  }, [pagination.pageIndex, pagination.pageSize, globalFilter ?? '',
  BranchId?.value, FilterData?.startDate, FilterData?.endDate, FilterData?.orderStatus,
    sortingChanged]);


  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const prevPaginationRef = useRef();

  useEffect(() => {
    if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
      sessionStorage.setItem(`${url23}_paginationState`, JSON.stringify(pagination));
    }
    prevPaginationRef.current = pagination;
  }, [pagination]);



  useEffect(() => {
    getBranchList();
  }, [])


  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };


  function handleSubmitCopyMenu(event) {
    event.preventDefault();
    setError(null);

    if (!CopyBranchId) {
      setError("Please select branch.");
    } else if (menuId === "") {
      setError("Please select menu.");
    } else if (!menu_name) {
      setError("Please enter menu name.");
    } else {
      let item = {
        branch_id: CopyBranchId?.value,
        menu_name: menu_name,
        menu_id: menuId,
      };

      api
        .post(`/groupadmin/menu/copy`, item, { headers })
        .then((res) => {
          handleClose()
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Menu details has been copied succesfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          // getMenuList();
          // window.location.reload(true);
          // setmenuId([]);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }
  }

  const [DataTable ,setDataTable] = useState("")


  function handleSubmitEdit(data, status) {


    if(status === "reserved"){
      let item = {
        reservation_status: status,
      };
  
      api
        .post(`/groupadmin/table_reservation/${data?._id}`, item, { headers })
        .then((res) => {
          showSuccessNotification("Table reservation accepted successfully")
          getTableReservationList();
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }else if (status === "cancelled"){
      handleShowTableCancel();
      setDataTable(data)
    }
    
  }


  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'booking_id',
        header: 'Booking ID',
        // size: 300,
        Cell: ({ row }) => {
          const hasViewPermission = userPermissionType_ || MenuPermissions?.access?.includes("view");
          return hasViewPermission ? (
            <Link
              to={{
                pathname: `/group_admin/table_reservations/table_reservations_details/${row?.original?._id}`,
                // state: { menuId: row?.original?._id, branchID: row?.original?.branch?._id },
              }}
            >
              <span style={HeaderStyle?.uniqueCodeHeader}>
                {row?.original?.booking_id}
              </span>
            </Link>
          ) : (
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.menu_name}
            </span>
          );
        },
      }
      ,

      {
        accessorKey: 'branchname',
        header: 'Branch',
        // size: 270,
        Cell: ({ row }) => row?.original?.branch?.branch_name_EN,
        sortingFn: (rowA, rowB) => {
          const dateA = rowA?.original?.branch?.branch_name_EN;
          const dateB = rowB?.original?.branch?.branch_name_EN;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        }
      },
      {
        accessorKey: 'booking_type',
        header: 'Booking Type',
        // size: 270,
        Cell: ({ row }) => (row?.original?.booking_type)
      },
      {
        accessorKey: 'booking_time',
        header: 'Booking Slot',
        // size: 270,
        Cell: ({ row }) => (row?.original?.booking_time)
      },
      {
        accessorKey: 'no_of_guests',
        header: 'Total Guests',
        // size: 270,
        Cell: ({ row }) => (row?.original?.no_of_guests)
      },
      {
        accessorKey: 'name',
        header: 'Customer Details',
        // size: 270,
        Cell: ({ row }) => (<>
          <div>
            {row?.original?.name}
          </div>
          <div>
            {row?.original?.phone_number}
          </div>
          <div>
            {row?.original?.email}
          </div>
        </>),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        // size: 270,
        Cell: ({ row }) => renderStatusChipTableReservations(row?.original?.status),
      },
      {
        accessorKey: 'special_requirements',
        header: 'Special Requirements',
        // size: 270,
        Cell: ({ row }) => (row?.original?.special_requirements || "---")
      },
      {
        accessorKey: 'message',
        header: 'Message',
        // size: 270,
        Cell: ({ row }) => (row?.original?.message)
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const table = useMaterialReactTable({
    columns: Columns,
    data: TableReservationList,
    ...tableOptionTableReservationList({
      getTableReservationList,
      url23, handleSubmitEdit,handleShowTableCancel,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      isError,
      isRefetching,
      columnFilters,
      globalFilter,
      sorting,
      setColumnFilters,
      setGlobalFilter,
      setPagination,
      setSorting,
      rowCount,
      isLoading,
      pagination,
      windowHeight,
    }),
  });


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setmenu_name("");
    setBranchID("");
    setmenuId("");
    setShow(false)
  };

  const handleShow = () => setShow(true);

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  return (
    <React.Fragment>
      <ToastContainer
        toastStyle={{
          border: "1px solid black",
          fontSize: "17px",
        }}
      />
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Table Reservations
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                            className="search-line mx-3"
                            value={BranchId}
                            placeholder={getPlaceholder(filteredOptions)}
                            options={getSelectOptions(filteredOptions)}
                            onChange={(opt) => {
                              if (opt.label === "All Locations") {
                                console.log("opt", opt.label === "All Locations")
                                getTableReservationList();
                                setBranchID("");
                                sessionStorage.removeItem(`${url23}_branchName`)
                              } else {
                                setBranchID(opt);
                                sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                              }
                            }}
                            styles={selectStyles}
                          />



                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          {/* {(userPermissionType_ || MenuPermissions?.access?.includes("create")) &&
                            <button onClick={handleClick} className="btn btn-danger mx-4">
                              <span className="me-2"> More Actions </span><KeyboardArrowDownOutlinedIcon />
                            </button>
                            } */}

                          {/* {(userPermissionType_ || MenuPermissions?.access?.includes("create")) &&
                            <Addnewdetails
                              url={"/group_admin/menu_management/add_new_menu"}
                              Name="Add New Menu" />
                          } */}
                        </div>
                      </div>
                    </div>

                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="mt-5">
                      <MaterialReactTable
                        table={table}
                      /></div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>

      <ColumPopoverCommon
        anchorEl_={anchorEl_}
        handleClickColum_={handleClickColum_}
        onColumnDataChange={onColumnDataChange}
        handleCloseColum_={handleCloseColum_}
        url23={url23}
      />

      <FilterPopover
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        Type={""}
        handleClose={handleFilterClose}
        handleFilter={handleApplyFilters}
      />


      <TableCancel
        showTableCancel={showTableCancel}
        handleCloseTableCancel={handleCloseTableCancel}
        getTableReservationList={() => getTableReservationList()}
        DataTable={DataTable}
      />


    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReservationsList);

