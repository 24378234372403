import React, { useRef, useState } from 'react'
import GroupAdminSidebar from '../../../../../Comman/Sidebar'
import GroupAdminHeader from '../../../../../Comman/Header'
import GroupAdminUperSide from '../../../../../Comman/UperSide'
import useDrawer from '../../../../../../context/DrawerContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Editor } from "@tinymce/tinymce-react";
import TyniAMC from '../../../../../../CommonApi/TyniAmc';
import { UserPermissionAllData, userPermissionType } from '../../../../../Comman/UserPermission';
import api from '../../../../../../CommonApi/axios';
import { showSuccessNotification } from '../../../../../Comman/Swal/Swal';
import useError from '../../../../../Comman/Error/useError';
import { ErrorMessage } from '../../../../../Comman/Error/ErrorMessage';
import headers from '../../../../../../CommonApi/headers';

const TinyMCE_Api_Key = TyniAMC.TyniAMCAPIKEy;

export default function AddEmailTemplate() {

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();
  const userPermissionType_ = userPermissionType === "groupadmin"
  const { error, showError, clearError } = useError();

  let history = useHistory();
  const editorRefEN = useRef(null);
  const [TemplateName, setTemplateName] = useState("");
  const [SubjectLine, setSubjectLine] = useState("");
  const [description, setDescription] = useState("");

  const handleEditorChange = (content) => {
    setDescription(content); // Update the state with the editor content
  };

  console.log("description", description)

  function logEN(event) {
    event.preventDefault();
    clearError(null);

    var editorContent = editorRefEN.current.getContent();
    if (editorContent == "") {
      showError("Please Fill");
    } else {
      let item = {
        description_EN: editorRefEN.current.getContent(),
        // description_DE: editorRef.current.getContent(),
        // description_FR: editorRef.current.getContent(),
        // description_IT: editorRef.current.getContent()
      };

      api.post(`/groupadmin/product/description/`, item, { headers }).then((res) => {
        showSuccessNotification("Your work has been saved successfully")
        // groupAdminBranchList();
        // window.location.reload(true);
      })
        .catch((err) => {
          if (err.response) {
            showError(err.response?.data?.error?.message);
          } else if (err.request) {
            showError(err.request);
          } else {
            showError(err.message);
          }
        });
    }
  }



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div

                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Settings - Add Email Template
                          </h1>

                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          <button
                            className="btn btn-primary"
                            type='button'
                            onClick={() => history.push("/group_admin/settings/emailtemplate/emailtemplatelist")}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    {/* <div className="row col-md-12 mb-4">
                      <div
                        className="col-md-9"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Add Email Template
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                      </div>
                    </div> */}

                    {/* <div className="rounded border p-5"> */}
                      <div
                        className="card card-docs mb-2">
                        <div className="card-body">
                          <div className="row col-md-12 mt-2">
                            <div className="col-md-2">
                              <div style={{ fontSize: 16, fontWeight: '500', color: '#52525B' }} className="mt-3">Template Name :</div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-5">
                                <input
                                  type="text"
                                  placeholder="Enter Template Name"
                                  className="form-control"

                                  value={TemplateName}
                                  onChange={(e) => setTemplateName(e.target.value)}

                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                          </div>
                          <div className="row col-md-12 mt-2">
                            <div className="col-md-2">
                              <div style={{ fontSize: 16, fontWeight: '500', color: '#52525B' }} className="mt-3">Subject line :</div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-5">
                                <input
                                  type="text"
                                  placeholder="Enter Subject Line"
                                  className="form-control"

                                  value={SubjectLine}
                                  onChange={(e) => setSubjectLine(e.target.value)}

                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                          </div>

                          {/* <Editor
                            onInit={(evt, editor) => (editorRefEN.current = editor)}
                            apiKey={TinyMCE_Api_Key}
                            value={description_EN}
                            init={{
                              height: 1200, // Adjust height for better UI
                              toolbar: 'undo redo | styles | bold italic underline strikethrough forecolor backcolor | fontselect fontsizeselect formatselect | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | link image code codesample | table searchreplace charmap preview print |  ltr rtl | removeformat fullscreen', // Add font size and font family dropdowns
                              content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }",
                              menubar: "custom", // Show the format menu in the menubar
                              menu: {
                                custom: { title: 'Custom menu', items: 'basicitem nesteditem toggleitem' }
                              },
                              setup: function (editor) {
                                var toggleState = false;

                                editor.ui.registry.addMenuItem('basicitem', {
                                  text: 'My basic menu item',
                                  onAction: function () {
                                    editor.insertContent('<p>Here\'s some content inserted from a basic menu!</p>');
                                  }
                                });

                                editor.ui.registry.addNestedMenuItem('nesteditem', {
                                  text: 'My nested menu item',
                                  getSubmenuItems: function () {
                                    return [
                                      {
                                        type: 'menuitem',
                                        text: 'My submenu item',
                                        onAction: function () {
                                          editor.insertContent('<p>Here\'s some content inserted from a submenu item!</p>');
                                        }
                                      }
                                    ];
                                  }
                                });

                                editor.ui.registry.addToggleMenuItem('toggleitem', {
                                  text: 'My toggle menu item',
                                  onAction: function () {
                                    toggleState = !toggleState;
                                    editor.insertContent('<p class="toggle-item">Here\'s some content inserted from a toggle menu!</p>');
                                  },
                                  onSetup: function (api) {
                                    api.setActive(toggleState);
                                    return function () {};
                                  }
                                });
                              },
                              statusbar: false,
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount',
                                "mentions advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                              ],
                              autoresize_bottom_margin: 20,
                              branding: false,
                              entity_encoding: "raw",
                              relative_urls: false,
                              font_formats: "Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times; Courier New=courier new,courier; Verdana=verdana,geneva;",
                              fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt", // Define available font sizes
                              image_title: true, // Enable image title field
                              automatic_uploads: true, // Automatically upload images
                              file_picker_types: 'image', // Limit file picker to images
                              file_picker_callback: function (callback, value, meta) {
                                if (meta.filetype === 'image') {
                                  const input = document.createElement('input');
                                  input.setAttribute('type', 'file');
                                  input.setAttribute('accept', 'image/*');
                                  input.onchange = function () {
                                    const file = this.files[0];
                                    const reader = new FileReader();
                                    reader.onload = function () {
                                      callback(reader.result, {
                                        alt: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };
                                  input.click();
                                }
                              },
                              emoticons_append: {
                                custom_mind_explode: {
                                  keywords: ["brain", "mind", "explode", "blown"],
                                  char: "🤯",
                                },
                              },
                            }}
                          /> */}


                          <Editor
                            onInit={(evt, editor) => (editorRefEN.current = editor)}
                            apiKey={TinyMCE_Api_Key}
                            value={description}
                            onEditorChange={handleEditorChange}
                            init={{
                              height: 1200,
                              toolbar:
                                'undo redo | styles | bold italic underline strikethrough forecolor backcolor | fontselect fontsizeselect formatselect | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | link image code codesample | table searchreplace charmap preview print | ltr rtl | removeformat fullscreen | customVariable',
                              content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }",
                              menubar: false,
                              setup: function (editor) {
                                // Add custom menu button
                                editor.ui.registry.addMenuButton('customVariable', {
                                  text: '{ }  Variable',
                                  fetch: function (callback) {
                                    const items = [
                                      {
                                        type: 'menuitem',
                                        text: 'My basic menu item',
                                        onAction: function () {
                                          editor.insertContent('<p>Here\'s some content inserted from a basic menu!</p>');
                                        },
                                      },
                                      {
                                        type: 'nestedmenuitem',
                                        text: 'My nested menu item',
                                        getSubmenuItems: function () {
                                          return [
                                            {
                                              type: 'menuitem',
                                              text: 'My submenu item',
                                              onAction: function () {
                                                editor.insertContent('<p>Here\'s some content inserted from a submenu item!</p>');
                                              },
                                            },
                                          ];
                                        },
                                      },
                                      {
                                        type: 'togglemenuitem',
                                        text: 'My toggle menu item',
                                        onAction: function (api) {
                                          const toggleState = api.isActive();
                                          api.setActive(!toggleState);
                                          editor.insertContent(
                                            `<p class="toggle-item">Here's some content inserted from a toggle menu! State: ${toggleState ? 'Inactive' : 'Active'}</p>`
                                          );
                                        },
                                      },
                                    ];
                                    callback(items);
                                  },
                                });
                              },
                              statusbar: false,
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount',
                              ],
                              autoresize_bottom_margin: 20,
                              branding: false,
                              entity_encoding: "raw",
                              relative_urls: false,
                              font_formats: "Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times; Courier New=courier; Verdana=verdana,geneva;",
                              fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                              image_title: true,
                              automatic_uploads: true,
                              file_picker_types: 'image',
                              file_picker_callback: function (callback, value, meta) {
                                if (meta.filetype === 'image') {
                                  const input = document.createElement('input');
                                  input.setAttribute('type', 'file');
                                  input.setAttribute('accept', 'image/*');
                                  input.onchange = function () {
                                    const file = this.files[0];
                                    const reader = new FileReader();
                                    reader.onload = function () {
                                      callback(reader.result, { alt: file.name });
                                    };
                                    reader.readAsDataURL(file);
                                  };
                                  input.click();
                                }
                              },
                              emoticons_append: {
                                custom_mind_explode: {
                                  keywords: ["brain", "mind", "explode", "blown"],
                                  char: "🤯",
                                },
                              },
                            }}
                          />
                          <ErrorMessage error={error} />

                          <div className='text-center mt-5 col-md-12'>
                            {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                              permission?.key === "email_template" &&
                              permission?.access?.includes("create")
                            )) && (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  onClick={logEN}
                                >
                                  Save
                                </button>
                              )}

                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>


    </React.Fragment>
  )
}
