import { Popover, List, ListItem, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./ColumPopover.css";

export default function ColumPopoverCommon(props) {

  const { anchorEl_, handleCloseColum_, onColumnDataChange, url23 } = props

  const [columnData, setColumnData] = useState({});

  const [Array_, setArray] = useState("")

  // var pathname = window.location.pathname;
  // var pathnamesplits = pathname.split("/");
  // var url23 = pathnamesplits[2];

  // console.log("columnData" ,columnData);
  // console.log("Array_" ,Array_);


  const columnsBranch = [
    {
      header: 'Branch Name',
    },
    {
      header: 'Branch Location',
    },
    {
      header: 'Phone Number',
    },
    {
      header: 'Email',
    },
    {
      header: 'Time Zone',
    },
    {
      header: 'Currency',
    },
    {
      header: 'Sequence No',
    },
    {
      header: 'Status',
    },
  ];

  const columnsTableReservations = [
    {
      header: 'Booking ID',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Booking Type',
    },
    {
      header: 'Booking Slot',
    },
    {
      header: 'Total Guests',
    },
    {
      header: 'Customer Details',
    },
    {
      header: 'Status',
    },
    {
      header: 'Special Requirements',
    },
    {
      header: 'Message',
    },

    
  ];

  const columnsTables = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Table No',
    },
    {
      header: 'QR Link',
    },
    {
      header: 'QR Code',
    },
  ];

  const columnsMenu = [
    {
      header: 'Menu Name',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Created Date',
    },
    {
      header: 'Status',
    },
  ];

  const columnsInventoryProducts = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Product Name',
    },
    {
      header: 'Category Name',
    },
    {
      header: 'Status',
    },
  ];

  const columnsInventoryCategory = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Category Name',
    },
    {
      header: 'Status',
    },
  ];

  const columnsStock = [
    {
      header: 'Product',
    },
    {
      header: 'Category',
    },
    {
      header: 'Total Quantity',
    },
    // {
    //   header: 'Price',
    // },
    {
      header: 'Branch',
    },
    {
      header: 'Minimum Quantity',
    },
  ];

  const columnsStockViewList = [
    {
      header: 'Quantity Added',
    },
    {
      header: 'Remaining Quantity',
    },
    {
      header: 'Price',
    },
    {
      header: 'Date & Time',
    },
    {
      header: 'Added by',
    },
  ];

  const columnsStockViewUsed = [
    {
      header: 'Quantity',
    },
    {
      header: 'Description',
    },
    {
      header: 'Date & Time',
    },
  ];

  const columnsEmailMarketion = [
    {
      header: 'Customer Name',
    },
    {
      header: 'Customer Email',
    },
    {
      header: 'Customer Number',
    },
    {
      header: 'Status',
    },]

  const columnsSubcription = [
    {
      header: 'Sr.No.',
    },
    {
      header: 'Plan',
    },
    {
      header: 'Purchased Date',
    },
    {
      header: 'End Date',
    },
    {
      header: 'Status',
    },
  ];

  const columnsMenuProducts = [
    {
      header: 'Product Name',
    },
    {
      header: 'Price',
    },
    {
      header: 'Printer',
    },
    {
      header: 'Sequence No',
    },
    {
      header: 'Status',
    },
  ];

  const columnsPrinterList = [
    {
      header: 'Printer Name',
    },
    {
      header: 'Branch Name',
    },
    {
      header: 'Printer Category',
    },
    {
      header: 'Ip Address',
    },
    {
      header: 'Default Printer',
    },
    {
      header: 'Print Receipt',
    },
    {
      header: 'Status',
    },
  ];

  const columnsPrinterCategoryList = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Category Name',
    },
  ];

  const columnsCouponMan = [
    {
      header: 'Coupon Code',
    },
    {
      header: 'Branch Name',
    },
    {
      header: 'Discount Type',
    },
    {
      header: 'Discount Value',
    },
    {
      header: 'Apply Coupon Type',
    },
    {
      header: 'Order Type',
    },
  ];

  const columnsVoucherTemplates = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Branch Name',
    },
    {
      header: 'Voucher Category',
    },
    {
      header: 'Salutation Title',
    },
    {
      header: 'Voucher Validity',
    },
    {
      header: 'Status',
    },
  ];

  const columnsPurchasedVouchers = [
    {
      header: 'Voucher Code',
    },
    {
      header: 'Customer Details',
    },
    {
      header: 'Branch Name',
    },
    {
      header: 'Voucher Amount',
    },
    {
      header: 'Remaining Amount',
    },
    {
      header: 'Redeem',
    },
  ];

  const columnsUserManagement = [
    {
      header: 'User Name',
    },
    {
      header: 'Assigned Role',
    },
    {
      header: 'Phone Number',
    },
    {
      header: 'Email',
    },
    {
      header: 'Status',
    },
  ];

  const columnsUserRoles = [
    {
      header: 'Role Name',
    },
    {
      header: 'Created On',
    },
    {
      header: 'Last Active',
    },
  ];

  const columnsReasonsManagement = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Reason',
    },
  ];

  const columnsCompletedEnquiry = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Phone Number',
    },
    {
      header: 'Email',
    },
    {
      header: 'Enquiry Date',
    },
  ];

   const columnsPendingEnquiry = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Phone Number',
    },
    {
      header: 'Email',
    },
    {
      header: 'Enquiry Date',
    },
  ];

  const columnsEmailTemplate = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Template Name',
    },
    {
      header: 'Subject Line',
    },
  ];
  



  useEffect(() => {
    const storedData = sessionStorage.getItem(`${url23}_columnData`);
    if (storedData === null) {
      // If sessionStorage does not have the data, initialize it
      const initialDataMenu = Object.fromEntries(columnsMenu.map(column => [column.header, true]));
      const initialDataEmailMarketing = Object.fromEntries(columnsEmailMarketion.map(column => [column.header, true]));
      const initialData1 = Object.fromEntries(columnsEmailMarketion.map(column => [column.header, true]));
      const initialDatacolumnsInventoryProducts = Object.fromEntries(columnsInventoryProducts.map(column => [column.header, true]));
      const initialDatacolumnsInventoryCategory = Object.fromEntries(columnsInventoryCategory.map(column => [column.header, true]));
      const initialDataStock = Object.fromEntries(columnsStock.map(column => [column.header, true]));

      const StockViewList = Object.fromEntries(columnsStockViewList.map(column => [column.header, true]));
      const StockViewUsed = Object.fromEntries(columnsStockViewUsed.map(column => [column.header, true]));


      const Subcription = Object.fromEntries(columnsSubcription.map(column => [column.header, true]));
      const MenuProducts = Object.fromEntries(columnsMenuProducts.map(column => [column.header, true]));
      const PrinterList = Object.fromEntries(columnsPrinterList.map(column => [column.header, true]));
      const PrinterCategoryList = Object.fromEntries(columnsPrinterCategoryList.map(column => [column.header, true]));

      const CouponList = Object.fromEntries(columnsCouponMan.map(column => [column.header, true]));
      const VoucherTemplatesList = Object.fromEntries(columnsVoucherTemplates.map(column => [column.header, true]));
      const PurchasedVouchersList = Object.fromEntries(columnsPurchasedVouchers.map(column => [column.header, true]));

      const BranchList = Object.fromEntries(columnsBranch.map(column => [column.header, true]));
      const TableList = Object.fromEntries(columnsTables.map(column => [column.header, true]));

      const UserManagementList = Object.fromEntries(columnsUserManagement.map(column => [column.header, true]));
      const UserRolesList = Object.fromEntries(columnsUserRoles.map(column => [column.header, true]));
      const ReasonsManagement = Object.fromEntries(columnsReasonsManagement.map(column => [column.header, true]));
      const TableReservationsList = Object.fromEntries(columnsTableReservations.map(column => [column.header, true]));
      const CompletedEnquiryList = Object.fromEntries(columnsCompletedEnquiry.map(column => [column.header, true]));
      const PendingEnquiryList = Object.fromEntries(columnsPendingEnquiry.map(column => [column.header, true]));
      const EmailTemplateList = Object.fromEntries(columnsEmailTemplate.map(column => [column.header, true]));

      
      

      if (url23 === "menu_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataMenu));
        setColumnData(initialDataMenu);
      } else if (url23 === "stock_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataStock));
        setColumnData(initialDataStock);
      }
      else if (url23 === "view_stock_list") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(StockViewList));
        setColumnData(StockViewList);
      }
      else if (url23 === "view_stock_used") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(StockViewUsed));
        setColumnData(StockViewUsed);
      }
      else if (url23 === "email_marketing") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataEmailMarketing));
        setColumnData(initialDataEmailMarketing);
      } else if (url23 === "productlist") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDatacolumnsInventoryProducts));
        setColumnData(initialDatacolumnsInventoryProducts);
      } else if (url23 === "categorylist") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDatacolumnsInventoryCategory));
        setColumnData(initialDatacolumnsInventoryCategory);
      }
      else if (url23 === "subscription_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(Subcription));
        setColumnData(Subcription);
      } else if (url23 === "menu_products") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(MenuProducts));
        setColumnData(MenuProducts);
      }
      else if (url23 === "printerList") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(PrinterList));
        setColumnData(PrinterList);
      }
      else if (url23 === "printerCategory") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(PrinterCategoryList));
        setColumnData(PrinterCategoryList);
      }
      else if (url23 === "coupon_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(CouponList));
        setColumnData(CouponList);
      }
      else if (url23 === "voucher_templates") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(VoucherTemplatesList));
        setColumnData(VoucherTemplatesList);
      }
      else if (url23 === "purchased_vouchers") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(PurchasedVouchersList));
        setColumnData(PurchasedVouchersList);
      }
      else if (url23 === "branch_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(BranchList));
        setColumnData(BranchList);
      }
      else if (url23 === "table_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(TableList));
        setColumnData(TableList);
      }
      else if (url23 === "user_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(UserManagementList));
        setColumnData(UserManagementList);
      }
      else if (url23 === "roles") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(UserRolesList));
        setColumnData(UserRolesList);
      }
      else if (url23 === "reasons_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(ReasonsManagement));
        setColumnData(ReasonsManagement);
      }
      else if (url23 === "table_reservations") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(TableReservationsList));
        setColumnData(TableReservationsList);
      }
      else if (url23 === "completed_enquiry") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(CompletedEnquiryList));
        setColumnData(CompletedEnquiryList);
      }
      else if (url23 === "pending_enquiry") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(PendingEnquiryList));
        setColumnData(PendingEnquiryList);
      }
      else if (url23 === "emailtemplatelist") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(EmailTemplateList));
        setColumnData(EmailTemplateList);
      }

      else {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialData1));
        setColumnData(initialData1);
      }
    } else {
      const parsedData = JSON.parse(storedData);
      setColumnData(parsedData);
    }
    setArray(url23 === "menu_management" ?
      columnsMenu :
      url23 === "stock_management" ?
        columnsStock :
        url23 === "view_stock_list" ?
          columnsStockViewList :
          url23 === "view_stock_used" ?
            columnsStockViewUsed :
            url23 === "email_marketing" ?
              columnsEmailMarketion :
              url23 === "productlist" ?
                columnsInventoryProducts :
                url23 === "categorylist" ?
                  columnsInventoryCategory :
                  url23 === "subscription_management" ?
                    columnsSubcription :
                    url23 === "menu_products" ?
                      columnsMenuProducts :
                      url23 === "printerList" ?
                        columnsPrinterList :
                        url23 === "printerCategory" ?
                          columnsPrinterCategoryList :
                          url23 === "coupon_management" ?
                            columnsCouponMan :
                            url23 === "voucher_templates" ?
                              columnsVoucherTemplates :
                              url23 === "purchased_vouchers" ?
                                columnsPurchasedVouchers :
                                url23 === "branch_management" ?
                                  columnsBranch :
                                  url23 === "table_management" ?
                                    columnsTables :
                                    url23 === "user_management" ?
                                      columnsUserManagement :
                                      url23 === "roles" ?
                                        columnsUserRoles : 
                                        url23 === "reasons_management" ?
                                        columnsReasonsManagement : 
                                        url23 === "table_reservations" ? 
                                        columnsTableReservations :
                                        url23 === "completed_enquiry" ?
                                        columnsCompletedEnquiry : 
                                        url23 === "pending_enquiry" ? 
                                        columnsPendingEnquiry : 
                                        url23 === "emailtemplatelist" ?
                                         columnsEmailTemplate : "")
  }, [url23]);


  const handleCheckboxClick = (columnName, isChecked) => {
    const updatedColumnData = {
      ...columnData,
      [columnName]: isChecked,
    };
    sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));
    setColumnData(updatedColumnData);
    onColumnDataChange(updatedColumnData);
  };


  const isColumnChecked = (columnName) => {
    return columnData[columnName] === true;
  };

  return (
    <Popover
      open={Boolean(anchorEl_)}
      anchorEl={anchorEl_}
      onClose={handleCloseColum_}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: 3,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 85,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Typography variant="h6" sx={{ px: 2, py: 1 }}>
        Display Columns
      </Typography>
      <List sx={{ px: 1 }}>
        {Array_ !== "" && Array_?.map((column, index) => (
          <ListItem sx={{ py: 0 }} key={index}
            style={{
              display: ["Menu Name", "Product Name", "Printer Name", "Coupon Code", "Voucher Code",
                "Branch Name", "User Name" ,"Role Name" , "Booking ID"].includes(column?.header) ? "none" : ""
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  className="checkbox"
                  checked={isColumnChecked(column.header)}
                  onClick={(e) => handleCheckboxClick(column.header, e.target.checked)} />
              }
              label={
                <Typography
                  className="checkboxname">{column.header}</Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}
