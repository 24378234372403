import { useState, useEffect } from 'react';

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url23 = pathnamesplits[3];

  // console.log("url23", url23)

  useEffect(() => {
    const handleResize = () => {

      const getDistanceFromBottom = (url23) => {
        let distanceFromBottom;

        switch (url23) {
          case "view_stock":
          case "inventory_management":
          case "voucher_management":
            distanceFromBottom = 260;
            break;
          case "user_management":
          case "roles":
          case "table_management":
          case "printer":
          case "discount_and_tips_management":
            distanceFromBottom = 310;
            break;
          default:
            distanceFromBottom = 200;
            break;
        }

        return distanceFromBottom;
      };

      const windowHeight = window.innerHeight;
      const distanceFromBottom = getDistanceFromBottom(url23);
      const buttonPosition = windowHeight - distanceFromBottom;
      setWindowHeight(buttonPosition);
    };

    // Initial calculation
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowHeight;
};

export default useWindowHeight;
