import React from 'react'
import { Link } from "react-router-dom";


export default function TableResLinkCommon() {

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];

  return (
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/booktable/booktable_management",
          }}
          className={
            url === "booktable_management"
              ? "nav-link active"
              : "nav-link"
          }
        >
         Table Reservation Management
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={{
            pathname:
              "/group_admin/settings/booktable/setting",
          }}
          className={
            url === "setting"
              ? "nav-link active"
              : "nav-link"
          }
        >
          Settings
        </Link>
      </li>
    </ul>
  )
}
