import React, { useEffect, useState, useMemo } from "react";
import GroupAdminHeader from "../../../../../Comman/Header";
import GroupAdminSidebar from "../../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../../Comman/UperSide";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import useDrawer from "../../../../../../context/DrawerContext";
import api from "../../../../../../CommonApi/axios";
import headers from "../../../../../../CommonApi/headers";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessNotification } from "../../../../../Comman/Swal/Swal";
import TableResLinkCommon from "../TableReservationCommon/TableResLinkCommon";
import NoteState from "../../CommanBranch/NoteState";
import $ from "jquery";
import moment from "moment";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { applyFilterMeHandlers } from "../../../../../Comman/Error/jqueryUtils";
import { deleteCommonFunction } from "../../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { UserPermissionAllData, userPermissionType } from "../../../../../Comman/UserPermission";
import { showToastCommon } from "../../../../../Comman/Toastify/Toastify";
import "./TableRes.css"

window.jquery = window.$ = $;

function TableReservationManagment({ branchID }) {

      const [error, setError] = useState(null);
      const [isReservationsEnabled, setIsReservationsEnabled] = useState(true);
      const [list_branch, setlist_branch] = useState(false)

      useEffect(() => {
            applyFilterMeHandlers(); // Apply the jQuery handlers
            scrollToTop();
      }, []);

      const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: "smooth" });
      };


      const [data, setForm] = useState([
            {
                  Week: "",
                  booking_type: "",
                  start_time: "",
                  end_time: "",
                  minimum_guests: "",
                  maximum_guests: "",
                  booking_interval: "",
            },
      ]);

      // console.log("data" ,data)


      const ErrorDisplay = <div className="col-md-12">
            <div className="card shadow-sm">
                  <div className="card-body" style={{ textAlign: "center" }}>
                        <div className="col-md-12">
                              <a>
                                    <b style={{ color: "rgb(0, 0, 0)", fontSize: 20 }}>
                                          No Data Found.
                                    </b>
                                    <span />
                              </a>
                        </div>
                  </div>
            </div>{" "}
      </div>
      ////////////////////////// Pickup Order List /////////////////////
      useEffect(() => {
            if (branchID !== null) {
                  groupAdminPickupList();
                  groupAdminPickupHolidayList();
            }
            setError1(null);
            setValue("");
            setValue1("");
            setForm([
                  {
                        Week: "",
                        booking_type: "",
                        start_time: "",
                        end_time: "",
                        minimum_guests: "",
                        minimum_guests: "",
                        booking_interval: "",
                  },
            ])
      }, [branchID]);

      // const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];


      const [slot_management_type, setslot_management_type] = useState("auto");
      const [manual_dates, setmanual_dates] = useState("")


      const [pickupList, setpickupList] = useState([]);
      const [errorListDe, setErrorListDl] = useState(null);
      const [loader, setloader] = useState(false)

      // console.log("pickupList", pickupList);
      // console.log('slot_management_type' ,slot_management_type)


      function groupAdminPickupList() {
            setErrorListDl(null);
            // setloader(true);
            setError(null)
            setpickupList([])
            api
                  .get(`/groupadmin/settings/table_reservation_time/table_reservation/${branchID}`, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        setErrorListDl(null);
                        setpickupList(tableData);
                        // console.log("tableData" ,tableData?.[0]?.slot_management_type);
                        // console.log("tableData" ,tableData);
                        setslot_management_type(tableData?.[0].slot_management_type || "auto");
                        setIsReservationsEnabled(tableData?.[0].reservation_allowed);
                        setlist_branch(tableData?.[0].list_branch);

                  })
                  .catch((err) => {
                        if (err.response) {
                              setErrorListDl("No Data Found.");
                              setslot_management_type("auto")
                              setpickupList([]);
                        }
                  });
      }


      ////////////////////////// Pickup Order List /////////////////////

      ////////////////////////// Pickup Holiday Order List /////////////////////

      const [PickupHolidayList, setPickupHolidayList] = useState([]);
      const [errorListHo, setErrorListHo] = useState(null);


      const [value, setValue] = useState("");
      const [value1, setValue1] = useState("");

      const today = new Date()?.toISOString()?.split('T')[0];


      function groupAdminPickupHolidayList() {
            setErrorListHo(null);
            setPickupHolidayList([]);
            api
                  .get(`/groupadmin/settings/table_reservation_time/holiday/${branchID}`, {
                        headers,
                  })
                  .then((res) => {
                        setErrorListHo(null);
                        const tableData = res.data.data;
                        // console.log("tableData" ,tableData)
                        setPickupHolidayList(tableData);
                  })
                  .catch((err) => {
                        if (err.response) {
                              setErrorListHo("No Data Found.");
                              setPickupHolidayList([]);
                        }
                  });
      }

      ////////////////////////// Pickup Holiday Order List /////////////////////

      function deleteHoliday(_id) {

            const item = {}

            deleteCommonFunction(`/groupadmin/settings/order_time/holiday/${_id}`,
                  "Holiday has been deleted successfully.", () => {
                        groupAdminPickupHolidayList();
                        setValue("");
                        setValue1("");
                  }, item)
      }


      function groupAdminSlotChange(data) {
            setpickupList([]);
            setloader(true)
            setmanual_dates("")
            setForm([{
                  Week: "",
                  booking_type: "",
                  start_time: "",
                  end_time: "",
                  minimum_guests: "",
                  maximum_guests: "",
                  booking_interval: "",
            }])
            let item = {
                  branch_id: branchID,
                  slot_management_type: data,
                  reservation_allowed: isReservationsEnabled,
                  list_branch: list_branch
            }

            api
                  .post(`/groupadmin/settings/table_reservation_time/changeSlotManagementType`, item, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        setloader(false)
                        setErrorListDl(null);
                        groupAdminPickupList()
                        showSuccessNotification("Slot has been changed succesfully.")
                  })
                  .catch((err) => {
                        setloader(false)
                        // if (err.response) {
                        //   setErrorListDl("No Data Found.");
                        // }
                  });
      }


      function groupAdminSlotChange1(data, data1, data2) {
            setpickupList([]);
            setloader(true)
            setmanual_dates("")
            setForm([{
                  Week: "",
                  booking_type: "",
                  start_time: "",
                  end_time: "",
                  minimum_guests: "",
                  maximum_guests: "",
                  booking_interval: "",
            }])
            let item = {
                  branch_id: branchID,
                  slot_management_type: data,
                  reservation_allowed: data1,
                  list_branch: data2,
            }

            api
                  .post(`/groupadmin/settings/table_reservation_time/changeSlotManagementType`, item, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        setloader(false)
                        setErrorListDl(null);
                        groupAdminPickupList()
                        showSuccessNotification("Your work has been saved succesfully.")
                  })
                  .catch((err) => {
                        setloader(false)
                        // if (err.response) {
                        //   setErrorListDl("No Data Found.");
                        // }
                  });
      }


      //////////////////////////////  Order //////////////////////////////////



      const handleCheck = (index, e, selected) => {
            // console.log(index, e.target.value, selected);
            let temp = [...data];
            temp[index][selected] = e.target.value;
            setForm(temp);
      };

      const handleremove = (index) => {
            // console.log(index)
            let list = [...data];
            list.splice(index, 1);
            setForm(list);
      };

      const errorDiv = error && (
            <div className="text-center alert alert-danger">{error}</div>
      )

      // console.log('data', data)

      let history = useHistory();

      const handleSubmit = (e) => {
            e.preventDefault();
            setError(null);
            if (branchID === null) {
                  setError("Please Select Branch");
            } else if (data.length === 0) {
                  setError("Please Select Time");
            } else {

                  if (slot_management_type === "auto") {

                        let monday = [];
                        let tuesday = [];
                        let wednesday = [];
                        let thursday = [];
                        let friday = [];
                        let saturday = [];
                        let sunday = [];

                        for (let i = 0; i < data.length; i++) {
                              var weekkeys = data[i].Week;
                              var wkey = weekkeys.split("_");

                              var key1 = wkey[0] + "_start_time";
                              var key2 = wkey[0] + "_end_time";

                              // console.log("wkey" ,wkey);
                              // return

                              if (data[i].Week === "") {
                                    setError("Please select day.");
                                    return;
                              } else if (data[i].booking_type === "") {
                                    setError("Please select booking type.");
                                    return;
                              } else if (data[i].start_time === "") {
                                    setError("Please select opening slot time.");
                                    return;
                              } else if (data[i].end_time === "") {
                                    setError("Please select closing slot time.");
                                    return;
                              } else if (data[i].start_time === data[i].end_time) {
                                    setError("Opening and closing times cannot be the same. Please adjust.");
                                    return;
                              } else if (data[i].start_time > data[i].end_time) {
                                    setError("Opening slot time must be lower than closing slot time.");
                                    return;
                              } else if (data[i].end_time < data[i].start_time) {
                                    setError("Closing slot time must be greater than opening slot time.");
                                    return;
                              } 
                              // else if (data[i].minimum_guests === "") {
                              //       setError("Minimum guests does not allowed empty.");
                              //       return;
                              // }
                              // else if (data[i].maximum_guests === "") {
                              //       setError("Maximum guests does not allowed empty.");
                              //       return;
                              // } 
                              // else if (Number(data[i].minimum_guests) > Number(data[i].maximum_guests)) {
                              //       setError("Minimum guests cannot be greater than maximum guests.");
                              //       return;
                              // } 
                              
                              else if (data[i].booking_interval === "") {
                                    setError("Please select booking interval.");
                                    return;
                              }
                              else {
                                    // Helper function to check duplicates
                                    // const hasDuplicateBookingType = (dayArray, bookingType, dayName) => {
                                    //       const isDuplicate = dayArray.some(slot => slot.booking_type === bookingType);
                                    //       if (isDuplicate) {
                                    //             setError(`${bookingType} booking already exists for ${dayName}.`);
                                    //             return true;
                                    //       }
                                    //       return false;
                                    // };

                                    if (wkey[0] === "sun") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.sunday || [], data[i].booking_type, "Sunday")) return;
                                          sunday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "mon") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.monday || [], data[i].booking_type, "Monday")) return;
                                          monday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "tue") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.tuesday || [], data[i].booking_type, "Tuesday")) return;
                                          tuesday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "wed") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.wednesday || [], data[i].booking_type, "Wednesday")) return;
                                          wednesday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "thu") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.thursday || [], data[i].booking_type, "Thursday")) return;
                                          thursday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "fri") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.friday || [], data[i].booking_type, "Friday")) return;
                                          friday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    } else if (wkey[0] === "sat") {
                                          // if (hasDuplicateBookingType(pickupList[0]?.saturday || [], data[i].booking_type, "Saturday")) return;
                                          saturday.push({
                                                [key1]: data[i].start_time,
                                                [key2]: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    }

                                    else if (wkey[0] === "weekdays") {

                                          // if (hasDuplicateBookingType(pickupList[0]?.monday || [], data[i].booking_type, "Monday")) return;
                                          // if (hasDuplicateBookingType(pickupList[0]?.tuesday || [], data[i].booking_type, "Tuesday")) return;
                                          // if (hasDuplicateBookingType(pickupList[0]?.wednesday || [], data[i].booking_type, "Wednesday")) return;
                                          // if (hasDuplicateBookingType(pickupList[0]?.thursday || [], data[i].booking_type, "Thursday")) return;
                                          // if (hasDuplicateBookingType(pickupList[0]?.friday || [], data[i].booking_type, "Friday")) return;

                                          monday.push({
                                                mon_start_time: data[i].start_time,
                                                mon_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                          tuesday.push({
                                                tue_start_time: data[i].start_time,
                                                tue_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                          wednesday.push({
                                                wed_start_time: data[i].start_time,
                                                wed_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                          thursday.push({
                                                thu_start_time: data[i].start_time,
                                                thu_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                          friday.push({
                                                fri_start_time: data[i].start_time,
                                                fri_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    }
                                    else if (wkey[0] === "weekend") {
                                          // Weekend logic with validation
                                          // if (hasDuplicateBookingType(pickupList[0]?.saturday || [], data[i].booking_type, "Saturday")) return;
                                          // if (hasDuplicateBookingType(pickupList[0]?.sunday || [], data[i].booking_type, "Sunday")) return;

                                          saturday.push({
                                                sat_start_time: data[i].start_time,
                                                sat_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                          sunday.push({
                                                sun_start_time: data[i].start_time,
                                                sun_end_time: data[i].end_time,
                                                prep_time: Number(data[i].prep_time),
                                                booking_interval: data[0].booking_interval,
                                                min_guests: data[0].minimum_guests,
                                                max_guests: data[0].maximum_guests,
                                                booking_type: data[0].booking_type,
                                          });
                                    }
                              }
                        }

                        let item = {
                              branch_id: branchID,
                              monday: monday,
                              tuesday: tuesday,
                              wednesday: wednesday,
                              thursday: thursday,
                              friday: friday,
                              saturday: saturday,
                              sunday: sunday,
                              slot_management_type: slot_management_type,
                        };

                        // console.log("item", item);
                        // return

                        api
                              .post(`/groupadmin/settings/table_reservation_time`, item, { headers })
                              .then((res) => {
                                    $(".loader").hide();
                                    setForm([
                                          {
                                                Week: "",
                                                booking_type: "",
                                                start_time: "",
                                                end_time: "",
                                                minimum_guests: "",
                                                maximum_guests: "",
                                                booking_interval: "",
                                          }
                                    ])
                                    showSuccessNotification("Your work has been saved successfully.")
                                    groupAdminPickupList();
                                    // console.log(res.data.message);
                                    // window.location.reload(true);
                              })
                              .catch((err) => {
                                    $(".loader").hide();
                                    if (err.response) {
                                          setError(err.response.data?.error?.message);
                                    } else if (err.request) {
                                          setError(err.request);
                                    } else {
                                          setError(err.message);
                                    }
                              });

                  }
                  else {

                        // $(".loader").show();

                        if (manual_dates === "") {
                              setError("Please select date.");
                              return;
                        }
                        else if (data[0].booking_type === "") {
                              setError("Please select booking type.");
                              return;
                        } else if (data[0].start_time === "") {
                              setError("Please select opening slot time.");
                              return;
                        } else if (data[0].end_time === "") {
                              setError("Please select closing slot time.");
                              return;
                        }
                        else if (data[0].start_time === data[0].end_time) {
                              setError("Opening and closing times cannot be the same. Please adjust.");
                              return;
                        } else if (data[0].start_time > data[0].end_time) {
                              setError("Opening slot time must be lower than closing slot time.");
                              return;
                        } else if (data[0].end_time < data[0].start_time) {
                              setError("Closing slot time must be greater than Opening slot time.");
                              return;
                        } 
                        // else if (data[0].minimum_guests === "") {
                        //       setError("Minimum guests does not allowed empty.");
                        //       return;
                        // }
                        // else if (data[0].maximum_guests === "") {
                        //       setError("Maximum guests does not allowed empty.");
                        //       return;
                        // } 
                        // else if (Number(data[0].minimum_guests) > Number(data[0].maximum_guests)) {
                        //       setError("Minimum guests cannot be greater than maximum guests.");
                        //       return;
                        // } 
                        else if (data[0].booking_interval === "") {
                              setError("Please select booking interval.");
                              return;
                        } else if (pickupList?.length > 0) {
                              if ([...pickupList[0]?.manual_dates].some(entry => {
                                    if (entry.date === manual_dates) {
                                          //     const newStart = moment(data[0].start_time, "HH:mm");
                                          //     const newEnd = moment(data[0].end_time, "HH:mm");
                                          //     const existingStart = moment(entry.start_time, "HH:mm");
                                          //     const existingEnd = moment(entry.end_time, "HH:mm");

                                          //     // Check if the new entry's time period overlaps with the existing one
                                          //     const isTimeOverlapping = (
                                          //         newStart.isBetween(existingStart, existingEnd, null, '[)') ||
                                          //         newEnd.isBetween(existingStart, existingEnd, null, '(]') ||
                                          //         existingStart.isBetween(newStart, newEnd, null, '[)') ||
                                          //         existingEnd.isBetween(newStart, newEnd, null, '(]')
                                          //     );

                                          // Check if the booking type is the same
                                          const isBookingTypeDuplicate = entry.booking_type === data[0].booking_type;

                                          //     return isTimeOverlapping || isBookingTypeDuplicate; // Validate both conditions
                                          return isBookingTypeDuplicate; // Validate both conditions

                                    }
                                    return false;
                              })) {
                                    setError(`Date of ${moment(manual_dates).format("DD-MM-YYYY")} already added for the given booking type.`);
                                    return;
                              }

                        }



                        // console.log('data' , data)


                        const result = [...pickupList];
                        const defaultManualDate = {
                              date: manual_dates, // Assuming `manual_dates` is an array and you want the first date
                              start_time: data[0].start_time,
                              end_time: data[0].end_time,
                              // prep_time: data[0].prep_time,
                              booking_interval: data[0].booking_interval,
                              min_guests: data[0].minimum_guests,
                              max_guests: data[0].maximum_guests,
                              booking_type: data[0].booking_type,


                        };

                        // Use `concat` directly on the existing `manual_dates` if pickupList has entries
                        const FinalResul = pickupList?.length > 0
                              ? result[0]?.manual_dates?.concat([defaultManualDate])
                              : result.concat([{ manual_dates: [defaultManualDate] }]);

                        const item = {
                              branch_id: branchID,
                              // type: "pickup",
                              slot_management_type: slot_management_type,
                              manual_dates: FinalResul,
                        };
                        // 
                        console.log("FinalResul", FinalResul);
                        console.log("item", item);
                        // return

                        api
                              .post(`/groupadmin/settings/table_reservation_time`, item, { headers })
                              .then((res) => {
                                    $(".loader").hide();
                                    setmanual_dates('');
                                    setForm([
                                          {
                                                Week: "",
                                                booking_type: "",
                                                start_time: "",
                                                end_time: "",
                                                minimum_guests: "",
                                                maximum_guests: "",
                                                booking_interval: "",
                                          },
                                    ])
                                    showSuccessNotification("Your work has been saved successfully.")
                                    groupAdminPickupList();
                                    // console.log(res.data.message);
                                    // window.location.reload(true);
                              })
                              .catch((err) => {
                                    $(".loader").hide();
                                    if (err.response) {
                                          setError(err.response.data?.error?.message);
                                    } else if (err.request) {
                                          setError(err.request);
                                    } else {
                                          setError(err.message);
                                    }
                              });
                  }
            }

      };

      //////////////////////////////  Order //////////////////////////////////

      //////////////////////////////  Holiday //////////////////////////////////
      const [error1, setError1] = useState(null);
      const errorDiv1 = error1 && (
            <div className="text-center alert alert-danger">{error1}</div>
      )

      const [DataHoliday, setDataHoliday] = useState([
            {
                  start_date: "",
                  end_date: "",
            },
      ]);


      const handleremoveHoliday = (index) => {
            // console.log(index)
            let list = [...DataHoliday];
            list.splice(index, 1);
            setDataHoliday(list);
      };

      const handleSubmitHoliday = (event) => {
            event.preventDefault();
            setError1(null);

            if (branchID === null) {
                  setError1("Please Select Branch");
                  $(".loader1").hide();
                  return;
            } else if (value === "") {
                  setError1("Please select start date.");
                  $(".loader1").hide();
                  return;
            } else if (value1 === "") {
                  setError1("Please select end date.");
                  $(".loader1").hide();
                  return;
            } else if (value > value1) {
                  setError1(`Starting date is either equal to or lower than ending date.`);
                  $(".loader1").hide();
                  return;
            } else if (value1 < value) {
                  setError1("End date is either equal to or greater then Start date");
                  $(".loader1").hide();
                  return;
            } else {
                  $(".loader1").show();
                  var item = {};

                  item["branch_id"] = branchID;
                  item["type"] = "table_reservation";
                  item["start_date"] = moment(new Date(value)).format("YYYY-MM-DD");
                  item["end_date"] = moment(new Date(value1)).format("YYYY-MM-DD");

                  api
                        .post(`/groupadmin/settings/order_time/holiday`, item, { headers })
                        .then((res) => {
                              $(".loader1").hide();
                              showSuccessNotification("Holiday has been added successfully")
                              setValue("");
                              setValue1("");
                              groupAdminPickupHolidayList();
                              // window.location.reload(true);
                              // history.push('/group_admin/branch_management')
                              // history.goBack();
                        })
                        .catch((err) => {
                              $(".loader1").hide();
                              if (err.response) {
                                    setError1(err.response.data?.error?.message);
                              } else if (err.request) {
                                    setError1(err.request);
                              } else {
                                    setError1(err.message);
                              }
                        });
            }
      };

      //////////////////////////////  Holiday //////////////////////////////////


      function deleteOrder(branch_id, day_id, days) {

            if (slot_management_type === "auto") {
                  let items = {
                        branch_id: branch_id,
                        // type: "pickup",
                        day_id: day_id,
                        day: days,
                  };
                  deleteCommonFunction(`/groupadmin/settings/table_reservation_time/${branch_id}`,
                        "Time has been deleted successfully.", () => {
                              groupAdminPickupList();
                              setValue("");
                              setValue1("");
                        }, items)
            }
            else {

                  // Create a copy of the manual_dates array
                  let result = [...pickupList[0]?.manual_dates];

                  // Check if the day_id is within bounds
                  if (day_id >= 0 && day_id < result.length) {
                        // Remove the item at the specified index
                        result.splice(day_id, 1);
                  }

                  let item = {
                        branch_id: branchID,
                        slot_management_type: slot_management_type,
                        manual_dates: result
                  };

                  Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                        if (result.isConfirmed) {
                              api
                                    .post(`/groupadmin/settings/table_reservation_time`, item, { headers })
                                    .then((res) => {
                                          setmanual_dates('');
                                          setForm([
                                                {
                                                      Week: "",
                                                      booking_type: "",
                                                      start_time: "",
                                                      end_time: "",
                                                      minimum_guests: "",
                                                      maximum_guests: "",
                                                      booking_interval: "",
                                                }
                                          ])
                                          showSuccessNotification("Time has been deleted successfully.")
                                          groupAdminPickupList();
                                          // console.log(res.data.message);
                                          // window.location.reload(true);
                                    })
                                    .catch((err) => {
                                          if (err.response) {
                                                setError(err.response.data?.error?.message);
                                          } else if (err.request) {
                                                setError(err.request);
                                          } else {
                                                setError(err.message);
                                          }
                                    });
                        } else {
                              groupAdminPickupList();
                        }
                  });


            }
      }

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


      const DeleteButton = (userPermissionType === "groupadmin" ||
            UserPermissionAllData?.role_details?.settings?.find(permission =>
                  permission?.key === "table_reservation" &&
                  (permission?.access?.includes("delete"))
            )) && (
                  <i className="fa fa-trash fs-2" />)



      const daysArray = [
            { key: "monday", label: "Monday", start: "mon_start_time", end: "mon_end_time" },
            { key: "tuesday", label: "Tuesday", start: "tue_start_time", end: "tue_end_time" },
            { key: "wednesday", label: "Wednesday", start: "wed_start_time", end: "wed_end_time" },
            { key: "thursday", label: "Thursday", start: "thu_start_time", end: "thu_end_time" },
            { key: "friday", label: "Friday", start: "fri_start_time", end: "fri_end_time" },
            { key: "saturday", label: "Saturday", start: "sat_start_time", end: "sat_end_time" },
            { key: "sunday", label: "Sunday", start: "sun_start_time", end: "sun_end_time" },
      ];



      const ScheduleDetail = ({
            day,
            dayLabel,
            startTime,
            endTime,
            prepTime,
            branchId,
            orderId,
            deleteOrder,
            min_guests,
            max_guests,
            booking_type,
            booking_interval,
      }) => (
            <div className="row col-md-12">
                  {[
                        { label: "Day", value: dayLabel },
                        { label: "Booking Type", value: booking_type },
                        { label: "Opening Slot Time", value: startTime },
                        { label: "Closing Slot Time", value: endTime },
                        { label: "Booking Interval", value: `${booking_interval} mins` },
                        // { label: "Total Guests", value: ${min_guests} - ${max_guests} }
                  ].map(({ label, value }, idx) => (
                        <div className="col-md-2 my-2" key={idx}>
                              <label className="form-label">
                                    <strong style={{ color: "black" }}>{label}</strong>
                              </label>
                              <input
                                    readOnly
                                    type="text"
                                    className="form-control form-control-solid"
                                    value={value}
                              />
                        </div>
                  ))}

                  {/* Add a separate column for the Delete button */}
                  <div className="col-md-2 my-2">
                        <label className="form-label">
                              {/* <strong style={{ color: "black" }}>Action</strong> */}
                        </label>
                        <a
                              onClick={() => deleteOrder(branchId, orderId, day)}
                              id="deletebtn"
                              className="btn btn-danger1 remCF"
                        >
                              <strong style={{ color: "black" }} />
                              <br />
                              {DeleteButton}
                        </a>
                  </div>
            </div>

      );



      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div

                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ marginTop: "-30px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-2"
                                                            >
                                                                  Settings
                                                            </h1>
                                                            <hr />

                                                            <div className="row">
                                                                  <div
                                                                        className="col-md-9 "
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5 d-inline"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Table Reservation Setup
                                                                        </h3>
                                                                  </div>


                                                                  <div
                                                                        className="col-md-3 d-inline justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >


                                                                        <NoteState />

                                                                  </div>
                                                            </div>

                                                            {/* <div className="rounded border p-5 mt-3"> */}
                                                            {/* <br/> */}
                                                            <TableResLinkCommon />
                                                            <div className="mt-5">

                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div
                                                                              className="tab-pane fade show active"
                                                                              id="kt_tab_pane_1"
                                                                              role="tabpanel"
                                                                        >
                                                                              <div className="py-5">
                                                                                    <div className="card card-docs mb-2">
                                                                                          <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                                                <form onSubmit={handleSubmit}>
                                                                                                      <div className="rounded border p-10">
                                                                                                            <div className="d-inline flex-stack mb-5">
                                                                                                                  <div
                                                                                                                        className="d-inline justify-content-end"
                                                                                                                        data-kt-docs-table-toolbar="base"
                                                                                                                  ></div>

                                                                                                                  <div className="mb-10">
                                                                                                                        <h6>Enable/Disable Reservations</h6>

                                                                                                                        <div className="switch-container mt-4">
                                                                                                                              <label className="switchSmall m5">
                                                                                                                                    <input checked={isReservationsEnabled}
                                                                                                                                          onChange={(e) => {
                                                                                                                                                groupAdminSlotChange1(slot_management_type, !isReservationsEnabled, list_branch)
                                                                                                                                                setError("")

                                                                                                                                                setIsReservationsEnabled(!isReservationsEnabled)
                                                                                                                                          }}
                                                                                                                                          type="checkbox"
                                                                                                                                    />
                                                                                                                                    <small></small>
                                                                                                                              </label>
                                                                                                                              <span className="switch-label me-4">{isReservationsEnabled ? "Enabled" : "Disabled"}</span>
                                                                                                                              {!isReservationsEnabled && <span className="switch-label">
                                                                                                                                    <input
                                                                                                                                          className="form-check-input"
                                                                                                                                          type="checkbox"
                                                                                                                                          checked={list_branch}

                                                                                                                                          onChange={(e) => {
                                                                                                                                                groupAdminSlotChange1(slot_management_type, isReservationsEnabled, !list_branch)
                                                                                                                                                setError("")

                                                                                                                                                setlist_branch(!list_branch)
                                                                                                                                          }}
                                                                                                                                    />
                                                                                                                                    <label className="form-check-label switch-label mx-3">Show Branch Name (Non-Selectable) in selection</label>

                                                                                                                              </span>}

                                                                                                                        </div>
                                                                                                                  </div>

                                                                                                                  <div className="mb-5">
                                                                                                                        <h6>Table Reservation Timing Type</h6>

                                                                                                                        <div className="mt-4" style={{ display: 'flex' }}>
                                                                                                                              <input
                                                                                                                                    className="form-check-input me-5"
                                                                                                                                    type="radio"
                                                                                                                                    name="Auto"
                                                                                                                                    value="auto"
                                                                                                                                    style={{ height: "16px", width: "16px", marginTop: "0px" }}
                                                                                                                                    checked={slot_management_type === "auto"}
                                                                                                                                    onChange={(e) => {
                                                                                                                                          groupAdminSlotChange("auto")
                                                                                                                                          setError("")
                                                                                                                                          setslot_management_type(e.target.value)
                                                                                                                                    }
                                                                                                                                    }
                                                                                                                              />
                                                                                                                              <label style={{ color: "#344054", fontWeight: 400, fontSize: "14px" }} className="form-check-label">Auto</label>
                                                                                                                              <input
                                                                                                                                    className="form-check-input mx-10 me-5"
                                                                                                                                    type="radio"
                                                                                                                                    name="Manual"
                                                                                                                                    value="manual"
                                                                                                                                    style={{ height: "16px", width: "16px", marginTop: "0px" }}
                                                                                                                                    checked={slot_management_type === "manual"}
                                                                                                                                    onChange={(e) => {
                                                                                                                                          groupAdminSlotChange("manual")
                                                                                                                                          setError("")
                                                                                                                                          setslot_management_type(e.target.value)
                                                                                                                                    }
                                                                                                                                    } />
                                                                                                                              <label style={{ color: "#344054", fontWeight: 400, fontSize: "14px" }} className="form-check-label">Manual</label>



                                                                                                                        </div>
                                                                                                                  </div>

                                                                                                                  {slot_management_type === "direct" ? "" :
                                                                                                                        <React.Fragment>
                                                                                                                              <div className="mt-10">
                                                                                                                                    <h6>
                                                                                                                                          Select the times during which guest can order.
                                                                                                                                    </h6>
                                                                                                                              </div>

                                                                                                                              {data.map((details, index) => (
                                                                                                                                    <>
                                                                                                                                          <div
                                                                                                                                                className="row"
                                                                                                                                                key={index}
                                                                                                                                          >
                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      <label className="required form-label">
                                                                                                                                                            <strong
                                                                                                                                                                  style={{ color: "black" }}
                                                                                                                                                            >
                                                                                                                                                                  {slot_management_type === "auto" ? "Day" : "Date"}
                                                                                                                                                            </strong>
                                                                                                                                                      </label>
                                                                                                                                                      {slot_management_type === "auto" ?
                                                                                                                                                            <select
                                                                                                                                                                  className="form-select"
                                                                                                                                                                  data-placeholder="Select an option"
                                                                                                                                                                  value={details.Week}
                                                                                                                                                                  onChange={(e) =>
                                                                                                                                                                        handleCheck(index, e, "Week")
                                                                                                                                                                  }
                                                                                                                                                            >
                                                                                                                                                                  <option disabled selected value=''>
                                                                                                                                                                        Select
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="sun_Sunday">
                                                                                                                                                                        Sunday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="mon_Monday">
                                                                                                                                                                        Monday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="tue_Tuesday">
                                                                                                                                                                        Tuesday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="wed_Wednesday">
                                                                                                                                                                        Wednesday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="thu_Thursday">
                                                                                                                                                                        Thursday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="fri_Friday">
                                                                                                                                                                        Friday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="sat_Saturday">
                                                                                                                                                                        Saturday
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="weekdays">
                                                                                                                                                                        Week Days
                                                                                                                                                                  </option>
                                                                                                                                                                  <option value="weekend">
                                                                                                                                                                        Week End
                                                                                                                                                                  </option>
                                                                                                                                                            </select> :
                                                                                                                                                            <input id='txtDate' type="date"
                                                                                                                                                                  className="form-control"
                                                                                                                                                                  value={manual_dates}
                                                                                                                                                                  min={today}
                                                                                                                                                                  onChange={(e) => setmanual_dates(e.target.value)}
                                                                                                                                                            />
                                                                                                                                                      }
                                                                                                                                                </div>


                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      <label className="required form-label">
                                                                                                                                                            <strong
                                                                                                                                                                  style={{ color: "black" }}
                                                                                                                                                            >
                                                                                                                                                                  Booking Type
                                                                                                                                                            </strong>
                                                                                                                                                      </label>
                                                                                                                                                      <select
                                                                                                                                                            className="form-select"
                                                                                                                                                            data-placeholder="Select an option"
                                                                                                                                                            value={details.booking_type}
                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                  const selectedType = e.target.value;
                                                                                                                                                                  // let startTime = "";
                                                                                                                                                                  // let endTime = "";

                                                                                                                                                                  // Set default times based on the selected booking type
                                                                                                                                                                  // if (selectedType === "Lunch") {
                                                                                                                                                                  //       startTime = "12:00"; // Lunch start time
                                                                                                                                                                  //       endTime = "15:00";  // Lunch end time
                                                                                                                                                                  // } else if (selectedType === "Dinner") {
                                                                                                                                                                  //       startTime = "18:00"; // Dinner start time
                                                                                                                                                                  //       endTime = "22:00";  // Dinner end time
                                                                                                                                                                  // }

                                                                                                                                                                  // Update the booking type and times in the state
                                                                                                                                                                  handleCheck(index, e, "booking_type");
                                                                                                                                                                  // handleCheck(index, { target: { value: startTime } }, "start_time");
                                                                                                                                                                  // handleCheck(index, { target: { value: endTime } }, "end_time");
                                                                                                                                                            }}
                                                                                                                                                      >
                                                                                                                                                            <option disabled selected value=''>
                                                                                                                                                                  Select
                                                                                                                                                            </option>
                                                                                                                                                            <option value="Lunch">
                                                                                                                                                                  Lunch
                                                                                                                                                            </option>
                                                                                                                                                            <option value="Dinner">
                                                                                                                                                                  Dinner
                                                                                                                                                            </option>

                                                                                                                                                      </select>
                                                                                                                                                </div>

                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      <label className="required form-label">
                                                                                                                                                            <strong style={{ color: "black" }}>Opening Slot Time</strong>
                                                                                                                                                      </label>
                                                                                                                                                      <input
                                                                                                                                                            type="time"
                                                                                                                                                            className="form-control"
                                                                                                                                                            value={details?.start_time}
                                                                                                                                                            // min={details.booking_type === "Lunch" ? "12:00" : "18:00"} // Min time based on booking type
                                                                                                                                                            // max={details.booking_type === "Lunch" ? "15:00" : "22:00"} // Max time based on booking type
                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                  // const minTime = details.booking_type === "Lunch" ? "12:00" : "18:00";
                                                                                                                                                                  // const maxTime = details.booking_type === "Lunch" ? "15:00" : "22:00";
                                                                                                                                                                  // const selectedTime = e.target.value;

                                                                                                                                                                  // // Validate and reset time if out of bounds
                                                                                                                                                                  // if (selectedTime < minTime || selectedTime > maxTime) {
                                                                                                                                                                  //       showToastCommon(`Please select a time between ${minTime} and ${maxTime}`);
                                                                                                                                                                  //       e.target.value = ""; // Clear the invalid time
                                                                                                                                                                  //       return;
                                                                                                                                                                  // }

                                                                                                                                                                  handleCheck(index, e, "start_time");
                                                                                                                                                            }}
                                                                                                                                                            disabled={!details.booking_type} // Disable until booking type is selected
                                                                                                                                                      />
                                                                                                                                                </div>
                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      <label className="required form-label">
                                                                                                                                                            <strong style={{ color: "black" }}>Closing Slot Time</strong>
                                                                                                                                                      </label>
                                                                                                                                                      <input
                                                                                                                                                            type="time"
                                                                                                                                                            className="form-control"
                                                                                                                                                            value={details?.end_time || ""} // Ensure default value exists
                                                                                                                                                            // min={details.booking_type === "Lunch" ? "12:00" : "18:00"} // Min time based on booking type
                                                                                                                                                            // max={details.booking_type === "Lunch" ? "15:00" : "22:00"} // Max time based on booking type
                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                  // Restrict invalid times
                                                                                                                                                                  // const minTime = details.booking_type === "Lunch" ? "12:00" : "18:00";
                                                                                                                                                                  // const maxTime = details.booking_type === "Lunch" ? "15:00" : "22:00";
                                                                                                                                                                  // const selectedTime = e.target.value;

                                                                                                                                                                  // if (selectedTime < minTime || selectedTime > maxTime) {
                                                                                                                                                                  //       showToastCommon(`Please select a time between ${minTime} and ${maxTime}`);
                                                                                                                                                                  //       e.target.value = ""; // Clear the invalid time
                                                                                                                                                                  //       return;
                                                                                                                                                                  // }
                                                                                                                                                                  handleCheck(index, e, "end_time");

                                                                                                                                                            }}
                                                                                                                                                            disabled={!details.booking_type} // Disable until booking type is selected
                                                                                                                                                      />
                                                                                                                                                </div>
                                                                                                                                                {/* <div className="col-md-3 my-2">
                                                                                                                                                      <label
                                                                                                                                                            for="exampleFormControlInput1"
                                                                                                                                                            className="required form-label"
                                                                                                                                                      >
                                                                                                                                                            {" "}
                                                                                                                                                            <strong
                                                                                                                                                                  style={{ color: "black" }}
                                                                                                                                                            >
                                                                                                                                                                  Minimum Guests
                                                                                                                                                            </strong>
                                                                                                                                                      </label>
                                                                                                                                                      <input
                                                                                                                                                            type="number"
                                                                                                                                                            className="form-control"
                                                                                                                                                            placeholder="Enter Minimum Guests"
                                                                                                                                                            value={details?.minimum_guests}
                                                                                                                                                            onWheel={(e) => e.target.blur()}
                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                  let value = e.target.value;
                                                                                                                                                                  // Disallow the value 0
                                                                                                                                                                  if (value === "0") {
                                                                                                                                                                        value = ""; // Clear the input if 0 is entered
                                                                                                                                                                  }
                                                                                                                                                                  // Update the state only if the value is valid (not 0)
                                                                                                                                                                  handleCheck(index, { ...e, target: { ...e.target, value } }, "minimum_guests");
                                                                                                                                                            }}
                                                                                                                                                      />




                                                                                                                                                </div>
                                                                                                                                                <div className="col-md-3 my-2">
                                                                                                                                                      <label
                                                                                                                                                            for="exampleFormControlInput1"
                                                                                                                                                            className="required form-label"
                                                                                                                                                      >
                                                                                                                                                            {" "}
                                                                                                                                                            <strong
                                                                                                                                                                  style={{ color: "black" }}
                                                                                                                                                            >
                                                                                                                                                                  Maximum Guests
                                                                                                                                                            </strong>
                                                                                                                                                      </label>
                                                                                                                                                      <input
                                                                                                                                                            type="number"
                                                                                                                                                            className="form-control"
                                                                                                                                                            placeholder="Enter Maximum Guests"
                                                                                                                                                            value={details?.maximum_guests}
                                                                                                                                                            // onChange={(e) => {
                                                                                                                                                            //       const value = Math.max(0, Number(e.target.value)); // Prevent negative values
                                                                                                                                                            //       handleCheck(index, { ...e, target: { ...e.target, value } }, "maximum_guests");
                                                                                                                                                            // }}


                                                                                                                                                            onWheel={(e) => e.target.blur()}
                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                  let value = e.target.value;
                                                                                                                                                                  // Disallow the value 0
                                                                                                                                                                  if (value === "0") {
                                                                                                                                                                        value = ""; // Clear the input if 0 is entered
                                                                                                                                                                  }

                                                                                                                                                                  handleCheck(index, { ...e, target: { ...e.target, value } }, "maximum_guests");
                                                                                                                                                            }}
                                                                                                                                                      />
                                                                                                                                                </div> */}

                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      <label className="required form-label">
                                                                                                                                                            <strong
                                                                                                                                                                  style={{ color: "black" }}
                                                                                                                                                            >
                                                                                                                                                                  Booking Interval
                                                                                                                                                            </strong>
                                                                                                                                                      </label>
                                                                                                                                                      <select
                                                                                                                                                            className="form-select"
                                                                                                                                                            data-placeholder="Select an option"
                                                                                                                                                            value={details?.booking_interval}
                                                                                                                                                            onChange={(e) => handleCheck(index, e, "booking_interval")}
                                                                                                                                                      >
                                                                                                                                                            <option disabled selected value=''>
                                                                                                                                                                  Select
                                                                                                                                                            </option>
                                                                                                                                                            <option value="15">
                                                                                                                                                                  15 Min
                                                                                                                                                            </option>
                                                                                                                                                            <option value="30">
                                                                                                                                                                  30 Min
                                                                                                                                                            </option>
                                                                                                                                                            <option value="45">
                                                                                                                                                                  45 Min
                                                                                                                                                            </option>

                                                                                                                                                      </select>
                                                                                                                                                </div>

                                                                                                                                                <div className="col-md-2 my-2">
                                                                                                                                                      {data?.length !== 1 && (
                                                                                                                                                            <a
                                                                                                                                                                  id="deletebtn"
                                                                                                                                                                  className="btn btn-danger1 remCF"
                                                                                                                                                                  onClick={() =>
                                                                                                                                                                        handleremove(index)
                                                                                                                                                                  }
                                                                                                                                                            >
                                                                                                                                                                  <br />
                                                                                                                                                                  {DeleteButton}
                                                                                                                                                            </a>
                                                                                                                                                      )}
                                                                                                                                                </div>
                                                                                                                                          </div>
                                                                                                                                    </>
                                                                                                                              ))}
                                                                                                                        </React.Fragment>}
                                                                                                            </div>

                                                                                                            <div className="col-md-12 mt-2">
                                                                                                                  {errorDiv}
                                                                                                            </div>
                                                                                                            <div className="my-5">
                                                                                                                  {slot_management_type === "auto" ?
                                                                                                                        <>
                                                                                                                              {pickupList?.length > 0 ?
                                                                                                                                    Object.values(pickupList).map(
                                                                                                                                          (details) => {
                                                                                                                                                return (
                                                                                                                                                      <>
                                                                                                                                                            {daysArray.map(({ key, label, start, end }) =>
                                                                                                                                                                  details[key]?.map((dayDetail, index) => (
                                                                                                                                                                        <ScheduleDetail
                                                                                                                                                                              key={index}
                                                                                                                                                                              day={key}
                                                                                                                                                                              dayLabel={label}
                                                                                                                                                                              startTime={dayDetail[start]}
                                                                                                                                                                              endTime={dayDetail[end]}
                                                                                                                                                                              prepTime={dayDetail.prep_time}
                                                                                                                                                                              branchId={details.branch_id}
                                                                                                                                                                              orderId={dayDetail._id}
                                                                                                                                                                              min_guests={dayDetail.min_guests}
                                                                                                                                                                              max_guests={dayDetail.max_guests}
                                                                                                                                                                              booking_type={dayDetail.booking_type}
                                                                                                                                                                              booking_interval={dayDetail.booking_interval}
                                                                                                                                                                              deleteOrder={deleteOrder}
                                                                                                                                                                        />
                                                                                                                                                                  ))
                                                                                                                                                            )}
                                                                                                                                                      </>
                                                                                                                                                );
                                                                                                                                          }
                                                                                                                                    ) :
                                                                                                                                    loader === false && ErrorDisplay
                                                                                                                              }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                              {pickupList[0]?.manual_dates?.length > 0
                                                                                                                                    ?
                                                                                                                                    pickupList[0]?.manual_dates?.map((main, index) => (
                                                                                                                                          <>
                                                                                                                                                <div className="row">
                                                                                                                                                      <div className="row col-md-11">
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Day</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control form-control-solid" value={main?.date} />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Booking Type</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control form-control-solid" value={main?.booking_type} />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Opening Slot Time</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control form-control-solid" value={main?.start_time} />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Closing Slot Time</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control form-control-solid" value={main?.end_time} />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Booking Interval</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control filterme form-control-solid" placeholder="Booking Interval" value={`${main?.booking_interval} mins`} />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="col-md-2 my-2">
                                                                                                                                                                  <label className="form-label">
                                                                                                                                                                        <strong style={{ color: "black" }}>Total Guests</strong>
                                                                                                                                                                  </label>
                                                                                                                                                                  <input readOnly type="text" className="form-control filterme form-control-solid" placeholder="Total Guests" value={`${main?.min_guests} - ${main?.max_guests}`} />
                                                                                                                                                            </div>
                                                                                                                                                      </div>
                                                                                                                                                      <div className="col-md-1">
                                                                                                                                                            <div className="col-md-12 my-2">
                                                                                                                                                                  <a
                                                                                                                                                                        onClick={() => deleteOrder(branchID,
                                                                                                                                                                              index,)}
                                                                                                                                                                        id="deletebtn" className="btn btn-danger1 remCF">
                                                                                                                                                                        <label className="form-label">
                                                                                                                                                                              <strong style={{ color: "black" }}><br /></strong>
                                                                                                                                                                        </label>
                                                                                                                                                                        <br />
                                                                                                                                                                        {DeleteButton}
                                                                                                                                                                  </a>
                                                                                                                                                            </div>
                                                                                                                                                      </div>
                                                                                                                                                </div>


                                                                                                                                          </>




                                                                                                                                    )) : loader === false && ErrorDisplay
                                                                                                                              }
                                                                                                                        </>
                                                                                                                  }
                                                                                                            </div>
                                                                                                      </div>


                                                                                                      {slot_management_type === "direct" ? "" :
                                                                                                            <React.Fragment>
                                                                                                                  <div className="col-md-12 text-center">
                                                                                                                        <br />
                                                                                                                        <div
                                                                                                                              className="loader"
                                                                                                                              style={{ display: "none" }}
                                                                                                                        >
                                                                                                                              <img src="/assets/loader.gif" />
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                                  <div className="modal-footer flex-center">
                                                                                                                        {(userPermissionType === "groupadmin" ||
                                                                                                                              UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                                                                    permission?.key === "table_reservation" &&
                                                                                                                                    (permission?.access?.includes("create"))
                                                                                                                              )) && (
                                                                                                                                    <button type="submit" className="btn btn-primary">
                                                                                                                                          <span className="indicator-label">Save</span>
                                                                                                                                    </button>)}
                                                                                                                        <button
                                                                                                                              type="button"
                                                                                                                              className="btn btn-danger m-2"
                                                                                                                              onClick={() => history.push("/group_admin/settings")}
                                                                                                                        >
                                                                                                                              {" "}
                                                                                                                              Cancel{" "}
                                                                                                                        </button>
                                                                                                                  </div></React.Fragment>}
                                                                                                </form>
                                                                                                <br />

                                                                                                <form onSubmit={handleSubmitHoliday}>
                                                                                                      <div className="rounded border p-10">
                                                                                                            <div className="d-inline flex-stack mb-5">
                                                                                                                  <div
                                                                                                                        className="d-inline justify-content-end"
                                                                                                                        data-kt-docs-table-toolbar="base"
                                                                                                                  >
                                                                                                                  </div>
                                                                                                                  <div className="mb-10 d-inline">
                                                                                                                        <h6>Closed Days: Ex. Holidays</h6>
                                                                                                                  </div>

                                                                                                                  <div className="row col-md-12">
                                                                                                                        <div className="col-md-4 my-2">
                                                                                                                              <label className="required form-label">
                                                                                                                                    <strong
                                                                                                                                          style={{ color: "black" }}
                                                                                                                                    >
                                                                                                                                          Start Date
                                                                                                                                    </strong>
                                                                                                                              </label>

                                                                                                                              <input id='txtDate' type="date"
                                                                                                                                    className="form-control"
                                                                                                                                    value={value}
                                                                                                                                    min={today}
                                                                                                                                    onChange={(e) => setValue(e.target.value)}
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-4 my-2">
                                                                                                                              <label className="required form-label">
                                                                                                                                    <strong
                                                                                                                                          style={{ color: "black" }}
                                                                                                                                    >
                                                                                                                                          End Date
                                                                                                                                    </strong>
                                                                                                                              </label>

                                                                                                                              <input id='txtDate1'
                                                                                                                                    type="date"
                                                                                                                                    min={today}
                                                                                                                                    className="form-control"
                                                                                                                                    value={value1}
                                                                                                                                    onChange={(e) => setValue1(e.target.value)}
                                                                                                                              />
                                                                                                                        </div>

                                                                                                                  </div>

                                                                                                            </div>
                                                                                                            <div className="col-md-12 text-center">
                                                                                                                  <br />
                                                                                                                  <div
                                                                                                                        className="loader1"
                                                                                                                        style={{ display: "none" }}
                                                                                                                  >
                                                                                                                        <img src="/assets/loader.gif" />

                                                                                                                  </div>
                                                                                                            </div>

                                                                                                            <div className="col-md-12">
                                                                                                                  {" "}
                                                                                                                  {errorDiv1}{" "}
                                                                                                            </div>

                                                                                                            <div className="col-md-12">
                                                                                                                  {" "}
                                                                                                                  {errorListHo && ErrorDisplay}{" "}
                                                                                                            </div>

                                                                                                            {PickupHolidayList?.length > 0
                                                                                                                  && PickupHolidayList.map(
                                                                                                                        (details1, index) => {
                                                                                                                              return (
                                                                                                                                    <>
                                                                                                                                          <div
                                                                                                                                                className="row col-md-12"
                                                                                                                                                key={index}
                                                                                                                                          >
                                                                                                                                                <div className="col-md-4 my-2">
                                                                                                                                                      <input
                                                                                                                                                            type="text"
                                                                                                                                                            className="form-control form-control-solid"
                                                                                                                                                            value={moment(
                                                                                                                                                                  details1.start_date
                                                                                                                                                            ).format("DD/MM/YYYY")}
                                                                                                                                                      />
                                                                                                                                                </div>
                                                                                                                                                <div className="col-md-4 my-2">
                                                                                                                                                      <input
                                                                                                                                                            type="text"
                                                                                                                                                            className="form-control form-control-solid"
                                                                                                                                                            value={moment(
                                                                                                                                                                  details1.end_date
                                                                                                                                                            ).format("DD/MM/YYYY")}
                                                                                                                                                      />
                                                                                                                                                </div>
                                                                                                                                                {/* <div className='col-md-4 my-2'><input type="date" className="form-control form-control-solid" value={moment(details1.end_date).format('YYYY/MM/DD')} /></div> */}
                                                                                                                                                <div className="col-md-4 my-2">
                                                                                                                                                      <button
                                                                                                                                                            type="button"
                                                                                                                                                            onClick={(e) =>
                                                                                                                                                                  deleteHoliday(
                                                                                                                                                                        details1._id
                                                                                                                                                                  )
                                                                                                                                                            }
                                                                                                                                                            id="deletebtn"
                                                                                                                                                            className="btn btn-danger1 remCF"
                                                                                                                                                      >
                                                                                                                                                            {DeleteButton}
                                                                                                                                                      </button>
                                                                                                                                                </div>
                                                                                                                                          </div>
                                                                                                                                    </>
                                                                                                                              );
                                                                                                                        }
                                                                                                                  )}
                                                                                                      </div>
                                                                                                      <div className="modal-footer flex-center">
                                                                                                            {(userPermissionType === "groupadmin" ||
                                                                                                                  UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                                                                                        permission?.key === "table_reservation" &&
                                                                                                                        (permission?.access?.includes("create"))
                                                                                                                  )) && (
                                                                                                                        <button type="submit" className="btn btn-primary">
                                                                                                                              <span className="indicator-label">Save</span>
                                                                                                                        </button>)}
                                                                                                            <button
                                                                                                                  type="button"
                                                                                                                  className="btn btn-danger m-2"
                                                                                                                  onClick={() => history.push("/group_admin/settings")}
                                                                                                            >
                                                                                                                  {" "}
                                                                                                                  Cancel{" "}
                                                                                                            </button>
                                                                                                      </div>
                                                                                                </form>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* </div> */}
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>


            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
      return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReservationManagment);

