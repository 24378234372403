import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { useHistory, Link } from "react-router-dom";
import { getBranchList, getCouponList } from "../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../context/DrawerContext";
import Addnewdetails from "../../Comman/AddNewDetails/Addnewdetails";
import { CouponsPermissions, userPermissionType } from "../../Comman/UserPermission";
import { selectStyles } from "../../Comman/CommonFunction/CommonFunction";
import Select from "react-select";
import { HeaderStyle } from "./OrderManagement/TableCommonModule";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../Comman/BranchList";
import useWindowHeight from "../../NewTableCommon/useWindowsPosition";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOptionCouponAndVoucher } from "../../NewTableCommon/tableOption";
import TableFilter from "../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../NewTableCommon/ColumPopoverCommon";


function GroupAdminCouponManagement({ getCouponList, couponList, getBranchList, BranchList_ }) {
  let history = useHistory();

  useEffect(() => {
    getCouponList();
    getBranchList();
  }, []);

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  const filteredOptions = branchfilterAndMapData(BranchList_);
  const windowHeight = useWindowHeight();
  const userPermissionType_ = userPermissionType === "groupadmin"
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];


  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };


  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value; // Extract the status value

    if (BranchId && BranchId.value !== "All" && status && status !== "all") {
      // Filter by branch and specific order type
      return couponList.filter(
        menu => menu.branch_id === BranchId.value && menu.order_type === status
      );
    } else if (BranchId && BranchId.value !== "All") {
      // Filter by branch only
      return couponList.filter(menu => menu.branch_id === BranchId.value);
    } else if (status && status !== "all") {
      // Filter by specific order type only
      return couponList.filter(menu => menu.order_type === status);
    }
    // If status is "all" or undefined, return the full list
    return couponList;
  }, [couponList, BranchId, FilterData?.Status?.value]);

  // console.log("couponList", couponList)

  const Columns = useMemo(() => {
    const columns = [
      // {
      //   accessorKey: '_id',
      //   header: 'Sr.No',
      //   size: 100,
      //   Cell: ({ row }) => Number(row.index + 1),
      // },
      {
        accessorKey: 'code',
        header: 'Coupon Code',
        // size: 300,
        Cell: ({ row }) => {
          const hasViewPermission = userPermissionType_ || CouponsPermissions?.access?.includes("view");
          return hasViewPermission ? (
            <Link
              to={{
                pathname: `/group_admin/coupon_voucher_management/coupon_management/view_coupon/${row?.original?._id}`,
              }}
            >
              <span style={HeaderStyle?.uniqueCodeHeader}>
                {row?.original?.code}
              </span>
            </Link>
          ) : (
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.printer_name}
            </span>
          );
        },
      },
      {
        accessorKey: 'branch?.branch_name_EN',
        header: 'Branch Name',
        // size: 270,
        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN || "---"),
      },
      {
        accessorKey: 'discount_type',
        header: 'Discount Type',
        size: 270,
        Cell: ({ row }) => (<div style={{ textTransform: "capitalize" }}>{row?.original?.discount_type}</div>),
      },
      {
        accessorKey: 'discount_value',
        header: 'Discount Value',
        // size: 270,
        Cell: ({ row }) => Number(row?.original?.discount_value)
      },
      {
        accessorKey: 'apply_coupon_type',
        header: 'Apply Coupon Type',
        size: 150,
        Cell: ({ row }) => <div style={{ textTransform: "capitalize" }}>{row?.original?.apply_coupon_type}</div>,
      },
      {
        accessorKey: 'order_type',
        header: 'Order Type',
        size: 150,
        Cell: ({ row }) => <div style={{ textTransform: "capitalize" }}>{row?.original?.order_type}</div>,
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const EDIT = CouponsPermissions?.access?.includes("edit");
  const DELETE = CouponsPermissions?.access?.includes("delete")
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionCouponAndVoucher({
      getCouponList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history
    }),
  });


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Coupon Management
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                            className="search-line"
                            value={BranchId}
                            placeholder={getPlaceholder(filteredOptions)}
                            options={getSelectOptions(filteredOptions)}
                            onChange={(opt) => {
                              setBranchID(opt);
                              sessionStorage.setItem(`${url}_branchName`, JSON.stringify(opt));
                            }}
                            styles={selectStyles}
                          />



                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType_ || CouponsPermissions?.access?.includes("create")) && (
                            <Addnewdetails
                              url={"/group_admin/coupon_voucher_management/coupon_management/add_coupon"}
                              Name="Add Coupon" />)}
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="mt-5">
                      <MaterialReactTable
                        table={table}
                      />

                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>


        <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        />

        <TableFilter
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          url23={url}
        />
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  couponList: state.couponList,
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCouponList: () => dispatch(getCouponList()),
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminCouponManagement);


