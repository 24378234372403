import React, { useEffect, useMemo, useRef, useState } from 'react'
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory, useParams } from "react-router-dom";
import MaterialTable from "material-table";
import { useStyles } from '../../../Comman/TableuseStyles';
import { Tableoptions } from '../../../Comman/TableOption';
import { useMediaQuery } from "react-responsive";
import { getBranchDetaisById } from '../../../../Store';
import { connect } from "react-redux";
import BranchCommon from '../../Page/BranchCommon';
import useDrawer from '../../../../context/DrawerContext';
import { deleteCommonFunction } from '../../../Comman/DeleteCommonFun/DeleteCommonFunction';
import Addnewdetails from '../../../Comman/AddNewDetails/Addnewdetails';
import { generateTableActions } from '../../../Comman/TableActions';
import { BranchesPermissions, userPermissionType } from '../../../Comman/UserPermission';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import useWindowHeight from '../../../NewTableCommon/useWindowsPosition';
import { tableOptionBranchKitchenStaffList, tableOptionBranchList, tableOptionBranchManagerList, tableOptionBranchServiceStaffStaffList } from '../../../NewTableCommon/tableOption';
import ColumPopoverCommon from '../../../NewTableCommon/ColumPopoverCommon';


function GroupAdminUserList({ getBranchDetaisById, branchDetaislByID }) {

  const userPermissionType_ = userPermissionType === "groupadmin"


  const scrollRef = useRef();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ bihavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [user, setuser] = useState([]);
  const [comman, setcomman] = useState("");
  const [Staff, setStaff] = useState([])
  const [Waiter, setWaiter] = useState([]);

  console.log("user", user)

  useEffect(() => {
    if (id) {
      getBranchDetaisById(id);
    }
  }, [id]);

  useEffect(() => {
    if (branchDetaislByID) {
      setcomman(branchDetaislByID);
      setuser(branchDetaislByID?.managerData);
      setStaff(branchDetaislByID?.staffData);
      setWaiter(branchDetaislByID?.waiterData)
    }
  }, [branchDetaislByID])

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Manager Name",
      field: "name",
    },
    {
      title: "Phone Number",
      field: "phone_number",
    },
    {
      title: "Email",
      field: "email",
    },
  ];

  function deleteManager(_id) {
    deleteCommonFunction(`/groupadmin/manager/${_id}`, "Manager has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateManager(id) {
    history.push(`/group_admin/branch_management/edit_manager_details/${id}`);
  }

  const columns_staff = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Staff Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
  ];

  function deletStaff(_id) {
    deleteCommonFunction(`/groupadmin/kitchenuser/${_id}`, "Staff has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateStaff(id) {
    history.push(`/group_admin/branch_management/edit_staff_details/${id}`);
  }

  const columns_Waiter = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Service Staff Name",
      field: "name",
    },
    {
      title: "Phone Number",
      // field: "phone_number",
      render: (rowData) => {
        return <>{rowData.phone_number ? rowData?.phone_number : "---"}</>;
      },
    },
    // {
    //   title: "Email",
    //   field: "email",
    // },
    {
      title: "Staff Id",
      field: "staff_id",
    },
    {
      title: "Pin Code",
      field: "pin",
    },
  ];

  function deletWaiter(_id) {
    deleteCommonFunction(`/groupadmin/waiter/${_id}`, "Service staff has been successfully deleted.", () => getBranchDetaisById(id));
  }

  function updateWaiter(id) {
    history.push(`/group_admin/branch_management/edit_servicestaff_details/${id}`);
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  // const userPermissionType_ = userPermissionType === "groupadmin"

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];
  // console.log("url", url)

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  // const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));
  // const [BranchId, setBranchID] = useState(branchName || "");

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;


  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const EDIT = BranchesPermissions?.access?.includes("edit");
  const DELETE = BranchesPermissions?.access?.includes("delete");
  const VIEW = BranchesPermissions?.access?.includes("view");

  const ColumnsManager = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'name',
        header: 'Manager Name',
        size: 270,
        Cell: ({ row }) => (row?.original?.name),
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        size: 270,
        Cell: ({ row }) => (row?.original?.phone_number),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 270,
        Cell: ({ row }) => (row?.original?.email),
      },


    ];
    return columns;
  }, [user?.length > 0]);

  const ColumnsKitchenStaff = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'name',
        header: 'Staff Name',
        size: 270,
        Cell: ({ row }) => (row?.original?.name),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 270,
        Cell: ({ row }) => (row?.original?.email),
      },
    ];
    return columns;
  }, [Staff?.length > 0]);

  const ColumnServiceStaff = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'name',
        header: 'Service Staff Name',
        size: 270,
        Cell: ({ row }) => (row?.original?.name),
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        size: 270,
        Cell: ({ row }) => (row?.original?.phone_number),
      },
      {
        accessorKey: 'staff_id',
        header: 'Service Staff Id',
        size: 270,
        Cell: ({ row }) => (row?.original?.staff_id),
      },
      {
        accessorKey: 'pin',
        header: 'Pin Code',
        size: 270,
        Cell: ({ row }) => (row?.original?.pin),
      },
    ];
    return columns;
  }, [Waiter?.length > 0]);
  // console.log("url" ,url)

  const ManagerUrl = "BranchManager"
  const KitcheStaffUrl = "BranchKitcheStaff"
  const ServiceStaffUrl = "BranchServiceStaff"


  const tableManager = useMaterialReactTable({
    columns: ColumnsManager,
    data: user?.length > 0 ? user : [],
    ...tableOptionBranchManagerList({
      getBranchDetaisById,
      ManagerUrl,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history,id
    }),
  });

  const tableKitchenStaff = useMaterialReactTable({
    columns: ColumnsKitchenStaff,
    data: Staff?.length > 0 ? Staff : [],
    ...tableOptionBranchKitchenStaffList({
      getBranchDetaisById,
      KitcheStaffUrl,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history ,id
    }),
  });

  const tableServiceStaff = useMaterialReactTable({
    columns: ColumnServiceStaff,
    data: Waiter?.length > 0 ? Waiter : [],
    ...tableOptionBranchServiceStaffStaffList({
      getBranchDetaisById,
      ServiceStaffUrl,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,id,
      // VIEW,
      EDIT,
      history
    }),
  });

  return (

    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div ref={scrollRef} className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={maingetViewDetails.join(" ")}>
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Branches
                        </h1>
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        {comman?.resData?.branch_name_EN ?
                          <h3
                            className="anchor fw-bolder mb-3"
                            id="custom-form-control"
                          >
                            Branch - {comman?.resData?.branch_name_EN}
                          </h3> : ""}
                      </div>
                      <div className="d-flex flex-stack mb-3">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >

                        </div>
                      </div>
                    </div>
                    <div className={"rounded border p-5"}>
                      <BranchCommon />

                      {/* <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className={isMobile ? "" : "card card-docs mb-2"}>
                            <div className={isMobile ? "" : "card-body"}> */}

                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1
                            className="anchor fw-bolder"
                            id="custom-form-control"
                            style={{ fontSize: 22 }}
                          >
                            Manager List
                          </h1>
                        </div>
                        <div className="d-flex flex-stack">
                          <div
                            className="d-flex justify-content-end mx-2"
                            data-kt-docs-table-toolbar="base"
                          >

                            {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                              <Addnewdetails
                                url={`/group_admin/branch_management/add_manager/${id}`}
                                Name="Add Manager" />
                            )}


                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <MaterialReactTable
                          table={tableManager}
                        />
                      </div>



                      <div className="pt-20">
                        <div className="d-flex flex-stack mb-5">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h1
                              className="anchor fw-bolder"
                              id="custom-form-control"
                              style={{ fontSize: 22 }}
                            >
                              Kitchen Staff List
                            </h1>
                          </div>
                          <div className="d-flex flex-stack">
                            <div
                              className="d-flex justify-content-end mx-2"
                              data-kt-docs-table-toolbar="base"
                            >

                              {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                                <Addnewdetails
                                  url={`/group_admin/branch_management/add_staff/${id}`}
                                  Name="Add Staff" />
                              )}


                            </div>
                          </div>
                        </div>

                        <div className="mt-5">
                          <MaterialReactTable
                            table={tableKitchenStaff}
                          />
                        </div>

                      </div>


                      <div className="pt-20">
                        <div className="d-flex flex-stack mb-5">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h1
                              className="anchor fw-bolder"
                              id="custom-form-control"
                              style={{ fontSize: 22 }}
                            >
                              Service Staff List
                            </h1>
                          </div>
                          <div className="d-flex flex-stack">
                            <div
                              className="d-flex justify-content-end mx-2"
                              data-kt-docs-table-toolbar="base"
                            >
                              {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) && (
                                <Addnewdetails
                                  url={`/group_admin/branch_management/add_servicestaff/${id}`}
                                  Name="Add Service Staff"
                                />
                              )}


                            </div>
                          </div>
                        </div>

                        <MaterialReactTable
                          table={tableServiceStaff}
                        />

                      </div>
                      {/* </div>
                          </div>
                        </div>
                      </div> */}




                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        /> */}
        <GroupAdminUperSide />



      </div>

    </React.Fragment>
  )
}


const mapStateToProps = (state) => ({
  branchDetaislByID: state?.branch?.branchDetaislByID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetaisById: (id) => dispatch(getBranchDetaisById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminUserList);
