import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import { getBranchList, getPrinterList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import Select from "react-select";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Addnewdetails from "../../../../Comman/AddNewDetails/Addnewdetails";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../../Comman/BranchList";
import { HeaderStyle } from "../../OrderManagement/TableCommonModule";
import { tableOptionPrinterList } from "../../../../NewTableCommon/tableOption";
import useWindowHeight from "../../../../NewTableCommon/useWindowsPosition";
import { renderStatusChipPrinteList } from "../../../../NewTableCommon/rendderStatucChipDaynamic";
import TableFilter from "../../../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../../../NewTableCommon/ColumPopoverCommon";
import { selectStyles } from "../../../../Comman/CommonFunction/CommonFunction";


function GroupAdminPrinterList({ getPrinterList, printerList, getBranchList, BranchList_ }) {

  useEffect(() => {
    getPrinterList();
    getBranchList();
  }, []);

  const userPermissionType_ = userPermissionType === "groupadmin"
  const filteredOptions = branchfilterAndMapData(BranchList_);
  const windowHeight = useWindowHeight();

  let history = useHistory();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const VIEW = UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("view")

  const DELETE = UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("delete")

  const EDIT = UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("edit")

  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value;
    if (BranchId && BranchId.value !== "All" && status) {
      return printerList.filter(menu => menu.branch_id === BranchId.value &&
        menu.is_active === (status === "Active"));
    } else if (BranchId && BranchId.value !== "All") {
      return printerList.filter(menu => menu.branch_id === BranchId.value);
    } else if (status) {
      return printerList.filter(menu => menu.is_active === (status === "Active"));
    }
    return printerList;
  }, [printerList, BranchId, FilterData?.Status?.value]);



  const Columns = useMemo(() => {
    const columns = [
      // {
      //   accessorKey: '_id',
      //   header: 'Sr.No',
      //   size: 100,
      //   Cell: ({ row }) => Number(row.index + 1),
      // },
      {
        accessorKey: 'printer_name',
        header: 'Printer Name',
        // size: 300,
        Cell: ({ row }) => {
          const hasViewPermission = userPermissionType_ || VIEW;
          return hasViewPermission ? (
            <Link
              to={{
                pathname: `/group_admin/settings/printer/printerDetails/${row?.original?._id}`,
              }}
            >
              <span style={HeaderStyle?.uniqueCodeHeader}>
                {row?.original?.printer_name}
              </span>
            </Link>
          ) : (
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.printer_name}
            </span>
          );
        },
      },
      {
        accessorKey: 'branch_name_EN',
        header: 'Branch Name',
        // size: 270,
        Cell: ({ row }) => (row?.original?.branch_name_EN || "---"),
      },
      {
        accessorKey: 'printer_category_name',
        header: 'Printer Category',
        size: 270,
        Cell: ({ row }) => (row?.original?.printer_category_name || "---"),
      },
      {
        accessorKey: 'ip_address',
        header: 'Ip Address',
        // size: 270,
        Cell: ({ row }) => row?.original?.ip_address,
      },
      {
        accessorKey: 'default_status',
        header: 'Default Printer',
        size: 150,
        Cell: ({ row }) => renderStatusChipPrinteList(row?.original?.default_status, 'default_status'),
      },
      {
        accessorKey: 'enable_invoice',
        header: 'Print Receipt',
        size: 150,
        Cell: ({ row }) => renderStatusChipPrinteList(row?.original?.enable_invoice, 'enable_invoice'),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 150,
        Cell: ({ row }) => renderStatusChipPrinteList(row?.original?.is_active, 'is_active'),
      }
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionPrinterList({
      getPrinterList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE, VIEW, EDIT, history
    }),
  });

  // console.log("filteredMenuList" ,filteredMenuList)

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Settings 
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                            className="search-line"
                            value={BranchId}
                            placeholder={getPlaceholder(filteredOptions)}
                            options={getSelectOptions(filteredOptions)}
                            onChange={(opt) => {
                              setBranchID(opt);
                              sessionStorage.setItem(`${url}_branchName`, JSON.stringify(opt));
                            }}
                            styles={selectStyles}
                          />



                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                            permission?.key === "printer" &&
                            permission?.access?.includes("create")
                          )) && (
                              <Addnewdetails url="/group_admin/settings/printer/printerSettings" Name="Add Printer" />
                            )}
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="row col-md-12 mb-4">
                      <div
                        className="col-md-9"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Printer - Printer List
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                      </div>
                    </div>

                    <div className="rounded border p-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerList",
                            }}
                            className={
                              url === "printerList"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer List
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerSettings",
                              
                            }}
                            className={
                              url === "printerSettings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                          Printer Settings
                          </Link>
                        </li> */}

                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerCategory",
                            }}
                            className={
                              url === "printerCategory"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer Category
                          </Link>
                        </li>
                      </ul>
                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        /></div>
                    </div>
                  </main>
                </div>
              </div>
            </div>

            <ColumPopoverCommon
              anchorEl_={anchorEl_}
              handleClickColum_={handleClickColum_}
              onColumnDataChange={onColumnDataChange}
              handleCloseColum_={handleCloseColum_}
              url23={url}
            />

            <TableFilter
              open={isFilterPopoverOpen}
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilter={handleApplyFilters}
              url23={url}
            />
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  printerList: state.printerList,
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPrinterList: () => dispatch(getPrinterList()),
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPrinterList);


