import React, { useEffect, useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { getBranchList } from "../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../context/DrawerContext";
import Addnewdetails from "../../Comman/AddNewDetails/Addnewdetails";
import { BranchesPermissions, userPermissionType } from "../../Comman/UserPermission";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import useWindowHeight from "../../NewTableCommon/useWindowsPosition";
import { tableOptionBranchList } from "../../NewTableCommon/tableOption";
import { HeaderStyle } from "./OrderManagement/TableCommonModule";
import TableFilter from "../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../NewTableCommon/ColumPopoverCommon";
import renderStatusChip from "../../NewTableCommon/renderStatusChip";
import { Chip } from "@mui/material";

function GroupAdminBranchManagement({ getBranchList, BranchList_ }) {
  let history = useHistory();

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();
  useEffect(() => {
    getBranchList();
  }, []);
  const userPermissionType_ = userPermissionType === "groupadmin"
  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  const shouldShowCouponVoucher = DataSub && (DataSub.status === 'active' || DataSub.status === 'trial') && DataSub

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[2];
  // console.log("url", url)

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  // const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));
  // const [BranchId, setBranchID] = useState(branchName || "");

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;


  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value;
    if (status) {
      return BranchList_.filter(menu => menu.is_active === (status === "Active"));
    }
    return BranchList_;
  }, [BranchList_, FilterData?.Status?.value]);

  // console.log("BranchList_", BranchList_)

  const EDIT = BranchesPermissions?.access?.includes("edit");
  const DELETE = BranchesPermissions?.access?.includes("delete");
  const VIEW = BranchesPermissions?.access?.includes("view");

  const Columns = useMemo(() => {
    const columns = [
      // {
      //   accessorKey: '_id',
      //   header: 'Sr.No',
      //   size: 100,
      //   Cell: ({ row }) => Number(row.index + 1),
      // },
      {
        accessorKey: 'branch_name_EN',
        header: 'Branch Name',
        // size: 270,
        // Cell: ({ row }) => (row?.original?.branch?.branch_name_EN || "---"),
        Cell: ({ row }) => {
          const hasViewPermission = userPermissionType_ || VIEW;
          return hasViewPermission ? (
            <Link
              to={{
                pathname: `/group_admin/branch_management/branch_detail_view/${row?.original?._id}`,
                // state: { menuId: row?.original?._id, branchID: row?.original?.branch?._id },
              }}
            >
              <span style={HeaderStyle?.uniqueCodeHeader}>
                {row?.original?.branch_name_EN}
              </span>
            </Link>
          ) : (
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.branch_name_EN}
            </span>
          );
        },
      },
      {
        accessorKey: 'address',
        header: 'Branch Location',
        size: 270,
        Cell: ({ row }) => (row?.original?.address),
      },
      {
        accessorKey: 'phone_number',
        header: 'Phone Number',
        // size: 270,
        Cell: ({ row }) => (row?.original?.phone_number)
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
        Cell: ({ row }) => (row?.original?.email),
      },
      {
        accessorKey: 'branchTimeZone',
        header: 'Time Zone',
        size: 150,
        Cell: ({ row }) => (row?.original?.branchTimeZone),
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
        size: 150,
        Cell: ({ row }) => (row?.original?.currency),
      },
      {
        accessorKey: 'seq_no',
        header: 'Sequence No',
        size: 150,
        Cell: ({ row }) => (row?.original?.seq_no),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 150,
        Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionBranchList({
      getBranchList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history
    }),
  });

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <ToastContainer />
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container"
                  // className="container-xxl"
                  className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                    <div className="row g-5 g-xl-8">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                        <div className="d-flex flex-stack mb-3">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h2
                              className="anchor fw-bolder mb-5"
                              id="custom-form-control"
                            >
                              Branches
                              
                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                               <Chip 
                              style={{ color: "#DF3223", background: "#FEF3F2", fontSize: 12, 
                                border : "1px solid #DF3223",
                              fontWeight: 500, letterSpacing: "0.3px" }} 
                              label={`${BranchList_.length} ${BranchList_.length === (1 || 0) ? "Branch" : "Branches"}`} />
                            </h2>
                          </div>
                          <div className="d-flex flex-stack mb-5">
                            <div
                              className="d-flex justify-content-end mx-2"
                              data-kt-docs-table-toolbar="base"
                            >

                              {/* {console.log("aaa" ,shouldShowCouponVoucher?.branches , shouldShowCouponVoucher?.number_of_branches)} */}

                              {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) &&
                                (shouldShowCouponVoucher?.branches < shouldShowCouponVoucher?.number_of_branches
                                  ?
                                  (
                                    <Addnewdetails url="/group_admin/branch_management/add_branch" Name="Add Branch" />
                                  ) : "")}

                            </div>
                          </div>
                        </div>
                        <hr style={{ opacity: 0.07, marginTop:"-10px" }} />
                    
                        <div className="mt-5">
                          <MaterialReactTable
                            table={table}
                          />
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        />

        <TableFilter
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          url23={url}
        />
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminBranchManagement);