import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory,  } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPrinterCategoryList } from "../../../../../Store";
import useDrawer from "../../../../../context/DrawerContext";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ColumPopoverCommon from "../../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../../NewTableCommon/tableFilter";
import useWindowHeight from "../../../../NewTableCommon/useWindowsPosition";
import { tableOptionPrinterList } from "../../../../NewTableCommon/tableOption";
import AddEditCategoryModal from "./AddEditCategoryModal";


function GroupAdminPrinterCategory({ getPrinterCategoryList, printerCatagoryList }) {

  const [categoryId, setcategoryId] = useState("");
  const [existingCategoryName, setExistingCategoryName] = useState("");

  useEffect(() => {
    getPrinterCategoryList();
  }, []);

  const userPermissionType_ = userPermissionType === "groupadmin"
  const windowHeight = useWindowHeight();

  let history = useHistory();
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[4];
  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  const DELETE = userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("delete")

  const EDIT = userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
    permission?.key === "printer")?.access?.includes("edit")

  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 50,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'name',
        header: 'Category Name',
        size: 1100,
        Cell: ({ row }) => (row?.original?.name || "---"),
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openAddModal = () => {
    setIsEdit(false);
    setcategoryId(null);
    setShowModal(true);
    setExistingCategoryName("");
  };

  const openEditModal = (id ,name) => {
    setIsEdit(true);
    setcategoryId(id);
    setShowModal(true);
    setExistingCategoryName(name);
  };

  const table = useMaterialReactTable({
    columns: Columns,
    data: printerCatagoryList,
    ...tableOptionPrinterList({
      getPrinterCategoryList,
      url,
      handleClickColum_,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE, EDIT, history ,openEditModal
    }),
  });

  // console.log("EDIT" ,EDIT)
  // console.log("DELETE" ,DELETE)


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Settings 
                          </h1>

                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType_ || UserPermissionAllData?.role_details?.settings?.find(permission =>
                            permission?.key === "printer" &&
                            permission?.access?.includes("create")
                          )) && (
                              <Link
                                // to="#kt_modal_1"
                                type="button"
                                className="btn btn-primary"
                                // data-bs-toggle="modal"
                                onClick={openAddModal}
                              >

                                Add Category
                              </Link>)}
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="row col-md-12 mb-4">
                      <div
                        className="col-md-9"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Printer - Printer Category
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerList",
                            }}
                            className={
                              url === "printerList"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer List
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerSettings",
                              
                            }}
                            className={
                              url === "printerSettings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                          Printer Settings
                          </Link>
                        </li> */}

                        <li className="nav-item">
                          <Link
                            to={{
                              pathname:
                                "/group_admin/settings/printer/printerCategory",
                            }}
                            className={
                              url === "printerCategory"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Printer Category
                          </Link>
                        </li>

                      </ul>
                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        /></div>
                    </div>

                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>

      <AddEditCategoryModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isEdit={isEdit}
        categoryId={categoryId}
        existingCategoryName={existingCategoryName}
        getPrinterCategoryList={getPrinterCategoryList}
      />

      <ColumPopoverCommon
        anchorEl_={anchorEl_}
        handleClickColum_={handleClickColum_}
        onColumnDataChange={onColumnDataChange}
        handleCloseColum_={handleCloseColum_}
        url23={url}
      />

      {/* <TableFilter
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleFilter={handleApplyFilters}
        url23={url}
      /> */}
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  printerCatagoryList: state.printerCatagoryList
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPrinterCategoryList: () => dispatch(getPrinterCategoryList()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPrinterCategory);

