import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "../../Component/Setting/UserManagment/UserManagmentCommon";
import useDrawer from "../../../../context/DrawerContext";
import { SubmitformButton1 } from "../../../Comman/Error/SubmitformButton";
import { submitForm } from "../../../Comman/AddCommonFun/AddCommonFunction";
import useError from "../../../Comman/Error/useError";
import { formatResponse, formatResponseSetting, formatResponseReport, reports, settings, style1, style2, style3, stylelabel } from './styles';
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";



export default function AddRoles() {



  const { id } = useParams();
  let history = useHistory();
  const [user, setuser] = useState("")
  const [roleName, setRoleName] = useState("");  // State for role name
  const [permissions, setPermissions] = useState([
    {
      key: "",
      access: [
        { label: "View All", isCheck: false, disabled: false },
        { label: "Create All", isCheck: false, disabled: false },
        { label: "Edit All", isCheck: false, disabled: false },
        { label: "Delete All", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Dashboard",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: true }
      ],
    },
    {
      key: "Branch",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Tables",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: true }
      ],
    },
    {
      key: "Orders",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Menus",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Table Reservations",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Stock",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Coupons",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Vouchers",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Tour Groups",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: false },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Email Marketing",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: false },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Subscriptions",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: false }
      ],
    },
    {
      key: "Enquiries",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: true }
      ],
    },
    {
      key: "KPGPT",
      access: [
        { label: "View", isCheck: false, disabled: false },
        { label: "Create", isCheck: false, disabled: true },
        { label: "Edit", isCheck: false, disabled: true },
        { label: "Delete", isCheck: false, disabled: true }
      ],
    },
  ]); // State for selected permissions
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const { error, showError, clearError } = useError();


  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  // let shouldShowCouponVoucher;
  const shouldShowCouponVoucher =  DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features
  
  const MenuItemsSub = shouldShowCouponVoucher ? permissions?.length > 0 && permissions?.filter(item => {
    if (item.key === "Coupons") {
      return shouldShowCouponVoucher?.includes("coupons");
    }

    if (item.key === "Vouchers") {
      return shouldShowCouponVoucher?.includes("vouchers");
    }

    if (item.key === "Tour Groups") {
      return shouldShowCouponVoucher?.includes("tour_group_setup");
    }

    if (item.key === "Enquiries") {
      return shouldShowCouponVoucher?.includes("enquiry_management");
    }
    return permissions;
  }) : permissions.filter(item =>
    !["Enquiries", "Tour Groups", "Vouchers" ,"Coupons"].includes(item.key)
  );

  useEffect(() => {
    if (MenuItemsSub?.length > 0) {
      setPermissions(MenuItemsSub)
    }
  }, [MenuItemsSub?.length > 0])


// Mapping filteredSettings keys to settings titles
const settingsMap = {
  "pickup": "Pickup",
  "delivery": "Delivery",
  "dinein": "Dine In",
  "tour_group_setup": "Tour Setup",
};

// Filter settings based on the mapped titles
const filteredSettingsArray = shouldShowCouponVoucher ? settings.filter(setting => {
  // Check if the setting is in the settingsMap and the corresponding key exists in filteredSettings
  const correspondingKey = Object.keys(settingsMap).find(key => settingsMap[key] === setting);
  // const shouldShowCouponVoucher = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features

  // If the correspondingKey is found, check if it exists in filteredSettings
  return correspondingKey ? shouldShowCouponVoucher.includes(correspondingKey) : true ;
}) : settings.filter(item =>
  !["Delivery", "Pickup", "Dine In" ,"Tour Setup"].includes(item)
);

// console.log("filteredSettingsArray" ,filteredSettingsArray);


  const [Settings, setSettings] = useState([
    {
      title: "Settings",
      subcategories: filteredSettingsArray.map(setting => ({
        setting,
        access: [
          { label: "View", disabled: setting === "Email Template", isCheck: false },
          { label: "Create", disabled: (setting === "Language Settings" || setting === "Email Template"), isCheck: false },
          { label: "Edit", disabled: false, isCheck: false },
          { label: "Delete", disabled: (setting === "Language Settings" || setting === "Email Template"), isCheck: false }
        ]
      }))
    }
  ]);




  // console.log("Settings", Settings)


  useEffect(() => {
    UserDetailsGet();
  }, [id]);

  function UserDetailsGet() {
    api.get(`/groupadmin/user/${id?.id}`, { headers }).then((res) => {
      const tableData = res.data.data;
      setuser(tableData);
      // console.log("sss", tableData)
    });
  }

  const [Reports, setReports] = useState([
    {
      title: "Reports",
      subcategories: reports.map(report => ({
        report,
        access: [
          { label: "View", disabled: false },
          { label: "Create", disabled: true },
          { label: "Edit", disabled: true },
          { label: "Delete", disabled: true }
        ]
      }))
    }
  ]);


  const handleRoleNameChange = (e) => {
    const value = e.target.value;
    // Prevent only spaces from being entered
    if (value.trim() === '' && value.length > 0) {
      return;
    }
    setRoleName(value);
  };


  const handleCheckboxChange = (sectionIndex, checkboxIndex) => {
    setPermissions(prevPermissions => {
      return prevPermissions.map((section, sIndex) => {

        // Handle "Select All" toggling for the "All_Value" section
        if (sectionIndex === 0 && sIndex !== 0) {
          const toggledLabel = prevPermissions[0].access[checkboxIndex].label;
          const isSelectAllToggled = ["View All", "Create All", "Edit All", "Delete All"].includes(toggledLabel);
          const isSelectAllChecked = !prevPermissions[0].access[checkboxIndex].isCheck;

          if (isSelectAllToggled) {
            return {
              ...section,
              access: section.access.map(checkbox =>
                checkbox.label.includes(toggledLabel.split(" ")[0]) && !checkbox.disabled
                  ? { ...checkbox, isCheck: isSelectAllChecked }
                  : checkbox
              )
            };
          }
        }

        // Handle normal checkbox toggling for any section
        if (sIndex === sectionIndex) {
          const updatedAccess = section.access.map((checkbox, cIndex) => {
            if (cIndex === checkboxIndex && !checkbox.disabled) {
              return { ...checkbox, isCheck: !checkbox.isCheck };
            }
            return checkbox;
          });

          // Ensure "Edit", "Create", or "Delete" toggles "View" as well, only if not disabled
          const isEditCreateDeleteChanged = ["Edit", "Create", "Delete"].includes(updatedAccess[checkboxIndex].label);
          if (isEditCreateDeleteChanged) {
            const isChecked = updatedAccess[checkboxIndex].isCheck;
            const anyEditCreateDeleteChecked = updatedAccess.some(checkbox =>
              ["Edit", "Create", "Delete"].includes(checkbox.label) && checkbox.isCheck
            );

            return {
              ...section,
              access: updatedAccess.map(checkbox =>
                checkbox.label === "View" && !checkbox.disabled
                  ? { ...checkbox, isCheck: isChecked || anyEditCreateDeleteChecked }
                  : checkbox
              )
            };
          }

          return {
            ...section,
            access: updatedAccess
          };
        }

        return section;
      });
    });
  };

  const handleCheckboxChangeReports = (sectionIndex, subIndex, label) => {
    setReports(prevReports => {
      return prevReports.map((section, secIndex) => {
        if (secIndex !== sectionIndex) return section;

        return {
          ...section,
          subcategories: section.subcategories.map((sub, subIdx) => {
            if (subIdx !== subIndex) return sub;

            // Toggle the checkbox's isCheck state
            const updatedAccess = sub.access.map(accessOption => {
              if (accessOption.label === label) {
                return { ...accessOption, isCheck: !accessOption.isCheck };
              }
              return accessOption;
            });

            return {
              ...sub,
              access: updatedAccess
            };
          })
        };
      });
    });
  };


  const handleCheckboxChangeSettings = (sectionIndex, subIndex, label) => {
    setSettings(prevSettings => {
      const updatedSettings = [...prevSettings];
      const subCategory = updatedSettings[sectionIndex].subcategories[subIndex];

      // Determine the current state of the checkbox being toggled
      const isChecked = !subCategory.access.find(item => item.label === label).isCheck;

      // Update the specific checkbox
      const access = subCategory.access.map(item => {
        if (item.label === label) {
          // Toggle the checked state of the current checkbox
          return { ...item, isCheck: isChecked };
        }

        // If "Create", "Edit", or "Delete" is checked, ensure "View" is also checked
        if (isChecked && ["Create", "Edit", "Delete"].includes(label) && item.label === "View") {
          return { ...item, isCheck: true };
        }

        // If "Create", "Edit", or "Delete" is unchecked, and all are unchecked, uncheck "View"
        if (!isChecked && ["Create", "Edit", "Delete"].includes(label)) {
          const otherCheckedPermissions = subCategory.access.some(
            i => i.isCheck && ["Create", "Edit", "Delete"].includes(i.label) && i.label !== label
          );
          if (!otherCheckedPermissions && item.label === "View") {
            return { ...item, isCheck: false };
          }
        }

        return item;
      });

      // Update the subcategory's access array
      updatedSettings[sectionIndex].subcategories[subIndex].access = access;

      return updatedSettings;
    });
  };





  useEffect(() => {
    // Log the current permissions and checkbox values for debugging
    const checkAllStates = ['View All', 'Create All', 'Edit All', 'Delete All']?.reduce((acc, label) => {
      acc[label] = permissions[0]?.access.find(a => a.label === label)?.isCheck || false;
      return acc;
    }, {});

    // console.log('Check All States:', checkAllStates);
    setSettings(prevSettings => {
      const updatedSubcategories = prevSettings[0]?.subcategories?.map(subcategory => ({
        ...subcategory,
        access: subcategory?.access?.map(accessItem => {
          // Determine the corresponding 'All' checkbox
          const allLabel = `${accessItem.label} All`;

          // Set isCheck based on the corresponding 'All' checkbox state
          if (checkAllStates[allLabel] !== undefined) {
            return { ...accessItem, isCheck: checkAllStates[allLabel] };
          }
          return accessItem;
        })
      }));
      return [{ title: "Settings", subcategories: updatedSubcategories }];
    });

    setReports(prevSettings => {
      const updatedSubcategories = prevSettings[0]?.subcategories?.map(subcategory => ({
        ...subcategory,
        access: subcategory?.access?.map(accessItem => {
          // Determine the corresponding 'All' checkbox
          const allLabel = `${accessItem.label} All`;

          // Set isCheck based on the corresponding 'All' checkbox state
          if (checkAllStates[allLabel] !== undefined && !accessItem.disabled) {
            return { ...accessItem, isCheck: checkAllStates[allLabel] };
          }
          return accessItem;
        })
      }));
      // console.log("ssss", updatedSubcategories);
      return [{ title: "Reports", subcategories: updatedSubcategories }];
    });
  }, [permissions]);


  // console.log("permissions", permissions)
  // console.log("Reports", Reports);
  // console.log("Settings", Settings);


  const handleSubmit = (event) => {
    event.preventDefault();
    clearError(null);

    if (roleName === "") {
      showError("Please enter role name");
      return;
    }
    const hasPermissionsWithAccess = formatResponse(permissions)?.some(permission => permission.access.length > 0);
    const hasReportsWithAccess = formatResponseReport(Reports?.[0]?.subcategories || [])?.some(report => report.access.length > 0);
    const hasSettingsWithAccess = formatResponseSetting(Settings?.[0]?.subcategories || [])?.some(setting => setting.access.length > 0);

    if (!hasPermissionsWithAccess && !hasReportsWithAccess && !hasSettingsWithAccess) {
      showError("Please select at least one option to create a role.");
      return;
    }
    else {
      const item = {
        name: roleName,
        permissions: formatResponse(permissions),
        reports: formatResponseReport(Reports?.length > 0 && Reports?.[0]?.subcategories),
        settings: formatResponseSetting(Settings?.length > 0 && Settings?.[0]?.subcategories),
      };

      // console.log("item", item);
      // return

      submitForm("/groupadmin/role/add", item, history, showError, clearError, "Role has been added successfully.");
    }


  };


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="row col-md-12">
                      <div className="col-md-9" data-kt-docs-table-toolbar="base">
                        <h1 className="anchor fw-bolder mb-5 d-inline" id="custom-form-control">
                          User Management
                        </h1>
                      </div>

                      <div className="col-md-3 text-end" data-kt-docs-table-toolbar="base">
                        <button
                          className="btn btn-primary"
                          onClick={() => history.push("/group_admin/settings/roles")}
                        >
                          Go Back
                        </button>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="rounded border p-10">
                        <UserManagmentCommon />
                        <div className="row col-md-12 py-5 mx-0">
                          <div className="col-md-9">
                            <h3 className="anchor fw-bolder mb-5">Add New Role</h3>
                          </div>
                          <div className="col-md-3 text-end"></div>
                        </div>

                        <div className="card card-docs mb-2 mx-5">
                          <div className="card-body fs-6 pt-5 px-5 px-lg-15 text-gray-700">
                            <form onSubmit={handleSubmit}>
                              <div className="row col-md-12 mt-3">
                                <div className="col-md-3">
                                  <h6 style={style1}>Role Name</h6>
                                </div>
                                <div className={"col-md-9"}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Role Name"
                                    value={roleName}
                                    onChange={handleRoleNameChange}
                                    required
                                  />
                                </div>
                              </div>
                              <hr className="my-8" style={{ opacity: "0.1" }} />
                              {permissions.map((section, sectionIndex) => (
                                <div key={sectionIndex}>
                                  <div className="row col-md-12 mt-3">
                                    <div className="col-md-3">
                                      <h6 style={sectionIndex === 0 ? style1 : style2}>{section.key}</h6>
                                    </div>
                                    <div className={"col-md-9"}>
                                      {section.inputField ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Role Name"
                                          value={roleName}
                                          onChange={handleRoleNameChange}
                                          required
                                        />
                                      ) : (
                                        <div className="col-md-12">
                                          {section.access.map((checkbox, checkboxIndex) => (
                                            <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                              <input
                                                style={{
                                                  background: checkbox.disabled && "#F2F4F7",
                                                  border: checkbox.disabled && "#EAECF0"
                                                }}
                                                className="form-check-input me-4"
                                                type="checkbox"
                                                // id={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}`}
                                                disabled={checkbox.disabled}
                                                checked={checkbox?.isCheck}
                                                onChange={() => handleCheckboxChange(sectionIndex, checkboxIndex)}
                                              />
                                              <label
                                                style={{
                                                  fontSize: "16px",
                                                  color: checkbox.disabled ? "#c9cbcd" : "#344054",
                                                  fontWeight: "500",
                                                }}
                                                className="form-check-label"
                                              // htmlFor={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}`}
                                              >
                                                {checkbox.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <hr className="my-8" style={{ opacity: "0.1" }} />
                                </div>
                              ))}
                              {Reports.map((section, sectionIndex) => (
                                <div key={sectionIndex}>
                                  <div className="row col-md-12 mt-3">
                                    <div className="col-md-3">
                                      <h6 style={sectionIndex === 0 ? style1 : style2}>{section.title}</h6>
                                    </div>
                                    {section.subcategories && (
                                      section.subcategories.map((sub, subIndex) => (
                                        <div key={subIndex} className="row col-md-12 my-6">
                                          <div className="col-md-3">
                                            <h6 style={style3}>{sub.report}</h6>
                                          </div>
                                          <div className="col-md-9">
                                            {sub.access.map((checkbox, checkboxIndex) => (
                                              <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                                <input
                                                  style={{
                                                    background: checkbox.disabled && "#F2F4F7",
                                                    border: checkbox.disabled && "#EAECF0"
                                                  }}
                                                  className="form-check-input me-4"
                                                  type="checkbox"
                                                  disabled={checkbox.disabled}
                                                  onChange={() => handleCheckboxChangeReports(sectionIndex, subIndex, checkbox.label)}
                                                  checked={checkbox.isCheck}
                                                />
                                                <label
                                                  style={{
                                                    fontSize: "16px",
                                                    color: checkbox.disabled ? "#c9cbcd" : "#344054",
                                                    fontWeight: "500",
                                                  }}
                                                  className="form-check-label"
                                                >
                                                  {checkbox.label}
                                                </label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                  <hr className="my-8" style={{ opacity: "0.1" }} />
                                </div>
                              ))}
                              {Settings?.length > 0 &&
                                Settings.map((section, sectionIndex) => (
                                  <div key={sectionIndex}>
                                    <div className="row col-md-12 mt-3">
                                      <div className="col-md-3">
                                        <h6 style={sectionIndex === 0 ? style1 : style2}>{section.title}</h6>
                                      </div>
                                      {section.subcategories && (
                                        section.subcategories.map((sub, subIndex) => (
                                          <div key={subIndex} className="row col-md-12 my-6">
                                            <div className="col-md-3">
                                              <h6 style={style3}>{sub.setting}</h6>
                                            </div>
                                            <div className="col-md-9">
                                              {sub.access.map((checkbox, checkboxIndex) => (
                                                <div key={checkboxIndex} className="col-md-2 form-check form-check-inline">
                                                  <input
                                                    className="form-check-input me-4"
                                                    type="checkbox"
                                                    // id={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}_${subIndex}`}
                                                    disabled={checkbox.disabled}
                                                    checked={checkbox.isCheck}
                                                    onChange={() => handleCheckboxChangeSettings(sectionIndex, subIndex, checkbox.label)}
                                                  />
                                                  <label
                                                    style={stylelabel}
                                                    className="form-check-label"
                                                  // htmlFor={`${checkbox.label.replace(/\s+/g, '').toLowerCase()}_${sectionIndex}_${subIndex}`}
                                                  >
                                                    {checkbox.label}
                                                  </label>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                    <hr className="my-8" style={{ opacity: "0.1" }} />
                                  </div>
                                ))}
                              <ErrorMessage error={error} />
                              <SubmitformButton1 onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}





