import { EMAILTEMPLATE } from "../action-types";


const getEmailTemplateList = () => ({
  type: EMAILTEMPLATE.GET_EMAILTEMPLATE_LIST,
});


const setEmailTemplateList = (emailTemplatelist) => ({
  type: EMAILTEMPLATE.SET_EMAILTEMPLATE_LIST,
  emailTemplatelist,
});


export {
      getEmailTemplateList ,setEmailTemplateList
};
