import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import CommonVoucher from "./CommonVoucher";
import { getBranchList, getVoucherTemList } from "../../../../Store";
import { connect } from "react-redux"
import useDrawer from "../../../../context/DrawerContext";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import Addnewdetails from "../../../Comman/AddNewDetails/Addnewdetails";
import { userPermissionType, VouchersPermissions } from "../../../Comman/UserPermission";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../Comman/BranchList";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOptionCouponAndVoucher } from "../../../NewTableCommon/tableOption";
import TableFilter from "../../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import Select from "react-select";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";

window.jquery = window.$ = $;


function GroupAdminVoucherListManagement({ getVoucherTemList, voucherTemList, getBranchList, BranchList_ }) {

  let history = useHistory();
  const userPermissionType_ = userPermissionType === "groupadmin"

  useEffect(() => {
    getVoucherTemList();
    getBranchList();
  }, []);

  function groupAdminVoucherStatusUpdate(value, ID) {
    let item = {
      is_active: value
    };
    // console.log("item" ,item);
    api
      .patch(`/groupAdmin/available_voucher/${ID}`, item, { headers })
      .then((res) => {
        getVoucherTemList();
        showSuccessNotification(`Voucher has been ${value == true ? "Active" : "Inactive"} successfully`)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();
  const filteredOptions = branchfilterAndMapData(BranchList_);
  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[4];

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const filteredMenuList = useMemo(() => {
    const status = FilterData?.Status?.value;
    if (BranchId && BranchId.value !== "All" && status) {
      return voucherTemList.filter(menu => menu.branch_id === BranchId.value &&
        menu.is_active === (status === "Active"));
    } else if (BranchId && BranchId.value !== "All") {
      return voucherTemList.filter(menu => menu.branch_id === BranchId.value);
    } else if (status) {
      return voucherTemList.filter(menu => menu.is_active === (status === "Active"));
    }
    return voucherTemList;
  }, [voucherTemList, BranchId, FilterData?.Status?.value]);

  // console.log("voucherTemList", voucherTemList)

  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'branch?.branch_name_EN',
        header: 'Branch Name',
        // size: 270,
        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN || "---"),
      },
      {
        accessorKey: 'voucher_category',
        header: 'Voucher Category',
        size: 270,
        Cell: ({ row }) => (<div style={{ textTransform: "capitalize" }}>{row?.original?.voucher_category}</div>),
      },
      {
        accessorKey: 'salutation_title',
        header: 'Salutation Title',
        // size: 270,
        Cell: ({ row }) => (row?.original?.salutation_title)
      },
      {
        accessorKey: 'voucher_validity',
        header: 'Voucher Validity',
        size: 150,
        Cell: ({ row }) => <div style={{ textTransform: "capitalize" }}> {row?.original?.voucher_validity} {row?.original?.voucher_validity_type}</div>,
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 150,
        // Cell: ({ row }) => renderStatusChip(row?.original?.is_active),
        Cell: ({ row }) => {
          const { is_active, _id } = row?.original || {};
          const isActive = is_active === true;
          return (
            <div>
              <button
                onClick={() => groupAdminVoucherStatusUpdate(!isActive, _id)}
                type="button"
                className={`btn ${isActive ? 'btn-success' : 'btn-Inactive'}`}
              >
                {isActive ? 'Active' : 'Inactive'}
              </button>
            </div>
          );
        },
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const EDIT = VouchersPermissions?.access?.includes("edit");
  const DELETE = VouchersPermissions?.access?.includes("delete")
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: filteredMenuList,
    ...tableOptionCouponAndVoucher({
      getVoucherTemList,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history
    }),
  });


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid p-0"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>


                    <div className="row ">
                      <div className="col-md-6">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Voucher Management - Voucher Templates
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                            className="search-line"
                            value={BranchId}
                            placeholder={getPlaceholder(filteredOptions)}
                            options={getSelectOptions(filteredOptions)}
                            onChange={(opt) => {
                              setBranchID(opt);
                              sessionStorage.setItem(`${url}_branchName`, JSON.stringify(opt));
                            }}
                            styles={selectStyles}
                          />



                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >

                          {(userPermissionType_ || VouchersPermissions?.access?.includes("create")) && (
                            <Addnewdetails

                              url={"/group_admin/coupon_voucher_management/voucher_management/add_voucher"}
                              Name="Add Voucher" />)}

                          <button
                            onClick={() =>
                              history.push(
                                "/group_admin/coupon_voucher_management"
                              )
                            }
                            className="btn btn-primary mx-3"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    {/* <div className="rounded border p-5"> */}
                      <CommonVoucher />
                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        />

                      </div>
                    {/* </div> */}
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        />

        <TableFilter
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          url23={url}
        />
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  voucherTemList: state?.voucherTemList,
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVoucherTemList: () => dispatch(getVoucherTemList()),
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminVoucherListManagement);
