import React, { useEffect, useState } from 'react'
import GroupAdminHeader from '../../Comman/Header'
import GroupAdminSidebar from '../../Comman/Sidebar'
import GroupAdminUperSide from '../../Comman/UperSide'
import "./Tablemanagmnet.css"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from "react-select";
import api from '../../../CommonApi/axios'
import headers from '../../../CommonApi/headers'
import Swal from 'sweetalert2'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import * as mqtt from "mqtt/dist/mqtt";
import mqttdata from '../../../api/mqtt'
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import "react-toastify/dist/ReactToastify.css";
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';
import NoteState from './Setting/CommanBranch/NoteState'
import { connect } from "react-redux";
import useDrawer from '../../../context/DrawerContext'
import { deleteCommonFunction } from '../../Comman/DeleteCommonFun/DeleteCommonFunction'
import { showSuccessNotification } from '../../Comman/Swal/Swal'
import { getCurrencyLabel } from '../../Comman/currencyUtils/currencyUtils'
import { getBranchList } from '../../../Store'
import { branchfilterAndMapData } from '../../Comman/BranchList'
import { LoadingBackdrop } from '../../Comman/LoadingBackdrop/LoadingBackdrop'
import { selectStyles } from '../../Comman/CommonFunction/CommonFunction'


function GroupAdminTableManagement({ getBranchList, BranchList_, }) {

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [table_number_, settable_number_] = useState("");
  const [TableID_, setTableID_] = useState("");
  const [TableDataById, setTableDataById] = useState([])
  const [FinalAmount, setFinalAmount] = useState("");
  const [loader, setloader] = useState(false);
  const [Table, setTable] = useState([]);
  const [error, setError] = useState(null);
  const [branchID, setbranchID] = useState("");
  const [branch_id2, setbranch_id2] = useState("")

  //  const branchID = branchID
  const [isMqttForNewOrder, serisMqttForNewOrder] = useState(false)

  useEffect(() => {
    BranchDetailsViewData();
  }, [branchID]);

  useEffect(() => {
    getBranchList();
  }, []);

  const [loading__, setLoading__] = useState(false);

  const filteredOptions = branchfilterAndMapData(BranchList_);

  useEffect(() => {
    if (filteredOptions.length > 0) {
      setbranchID(filteredOptions.length > 0 ? filteredOptions[0]?.value : null)
    }
  }, [filteredOptions?.length > 0])


  useEffect(() => {
    if (branchID && table_number_) {
      api.get(`/groupadmin/table/getOrders/${branchID}/${table_number_}`, { headers }).then((res) => {
        const tableData = res.data;
        setTableDataById(res?.data?.data?.length > 0 ? res?.data?.data : [])
        setFinalAmount(tableData);
        serisMqttForNewOrder(false)
      });
    }
  }, [table_number_, branchID, isMqttForNewOrder === true]);

  function BranchDetailsViewData(value) {
    setloader(value);
    if (branchID) {
      setLoading__(true);
      api.get(`/groupadmin/branch/${branchID}`, { headers }).then((res) => {
        const tableData = res.data.data;
        setloader(false)
        setLoading__(false)
        const sortedTables = [...tableData?.tableData]?.sort((a, b) => {
          return parseInt(a?.table_number) - parseInt(b?.table_number);
        });
        setTable(tableData?.tableData?.length > 0 ? sortedTables : []);
        // console.log(tableData)
      }).catch((err) => {
        if (err.response) {
          setloader(false);
          setLoading__(false)
        }
      });
    }
    if (!branchID) {
      setTimeout(() => {
        setloader(false)
      }, 1500);
    }
  }


  function tableDataGet(table_number_) {
    if (table_number_) {
      api.get(`/groupadmin/table/getOrders/${branchID}/${table_number_}`, { headers }).then((res) => {
        const tableData = res.data;
        setTableDataById(res?.data?.data?.length > 0 ? res?.data?.data : [])
        setFinalAmount(tableData);
      });
    }
  }

  function handleSubmitClose(event) {
    event.preventDefault();
    setError(null);

    let item = {

    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Close it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`/groupadmin/table/close/${TableID_}`, item, { headers })
          .then((res) => {
            setTableDataById([]);
            BranchDetailsViewData();
            setTableID_("");
            settable_number_("");
            // setTableDataById("");
            // setFinalAmount("");
            showSuccessNotification("Table has been closed successfully.")

            //   history.goBack();
          })
          .catch((err) => {
            if (err.response) {
              // setError();
              toast.error(err.response?.data?.error?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
      }
    });

  }


  // function deletOrderProduct(order_id, item_id) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         let item = {
  //           order_id: order_id,
  //           item_id: item_id,
  //         };
  //         api.delete(`/groupadmin/table/removeOrderItem`, { data: item, headers }).then((result) => {
  //           Swal.fire({
  //             position: "center-center",
  //             icon: "success",
  //             title: "Product has been deleted successfully.",
  //             showConfirmButton: false,
  //             timer: 1500,
  //           });
  //           tableDataGet(table_number_);
  //         });
  //       }
  //     });
  // }

  function deletOrderProduct(order_id, item_id) {
    let item = {
      order_id: order_id,
      item_id: item_id,
    };
    deleteCommonFunction(`/groupadmin/table/removeOrderItem`, "Product has been successfully deleted.", () => tableDataGet(table_number_), item);
  }

  const [loaderIncre, setloaderIncre] = useState(false);
  const [loaderDe, setloaderDe] = useState(false);
  const [OrderID, setOrderID] = useState("");


  function increment_quantity_CheckOut(event, order_id, item_id, quantity_) {
    event.preventDefault();
    setloaderIncre(true);
    serisMqttForNewOrder(true)
    let item = {
      order_id: order_id,
      item_id: item_id,
      quantity: Number(quantity_ + 1),
    };

    api
      .patch(`/groupadmin/table/updateOrderItemQty`, item, { headers })
      .then((res) => {
        tableDataGet(table_number_);
        setloaderIncre(false);
        setOrderID("")
        serisMqttForNewOrder(false)
      })
      .catch((err) => {
        if (err.response) {
          setloaderIncre(false);
          setOrderID("")
          serisMqttForNewOrder(false)
          console.log(err?.response?.data);
        }
      });

  }

  function decrement_quantity_CheckOut(event, order_id, item_id, quantity_) {
    event.preventDefault();
    setloaderDe(true);
    serisMqttForNewOrder(true)
    if (quantity_ == 1) {
      let item = {
        order_id: order_id,
        item_id: item_id,

      };
      api
        .delete(`/groupadmin/table/removeOrderItem`, { data: item, headers })
        .then((res) => {
          tableDataGet(table_number_);
          setloaderDe(false)
          setOrderID("")
          serisMqttForNewOrder(false)
        })
        .catch((err) => {
          if (err.response) {
            setloaderDe(false)
            setOrderID("")
            serisMqttForNewOrder(false)
            console.log(err.response);
          }
        });
    } else {
      let item = {
        order_id: order_id,
        item_id: item_id,
        quantity: Number(quantity_ - 1),

      };
      api
        .patch(`/groupadmin/table/updateOrderItemQty`, item, { headers })
        .then((res) => {
          tableDataGet(table_number_);
          setloaderDe(false)
          setOrderID("")
          serisMqttForNewOrder(false)
        })
        .catch((err) => {
          if (err.response) {
            setloaderDe(false)
            setOrderID("")
            serisMqttForNewOrder(false)
            console.log(err.response?.data);
          }
        });
    }
  }


  // useEffect(() => {
  //   var OPTIONS = {
  //     clientId: "mqttjs_" + Math.random().toString(16).substring(2, 8),
  //   };
  //   var client = mqtt.connect(
  //     process.env.REACT_APP_BASE_URL === "https://groupapi.kitchenparameter.com"
  //   ? "wss://socket.kitchenparameter.com"
  //   : process.env.REACT_APP_BASE_URL === 
  //   "https://goldenindia-restaurantgroupapi.creatoro.in" ? 
  //   "wss://socket.creatoro.in" : process.env.REACT_APP_BASE_URL ===
  //   "https://groupapidemo.kitchenparameter.com" ? 
  //    "wss://socket.creatoro.in"  : "ws://3.12.248.21:9001", OPTIONS);

  //   const topic = `orderalert-${branchID}`;


  //   client.on("connect", () => {
  //     console.log("Connected");
  //   });
  //   client.on("error", (err) => {
  //     client.end();
  //   });
  //   client.on("reconnect", () => {
  //   });
  //   client.subscribe(topic, { qos: 0 }, (error) => {
  //     if (error) {
  //       console.log("Subscribe to topics error", error);
  //       return;
  //     }
  //   });
  //   client.on("message", (topic, message) => {
  //     const payload = { topic, message: message?.toString() };
  //       BranchDetailsViewData()
  //       serisMqttForNewOrder(true)
  //       tableDataGet(table_number_);
  //   });
  // }, [branchID]);

  // useEffect(() => {

  //   var OPTIONS = {
  //     clientId: "mqttjs_" + Math.random().toString(16).substring(2, 8),
  //   };
  //   var client = mqtt.connect(
  //     process.env.REACT_APP_BASE_URL === "https://groupapi.kitchenparameter.com"
  //   ? "wss://socket.kitchenparameter.com"
  //   : process.env.REACT_APP_BASE_URL === 
  //   "https://goldenindia-restaurantgroupapi.creatoro.in" ? 
  //   "wss://socket.creatoro.in" : process.env.REACT_APP_BASE_URL ===
  //   "https://groupapidemo.kitchenparameter.com" ? 
  //    "wss://socket.creatoro.in"  : "ws://3.12.248.21:9001", OPTIONS);

  //   const topic = `orderalertOnlinePayment-${branchID}`;


  //   client.on("connect", () => {
  //     console.log("Connected");
  //   });
  //   client.on("error", (err) => {
  //     client.end();
  //   });
  //   client.on("reconnect", () => {
  //   });
  //   client.subscribe(topic, { qos: 0 }, (error) => {
  //     if (error) {
  //       console.log("Subscribe to topics error", error);
  //       return;
  //     }
  //   });
  //   client.on("message", (topic, message) => {
  //     const payload = { topic, message: message?.toString() };
  //       BranchDetailsViewData();
  //       serisMqttForNewOrder(true)
  //       tableDataGet(table_number_);
  //   });
  // }, [branchID]);

  const data = [
    { color: "#F25042", label: "Available Tables" },
    { color: "rgb(229, 231, 235)", label: "Booked Table" }
  ];

  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >

        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <GroupAdminHeader />
              <div
                className="content d-flex flex-column flex-column-fluid p-2"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>

                    <div className="row ">
                      <div className="col-md-12">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                          Tables
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                          className="search-line mx-3"
                          placeholder="Select Branch"
                          options={filteredOptions}
                          value={branch_id2 || (filteredOptions.length > 0 ? filteredOptions[0] : null)}
                          onChange={(opt) => {
                            setbranchID(opt?.value);
                            setTableDataById([])
                            setbranch_id2(opt);
                            setTableID_("")
                          }}
                          styles={selectStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="py-2 mt-3">
                      <div className='row'>
                        <div className='col-12 col-sm-12 col-md-4 mb-5'>
                          <div className='card'>
                            <div className='card-header'>
                              <div className='col-8'>
                                <h2 style={{ fontSize: isTabletOrMobile && "15px" }} className='mt-6'>
                                  Tables
                                </h2>
                              </div>
                              <div className='col-4 text-end'>
                                <h2 className='mt-7'>
                                  {loader ?
                                    <CircularProgress style={{ color: "#F25042" }} />
                                    :
                                    <i onClick={() => BranchDetailsViewData(true)} className='fa fa-refresh fs-1' style={{ color: "#F25042", cursor: "pointer" }} />
                                  }
                                </h2>
                              </div>

                            </div>
                            <div className='card-body row text-center' style={{ overflowY: 'auto', height: Table?.length > 10 ? "51vh" : "" }} >
                              {Table?.length > 0 ? Table?.map((main, index) => (
                                <React.Fragment>
                                  <div className="col-4 my-2 d-flex justify-content-center" key={index}>

                                    {TableID_ === main?._id ?
                                      <div className="d-flex justify-content-center"
                                        onClick={(e) => {
                                          setTableID_("")
                                          setTableDataById([]);
                                          setFinalAmount("");
                                          tableDataGet(main?.table_number)
                                          setTableID_(main?._id)
                                          settable_number_(main?.table_number)
                                        }
                                        }

                                        style={{
                                          backgroundColor: TableID_ === main?._id ? "rgb(44, 201, 116)" : "#E5E7EB",
                                          borderRadius: "82.062px",
                                          border: TableID_ === main?._id ? "2px solid rgb(37, 161, 94)" : "",
                                          width: isTabletOrMobile ? "50.497px" : "75.497px",
                                          height: isTabletOrMobile ? "50.497px" : "75.497px",
                                          flexShrink: 0,
                                          alignItems: "center",
                                          cursor: "pointer"

                                        }}>
                                        <h3 style={{
                                          fontFamily: "Segoe UI",
                                          fontSize: isTabletOrMobile ? 22 : 26,
                                          color: main?.table_status === "open" ? "" : "#fff"
                                        }}>{main?.table_number}</h3>
                                      </div>
                                      :
                                      <div className="d-flex justify-content-center"
                                        onClick={() => {

                                          setTableID_("")
                                          setTableDataById([]);
                                          setFinalAmount("");
                                          tableDataGet(main?.table_number)
                                          setTableID_(main?._id)
                                          settable_number_(main?.table_number)
                                        }
                                        }
                                        style={{
                                          backgroundColor: main?.is_order === true ? "#E5E7EB" : "#F25042",
                                          borderRadius: "82.062px",
                                          // border : table_number_ ?  "2px solid rgb(37, 161, 94)" : "" ,
                                          width: isTabletOrMobile ? "50.497px" : "75.497px",
                                          height: isTabletOrMobile ? "50.497px" : "75.497px",
                                          flexShrink: 0,
                                          alignItems: "center",
                                          cursor: "pointer"
                                        }}>
                                        <h3 style={{
                                          fontFamily: "Segoe UI",
                                          fontSize: isTabletOrMobile ? 22 : 26,
                                          color: main?.is_order === true ? "black" : "#fff"
                                        }}>{main?.table_number}</h3>
                                      </div>}
                                  </div>
                                </React.Fragment>
                              )) : (<React.Fragment>
                                <div className='row'>
                                  <h4>
                                    No table available
                                  </h4>
                                </div>
                              </React.Fragment>)}
                            </div>

                            <div className='card-footer row'>
                              {data?.map((item, index) => (
                                <div key={index} className={isTabletOrMobile ? 'col-12 text-start' : 'col-6 text-center'}>
                                  <Brightness1RoundedIcon style={{ color: item.color, fontSize: 30 }} />
                                  <label className="form-check-label mx-3">
                                    <p>{item.label}</p>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {TableDataById?.length > 0 ?
                          <div className='col-12 col-sm-12 col-md-8'>
                            <div className='card'>
                              <div className='card-header'>
                                {table_number_ ?
                                  <h2 className='mt-6'> T - {table_number_}</h2>
                                  : <h2 className='mt-6'> T </h2>}
                              </div>
                              <div className='row px-5'>
                                <div style={{ height: "700px", overflowY: "auto" }} >
                                  <table
                                    className="table table-striped table-row-bordered gy-5 gs-7"
                                  >
                                    <thead>
                                      <tr
                                        className="fw-bold fs-6 text-gray-800"
                                      >
                                        <th className="text-start"> <h6 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='table_head'>
                                          Dish Name
                                        </h6> </th>
                                        <th ><h6 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='table_head'>
                                          Quantity
                                        </h6></th>
                                        <th className='text-end'> <h6 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='table_head'>
                                          Price
                                        </h6></th>
                                        {/* <th ></th> */}
                                      </tr>
                                    </thead>

                                    {TableDataById?.length > 0 ? TableDataById?.map((main, index) => (
                                      <React.Fragment>
                                        {main?.orderItemDetails?.length > 0 ?
                                          <tbody key={index}>
                                            <td style={{ fontSize: isTabletOrMobile ? "15px" : "19px" }} colSpan="4" className='Order_number my-0'>

                                              Order No. {main?.order_no}&nbsp;&nbsp;&nbsp;
                                              {moment(main?.created_at).tz(FinalAmount?.branchData?.branchTimeZone).format('h:mm A')}
                                              {/* {moment(main?.created_at).format('h:mm A')} */}
                                            </td>
                                          </tbody>
                                          : null}

                                        {main?.orderItemDetails?.length > 0 ?
                                          main?.orderItemDetails?.map((main2, index) => (
                                            <tbody key={index}>
                                              <tr>
                                                <td>
                                                  <h6 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='product_name'>
                                                    {main2?.name_EN}
                                                    {main2?.is_pickup && <span className="mx-2">
                                                      <img
                                                        alt="Take_away"
                                                        loading="lazy"
                                                        src="/assets/media/avatars/take-away1 1.png"
                                                        style={{ height: 17 }}
                                                      />
                                                    </span>
                                                    }
                                                    {main2?.is_custom && <span className="mx-1">
                                                      <img
                                                        alt="Take_away"
                                                        loading="lazy"
                                                        src="/assets/media/avatars/CustomeDish.png"
                                                        style={{ height: 17, marginTop: 3 }}
                                                      />
                                                    </span>
                                                    }
                                                  </h6></td>
                                                <td>
                                                  <p style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='product_price'>{main2?.quantity}</p>

                                                  {/* <Box
                                                    sx={{
                                                      flexDirection: 'column',
                                                      alignItems: 'center',

                                                    }}
                                                  >
                                                    <ButtonGroup size={isTabletOrMobile ? "small" : "large"} aria-label="small button group">
                                                      <Button key="one" onClick={(e) => {
                                                        decrement_quantity_CheckOut(
                                                          e,
                                                          main2?.order_id,
                                                          main2?._id,
                                                          main2?.quantity
                                                        )
                                                        setOrderID(main2?._id)
                                                      }}>
                                                        {loaderDe && OrderID === main2?._id ?
                                                          <CircularProgress size={20} style={{ color: "#F25042" }} />
                                                          :
                                                          <i className="fa-solid fa-minus text-black"></i>
                                                        }

                                                      </Button>
                                                      <Button key="two" className='text-black'>{main2?.quantity}</Button>
                                                      <Button key="three" onClick={(e) => {
                                                        increment_quantity_CheckOut(
                                                          e,
                                                          main2?.order_id,
                                                          main2?._id,
                                                          main2?.quantity
                                                        )
                                                        setOrderID(main2?._id)
                                                      }}>
                                                        {loaderIncre && OrderID === main2?._id ?
                                                          <CircularProgress size={20} style={{ color: "#F25042" }} />
                                                          :
                                                          <i className="fa-solid fa-plus text-black"></i>
                                                        }
                                                      </Button>
                                                    </ButtonGroup>
                                                  </Box> */}
                                                </td>
                                                <td className='text-end'>
                                                  <p style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='product_price'>
                                                    {currencyLabel}&nbsp;{Number(main2?.total_price).toFixed(2)}
                                                  </p>
                                                </td>
                                                {/* <td className='text-end'>
                                                  <i onClick={() => deletOrderProduct(main2?.order_id,
                                                    main2?._id,)} className={isTabletOrMobile ? "fa-regular fa-trash-can fs-2" : "fa-regular fa-trash-can fs-1"} style={{ color: "red", cursor: "pointer" }}></i>
                                                </td> */}

                                              </tr>

                                            </tbody>
                                          ))
                                          : null}

                                        {main?.orderItemDetails?.length > 0 ? main?.order_notes &&
                                          <tbody >
                                            <td style={{ fontSize: isTabletOrMobile ? "15px" : "19px", padding: 11 }} colSpan="4" className='Order_number my-0  mx-2'>
                                              <strong style={{ fontSize: isTabletOrMobile ? "15px" : "", color: "#898787" }} >  Order Notes : </strong> {main?.order_notes}
                                            </td>
                                          </tbody>
                                          : null}


                                      </React.Fragment>
                                    )) :
                                      (
                                        <React.Fragment>
                                          <tbody>
                                            <tr>
                                              <td colSpan="4">
                                                <h2 className='Order_number'> No order available</h2>
                                              </td>
                                            </tr>

                                          </tbody>
                                        </React.Fragment>)
                                    }
                                  </table>


                                  <table className="table table-striped table-row-bordered gy-5 gs-7 mt-20">
                                    <thead className="thead-dark">
                                      <tr className="fw-bold fs-6 text-gray-800">

                                        <th className='table_head text-white' scope="col" >
                                          _____________
                                          {/* sdfddsasfasdd */}
                                        </th>
                                        <th className='table_head text-white' scope="col">
                                          {/* fssdsafdf */}
                                          _________
                                        </th>
                                        <th className='table_head text-white' scope="col">
                                          {/* fdsafsd */}
                                          _______
                                        </th>
                                        <th className='table_head text-white' scope="col">
                                          {/* fasddasfsdfas */}
                                          _____________
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr style={{ borderBottom: "1px dashed #d0cccc", borderTop: "1px dashed #d0cccc" }}  >
                                        <td colSpan="1">
                                          <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='sub_total'> Sub Total</h5>
                                        </td>
                                        <td className='text-center' colSpan="1">
                                          <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='sub_total'> x{Number(FinalAmount?.item_qty)} items </h5>
                                        </td>
                                        <td colSpan="2">
                                          <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='chf_chf text-end'>
                                            {currencyLabel} {Number(FinalAmount?.final_amount ? FinalAmount?.final_amount : 0).toFixed(2)}
                                          </h5>
                                        </td>
                                      </tr>
                                      {FinalAmount?.discount_amount > 0 ?
                                        <tr style={{ borderBottom: "1px dashed #d0cccc", borderTop: "1px dashed #d0cccc" }}  >
                                          <td colSpan="2">
                                            <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='sub_total'> Discount Amount</h5>
                                          </td>
                                          <td colSpan="2">
                                            <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "", color: 'green' }} className='chf_chf text-end'>
                                              {currencyLabel} {Number(FinalAmount?.discount_amount ? FinalAmount?.discount_amount : 0).toFixed(2)}
                                            </h5>
                                          </td>
                                        </tr> : ""}
                                      {FinalAmount?.final_amount_after_discount ?
                                        <tr style={{ borderBottom: "1px dashed #d0cccc", borderTop: "1px dashed #d0cccc" }}  >

                                          <td colSpan="2">
                                            <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='sub_total'> To Pay </h5>
                                          </td>
                                          <td colSpan="2">
                                            <h5 style={{ fontSize: isTabletOrMobile ? "15px" : "" }} className='chf_chf text-end' >
                                              {currencyLabel} {Number(FinalAmount?.final_amount_after_discount >= 0 ? FinalAmount?.final_amount_after_discount : 0).toFixed(2)}
                                            </h5>
                                          </td>
                                        </tr> : ""}
                                    </tbody>
                                  </table>

                                  <React.Fragment>
                                    <div className='row mt-5 mb-10'>
                                      <div className={isTabletOrMobile ? 'col-lg-12 my-1 col-sx-12 text-center' : 'col-lg-4 my-1 col-sx-12 text-center'} >
                                        <div className='row mx-3' >
                                          <button
                                            onClick={handleSubmitClose}
                                            type="button"
                                            id="kt_modal_new_address_submit"
                                            className="btn btn-primary"
                                          >
                                            <span className="indicator-label">
                                              <i className="fa-solid fa-xmark fs-2 mx-1"></i>Close Table</span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className={isTabletOrMobile ? 'col-lg-12 my-1 col-sx-12 text-center' : 'col-lg-4 my-1 col-sx-12 text-center'}>
                                        <div className='row mx-3' >
                                          <button
                                            type="button"
                                            id="kt_modal_new_address_submit"
                                            className="btn btn-primary"
                                          >
                                            <span className="indicator-label">
                                              <i className="fa-solid fa-print fs-2 mx-1"></i>Print Rreceipt</span>
                                          </button>
                                        </div></div>
                                      <div className={isTabletOrMobile ? 'col-lg-12 my-1 col-sx-12 text-center' : 'col-lg-4 my-1 col-sx-12 text-center'}>
                                        <div className='row mx-3' >
                                          <button
                                            type="button"
                                            id="kt_modal_new_address_submit"
                                            className="btn btn-primary"
                                          >
                                            <span className="indicator-label">Pay {currencyLabel} {Number(FinalAmount?.final_amount_after_discount > 0 ? FinalAmount?.final_amount_after_discount : 0).toFixed(2)}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </div> :
                          <React.Fragment>
                            <div className='col-12 col-sm-12 col-md-8 text-center'>
                              <div className='card'>
                                <h2 className='Order_number mt-10 mb-10'> No order available</h2>
                                {TableID_ ?

                                  FinalAmount?.table_status === "open" && FinalAmount?.is_order === true ?
                                    <div className='mb-10'>
                                      <button
                                        onClick={handleSubmitClose}
                                        type="button"
                                        id="kt_modal_new_address_submit"
                                        className="btn btn-primary"
                                      >
                                        <span className="indicator-label">
                                          <i className="fa-solid fa-xmark fs-2 mx-1"></i>Close Table</span>
                                      </button>
                                    </div> : "" : ' '}
                              </div>
                            </div>
                          </React.Fragment>

                        }
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoadingBackdrop open={loading__} />
        <GroupAdminUperSide />
        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h5 className="modal-title">Message</h5>
                <p>Looking forward to visting you !</p>
                <h5 className="modal-title">Submitted By</h5>
                <p>June 21, 2022 4:30 PM</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminTableManagement);
