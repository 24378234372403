import React, { useEffect, useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import api from "../../../../../CommonApi/axios";
import $ from "jquery";
import headers from "../../../../../CommonApi/headers";
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserManagmentCommon from "./UserManagmentCommon";
import useDrawer from "../../../../../context/DrawerContext";
import moment from "moment";
import { Chip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import useWindowHeight from "../../../../NewTableCommon/useWindowsPosition";
import { HeaderStyle } from "../../OrderManagement/TableCommonModule";
import { tableOptionBranchList } from "../../../../NewTableCommon/tableOption";
import TableFilter from "../../../../NewTableCommon/tableFilter";
import ColumPopoverCommon from "../../../../NewTableCommon/ColumPopoverCommon";


window.jquery = window.$ = $;


function GroupAdminUserRoleManagment() {

  let history = useHistory();
  const [user, setUser] = useState([])

  useEffect(() => {
    UserDetailsGet();
  }, []);

  function UserDetailsGet() {
    api.get(`/groupadmin/user/getroles`, { headers }).then((res) => {
      const tableData = res.data.data;
      setUser(tableData);
    });
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const [BranchId, setBranchID] = useState(branchName || "");

  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'name',
        header: 'Role Name',
        size: 300,
        Cell: ({ row }) =>
          <Link
            to={{
              pathname: `/group_admin/settings/roles/view_role/${row?.original?._id}`,
            }}
          >
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.name}
            </span>
          </Link>,
      },
      {
        accessorKey: 'created_at',
        header: 'Created On',
        size: 300,
        Cell: ({ row }) => (`${moment(row?.original?.created_at).format('DD/MM/YY')}, ${moment(row?.original?.created_at).format('LT')}`),
      },
      {
        accessorKey: 'created_at',
        header: 'Last Active',
        size: 300,
        Cell: ({ row }) => (`${moment(row?.original?.created_at).format('DD/MM/YY')}, ${moment(row?.original?.created_at).format('LT')}`),
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  // const EDIT = VouchersPermissions?.access?.includes("edit");
  // const DELETE = VouchersPermissions?.access?.includes("delete")
  // console.log("url" ,url)

  const table = useMaterialReactTable({
    columns: Columns,
    data: user,
    ...tableOptionBranchList({
      UserDetailsGet,
      url,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      // DELETE,
      // VIEW,
      // EDIT,
      history
    }),
  });

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <ToastContainer />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />

                    <div className="row col-md-12">
                      <div
                        className="col-md-9 "
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          User Management - Roles List <Chip
                            className="mx-2"
                            style={{
                              color: "#DF3223", background: "#FEF3F2",
                              border: "1px solid #DF3223",
                              fontSize: 12, fontWeight: 500, letterSpacing: "0.3px"
                            }} label={`${user.length} Records`} />
                        </h3>
                      </div>

                      <div
                        className="col-md-3 text-end"
                        data-kt-docs-table-toolbar="base"
                      >

                        <button
                          className="btn btn-primary me-3"
                          onClick={() =>
                            history.push("/group_admin/settings/roles/add_roles")
                          }
                        >
                          <AddIcon className="me-2" />  Add Role
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            history.push("/group_admin/settings/user_management")
                          }
                        >
                          Go Back
                        </button>
                      </div>
                    </div>

                    <div className="rounded border p-5">
                      <UserManagmentCommon />
                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        />

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>

            <ColumPopoverCommon
              anchorEl_={anchorEl_}
              handleClickColum_={handleClickColum_}
              onColumnDataChange={onColumnDataChange}
              handleCloseColum_={handleCloseColum_}
              url23={url}
            />

            {/* <TableFilter
              open={isFilterPopoverOpen}
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilter={handleApplyFilters}
              url23={url}
            /> */}
            <GroupAdminUperSide />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}



export default GroupAdminUserRoleManagment;

