import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Select from "react-select";
import { connect } from "react-redux";
import headers from "../../../../CommonApi/headers";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import $ from "jquery";
import moment from "moment";
import useDrawer from "../../../../context/DrawerContext";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import { tableOptionEnquiryManagment } from "../../../NewTableCommon/tableOption";
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
window.jquery = window.$ = $;

function GroupAdminPendingEnquiryListNew({ getBranchList, BranchList_ }) {
      /////////////////////////////// Branch List /////////////////////////
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      useEffect(() => {
            getBranchList();
      }, []);

      let history = useHistory();

      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[3];
      const [data, setData] = useState([]);

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const [BranchId, setBranchID] = useState(branchName || "");

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;
      const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover
      const [columnFilters, setColumnFilters] = useState([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState([]);
      const [rowCount, setRowCount] = useState(0);
      const [pagination, setPagination] = useState("");
      const [isError, setIsError] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const prevSorting = useRef([]);

      const storedState = sessionStorage.getItem(`${url23}_paginationStatePendingEnquiry`);

      useEffect(() => {
            if (storedState !== null) {
                  setPagination(JSON.parse(storedState));
            } else {
                  setPagination({ pageIndex: 0, pageSize: 5 });
            }
      }, [storedState]);

      const getCompletedEnquiry = async () => {
            setData([]);
            setIsLoading(true);
            setIsError(false)
            // let url = `/groupAdmin/inquiry/completed/list?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let url = `groupAdmin/inquiry/list/pending/?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
            let formData = new FormData();
            if (BranchId?.value) {
                  url += `&branch_id=${BranchId?.value}`;
            }
            if (sorting?.length > 0 && sorting[0]?.id) {
                  switch (sorting[0]?.id) {
                        case "branchname":
                              url += `&sort_field=branch.branch_name_EN`;
                              break;
                        case "datetime":
                              url += `&sort_field=order_date`;
                              break;
                        // Add additional cases as needed
                        default:
                              url += `&sort_field=${sorting[0]?.id}`;
                              break;
                  }
            }
            if (sorting?.length > 0 && sorting[0]?.desc === true) {
                  url += `&sort_type=${'-1'}`;
            }
            if (sorting?.length > 0 && sorting[0]?.desc === false) {
                  url += `&sort_type=${'1'}`;
            }
            if (globalFilter) {
                  url += `&search=${globalFilter ?? ''}`;
            }
            // Add global filter to FormData if it exists
            try {
                  const res = await api.post(url, formData, { headers });
                  const tableData = res?.data?.data?.filteredInquiryData;
                  setData(tableData);
                  setRowCount(res?.data?.data?.totalInquiries)
            } catch (err) {
                  setIsError(true);
                  setData([]);
            } finally {
                  setIsLoading(false);
            }
      };

      function arraysAreEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                  if (arr1[i] !== arr2[i]) return false;
            }
            return true;
      }

      const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
      prevSorting.current = sorting;

      useEffect(() => {
            if (pagination.pageIndex, pagination.pageSize) {
                  getCompletedEnquiry();
            }
      }, [pagination.pageIndex, pagination.pageSize, sortingChanged,
      filterData?.Status?.value, globalFilter ?? '', BranchId?.value]);

      const prevPaginationRef = useRef();
      useEffect(() => {
            if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
                  sessionStorage.setItem(`${url23}_paginationStatePendingEnquiry`, JSON.stringify(pagination));
            }
            prevPaginationRef.current = pagination;
      }, [pagination, rowCount]);

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };

      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: '_id',
                        header: 'Sr.No',
                        size: 100,
                        Cell: ({ row }) => (row?.index + 1),
                  },
                  {
                        accessorKey: 'branch?.branch_name_EN',
                        header: 'Branch',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.branch?.branch_name_EN),
                  },
                  {
                        accessorKey: 'name',
                        header: 'Name',
                        size: 250,
                        Cell: ({ row }) => (row?.original?.name || '---'),
                  },
                  {
                        accessorKey: 'phone_number',
                        header: 'Phone Number',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.phone_number || '---'),
                  },
                  {
                        accessorKey: 'email',
                        header: 'Email',
                        size: 200,
                        Cell: ({ row }) => (row?.original?.email || '---'),
                  },
                  {
                        accessorKey: 'updated_at',
                        header: 'Enquiry Date',
                        size: 200,
                        Cell: ({ row }) => moment(row?.original?.updated_at).format("DD/MM/YYYY"),
                  }
            ];

            return dineInColumnsData === null ? columns : columns.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const commonOptions = {
            columnFilters,
            globalFilter,
            sorting, isLoading,
            setColumnFilters,
            setGlobalFilter,
            setPagination,
            setSorting,
            rowCount,
            pagination,
            history,
            // handleFilterClick,
            handleClickColum_,
            windowHeight,
            // getMenuList,
            url23,
            // handleSubmitActive,
            filterData,
            DisplayCol,
            getCompletedEnquiry,
      };

      const table = useMaterialReactTable({
            columns: Columns,
            data: data,
            ...tableOptionEnquiryManagment(commonOptions),
      });

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Pending Enquiry List

                                                                              </h1>
                                                                              <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    value={BranchId}
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    onChange={(opt) => {
                                                                                          if (opt.label === "All Locations") {
                                                                                                getCompletedEnquiry();
                                                                                                setBranchID("");
                                                                                                sessionStorage.removeItem(`${url23}_branchName`)
                                                                                          } else {
                                                                                                setBranchID(opt);
                                                                                                sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                                                                                          }
                                                                                    }}
                                                                                    styles={selectStyles}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <button
                                                                                    onClick={() => history.goBack()}
                                                                                    className="btn btn-primary"
                                                                                    data-kt-menu-trigger="click"
                                                                                    data-kt-menu-attach="parent"
                                                                                    data-kt-menu-placement="bottom-end"
                                                                              >
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="mt-5">
                                                                  <MaterialReactTable
                                                                        table={table}
                                                                  />
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <ColumPopoverCommon
                              anchorEl_={anchorEl_}
                              handleClickColum_={handleClickColum_}
                              onColumnDataChange={onColumnDataChange}
                              handleCloseColum_={handleCloseColum_}
                              url23={url23}
                        />

                        <GroupAdminUperSide />
                  </div>
            </React.Fragment>
      );
}


const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminPendingEnquiryListNew);
